import configJSON from 'config.json';
import { TenantName, ZOOCASA_TENANT_NAME, EXP_IN_SERVICE_TENANT_NAME } from 'tenants';

export const isServerSide = () => (typeof window === 'undefined');

export const isClientSide = () => (typeof window !== 'undefined');

export const getInsightsHost = (isServerSide: boolean) => isServerSide ? configJSON.services.insights.serverSideHost : configJSON.services.insights.clientSideHost;

export const getGoSearchHost = (isServerSide: boolean) => isServerSide ? configJSON.services.search.serverSideHost : configJSON.services.search.clientSideHost;

export const getClientAppHost = (isServerSide: boolean) => isServerSide ? configJSON.services.clientApp.serverSideHost : configJSON.services.clientApp.clientSideHost;

export const getCmsHost = () => configJSON.services.cms;

export const getWalkScoreHost = () => configJSON.services.walkScore;

export const getProviderComplianceHost = () => configJSON.providerTermsOfUseHost;

/**
 * Get the WordPress blog API URL for the given tenant.
 *
 * @param tenant The {@link TenantName tenant} to get the blog API URL for. Defaults to {@link ZOOCASA_TENANT_NAME zoocasa}.
 * @returns The blog API URL for the given tenant.
 */
export const getBlogApiUrl = (tenant: Extract<TenantName, typeof ZOOCASA_TENANT_NAME | typeof EXP_IN_SERVICE_TENANT_NAME> = ZOOCASA_TENANT_NAME) => configJSON.blogApi[tenant];

/**
 * Get the storage service host for the given tenant.
 *
 * @param isServerSideHost Whether the host is for the server side or client side. Defaults to the value of {@link isServerSide isServerSide()}.
 * @returns The storage service host.
 */
export const getStorageServiceHost = (isServerSideHost: boolean = isServerSide()) => isServerSideHost ? configJSON.services.storage.serverSideHost : configJSON.services.storage.clientSideHost;