export const ZOOCASA_THEME_NAME = 'zoocasa' as const;
export const EXPREALTY_CA_THEME_NAME = 'exprealtyCA' as const;
export const EXPREALTY_US_THEME_NAME = 'exprealtyUS' as const;
export const EXP_IN_SERVICE_THEME_NAME = 'expInService' as const;
export const EXP_COMMERCIAL_THEME_NAME = 'expCommercial' as const;

export const ThemeNames = {
  ZOOCASA: ZOOCASA_THEME_NAME,
  EXP_REALTY_CA: EXPREALTY_CA_THEME_NAME,
  EXP_REALTY_US: EXPREALTY_US_THEME_NAME,
  EXP_IN_SERVICE: EXP_IN_SERVICE_THEME_NAME,
  EXP_COMMERCIAL: EXP_COMMERCIAL_THEME_NAME,
} as const;

export type ThemeNames = typeof ThemeNames[keyof typeof ThemeNames];
export type Themes = ThemeNames;
