import ExpInServiceTheme from 'themes/expInService';
import { Tenant } from './tenants';
import { ThemeConfig } from 'themes/themeConfig';
import { CountryCodeList } from 'types/countries';

export const EXP_IN_SERVICE_TENANT_ID = 'expInService' as const;
export const EXP_IN_SERVICE_L18N_NAMESPACE = 'expInService' as const;
export const EXP_IN_SERVICE_L18N_NAMESPACES = [EXP_IN_SERVICE_L18N_NAMESPACE, 'common'] as const;

const headerConfig = {
  mainButtonCopy: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.mainButtonCopy`,
  authButtonCopy: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.authButtonCopy`,
  hasJoinExpPage: true,
  authMenuItems: [
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.authLinks.mySavedSearches`,
      link: '/account/saved/searches',
      id: 'my-saved-searches',
    },
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.authLinks.myFavourites`,
      link: '/account/saved/favourites',
      id: 'my-favourites',
    },
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.authLinks.editProfile`,
      link: '/account/details',
      id: 'edit-profile',
    },
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.authLinks.signOut`,
      id: 'sign-out',
      signOut: true,
    },
  ],
  menuItems: [
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.menuLinks.buy`,
      link: '/search?openSearch=true',
      id: 'header-buy-button',
    },
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.menuLinks.militaryRewardsProgram`,
      link: '/military-rewards-program',
      id: 'military-rewards-program-button',
    },
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.menuLinks.inServiceLife`,
      link: '/inservice-life',
      id: 'in-service-life-button',
    },
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.menuLinks.findAnAgent`,
      link: '/agents-search',
      id: 'header-find-agent-button',
    },
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.menuLinks.joinExp`,
      link: '/join-exp',
      newTab: true,
      id: 'header-join-exp-button',
    },
    {
      label: `${EXP_IN_SERVICE_L18N_NAMESPACE}:header.menuLinks.sitemap`,
      link: '/sitemap',
      id: 'header-sitemap-button',
    },
  ],
};

const homeConfig = {
  heroImage: '/next/assets/images/in-service/military-hero.jpg',
  heroImageAlt: 'Military hero image',
  testimonialBlock: {
    backgroundImage: '/next/assets/images/agent-search/exp-mobile-banner-white-flag.jpg',
    heading: 'Hear From Our Military Agents',
    testimonials: [
      {
        image: '/next/assets/images/in-service/inservice-agent1.jpg',
        alt: 'Agent 1',
        name: 'Brittany Sims',
        company: 'eXp Realty, Virginia',
        text: '\"I\'m absolutely loving eXp. From the systems to the support, being able to do real estate while knowing I can build my vision is such an awesome feeling. Everything just feels very aligned.\"',
        key: 0,
      },
      {
        image: '/next/assets/images/in-service/inservice-agent2.jpg',
        alt: 'Agent 2',
        name: 'Ruben Hernandez',
        company: 'eXp Realty, California',
        text: '"Creating a real estate company focused on assisting fellow veterans has helped me connect the dots and carry out the Marine Corps motto, \'Semper Fidelis,\' which stands for \'always faithful.\'\"',
        key: 1,
      },
      {
        image: '/next/assets/images/in-service/inservice-agent3.jpg',
        alt: 'Agent 3',
        name: 'Ruben Hernandez',
        company: 'eXp Realty, California',
        text: '"With our previous structure, we had ceilings on how to grow outside the local area. eXp has the ability to extend locally without having to buy another franchise."',
        key: 2,
      },
    ],
  },
  joinUsSection:{
    link: '/join-exp',
    title: 'Why Join eXp Realty',
    title2: 'Military Rewards Program',
    description: 'When you join eXp Realty, you become part of a global family of agents and partners dedicated to transforming the real estate experience. Learn more about how we empower our agents with world-class education, strategic partnership and stock awards.',
    imageUrl: '/next/assets/images/join-us-military.jpg',
    buttonText: 'Learn More',
  },
  contentCards: {
    sectionTitleParts: ['Your Military', 'Moving', 'Experts'],
    cards: [
      {
        title: 'Relocation Assistance',
        image: '/next/assets/images/content-cards/relocation-assistance-inservice.jpg',
        titleColor: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: '#163259',
        content: 'Moving to a new base or transitioning to civilian life? Our dedicated relocation experts provide personalized support to make your move seamless. From finding the perfect home to understanding VA loan options, we\'ve got you covered.',
        link: '/search?openSearch=true',
        desktopTransform: 'rotate(-5deg) translateY(-1.5em)',
        tabletTransform: 'none',
        rounded: true,
        altText: 'A man with his hair in a bun hugging a woman outside of a modern building.',
        buttonText: 'Find an Expert',
      },
      {
        title: 'Military Rewards Program',
        image: '/next/assets/images/content-cards/military-rewards-program.jpg',
        titleColor: 'rgba(255, 255, 255, 0.7)',
        backgroundColor: '#6C82A0',
        content: 'Save money when buying or selling a home through our exclusive Military Rewards Program. Eligible service members, veterans, and military families receive cashback or concessions at closing.',
        link: '/sell',
        tabletTransform: 'translateY(3em)',
        translateYTablet: '50',
        altText: 'A smiling family, with two kids playing inside a cardboard box',
        buttonText: 'Learn More about Benefits',
      },
      {
        title: 'Certified Specialists',
        image: '/next/assets/images/content-cards/certified-specialist.jpg',
        titleColor: '#0C0F24',
        backgroundColor: '#DAE0E7',
        content: 'Work with real estate professionals who understand military moves. Our certified agents, many of whom are veterans themselves, specialize in PCS transitions, VA loans, and military-specific housing needs.',
        link: '/agents-search',
        desktopTransform: 'rotate(2deg) translateY(-1.5em)',
        tabletTransform: 'none',
        translateYDesktop: '-20',
        altText: 'A group of professionals sitting around a table, engaged in conversation.',
        buttonText: 'Connect with a Specialist', 
      },
      {
        title: 'In Service Life',
        image: '/next/assets/images/content-cards/in-service-life.jpg',
        titleColor: '#0C0F24',
        backgroundColor: '#EEEEEE',
        content: 'Get inspired by stories from fellow military members and veterans who have successfully navigated real estate and homeownership. Explore tips, testimonials, and community resources designed for military families.',
        link: 'https://expressoffers.com/',
        desktopTransform: 'rotate(7deg) translateY(2em)',
        tabletTransform: 'translateY(3em)',
        translateYDesktop: '40',
        translateYTablet: '50',
        rounded: true,
        altText: 'Close-up of hands typing on a laptop.',
        buttonText: 'Read Military Stories',
      },
    ],
  },
};

const footerConfig = {
  spotlightImage: 'image.png',
  links: {
    homeLink: 'inservice.exprealty.com',
  },
  footerColumns: {
    column1: [
      {
        label: 'Explore eXp',
        link: '/explore-exp',
        isAnchor: true,
        target: '_blank',
      },
      {
        label: 'Find an Agent',
        link: '/agents-search',
        isAnchor: true,
        target: '_blank',
      },
      {
        label: 'Join eXp',
        link: '/join-exp',
        isAnchor: true,
        target: '_blank',
      },
      {
        label: 'Investor Relations',
        link: 'https://expworldholdings.com/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Company Careers',
        link: 'https://expworldholdings.com/careers/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Home Guides',
        link: 'https://exprealty.com/guides/',
        isAnchor: true,
        target: '_blank',
      },
    ],
    column2: [
      {
        label: 'eXp Life',
        link: 'https://life.exprealty.com/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Zoocasa',
        link: 'https://zoocasa.com/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'SUCCESS',
        link: 'https://www.success.com/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'eXp Revenos',
        link: 'https://revenos.com/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'eXp Commercial',
        link: 'https://expcommercial.com/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'eXp Luxury',
        link: 'https://expluxury.com/?tl=en',
        isAnchor: false,
        target: 'blank',
      },
    ],
    column3: [
      {
        label: 'Terms and Conditions',
        link: 'https://expworldholdings.com/terms-of-service/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Privacy Rights Request',
        link: 'https://submit-irm.trustarc.com/services/validation/e6d7516d-df9c-4404-bd9f-21a2f0d2a6a4/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Privacy Policy',
        link: 'https://expworldholdings.com/privacy-policy/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Cookie Policy',
        link: 'https://expworldholdings.com/cookie-policy/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Brand Guidelines',
        link: 'https://join.exprealty.com/brand/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Help',
        link: 'https://searchhelp.exprealty.com/hc/en-us/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Sitemap',
        link: '/sitemap',
        isAnchor: true,
        target: '_blank',
      },
    ],
    bottom: [
      [
        {
          label: '© 2024 eXp Realty. eXp World Holdings, Inc. All Rights Reserved.',
        },
      ],
      [
        {
          label: ' ',
        },
      ],
      [
        {
          label: 'DMCA Notice',
          link: '/dmca',
          target: '_blank',
          isAnchor: true,
        },
      ],
      [
        {
          label: 'This site is protected by reCAPTCHA and the Google ',
        },
        {
          label: 'Privacy Policy',
          link: 'https://policies.google.com/privacy',
          target: '_blank',
          isAnchor: true,
        },
        {
          label: ' and ',
        },
        {
          label: 'Terms of Service ',
          link: 'https://policies.google.com/terms',
          target: '_blank',
          isAnchor: true,
        },
        {
          label: 'apply.',
        },
      ],
      [
        {
          label: 'Fair Housing and Reasonable Accommodations',
          link: '/fair-housing-and-reasonable-accommodations',
          target: '_blank',
          isAnchor: true,
        },
      ],
      [
        {
          label: 'MLS Compliance Statements',
        },
      ],
      [
        {
          label: 'Karen Richards – Designated Managing Broker, Broker Operations – TX',
        },
      ],
      [
        {
          label: 'Texas Real Estate Commission information about brokerage services',
          link: 'https://drive.google.com/file/d/1NMjX7L_BEHJWLQjUXnvf2Ivou4s8sTuA/view',
          isAnchor: true,
          target: '_blank',
        },
      ],
      [
        {
          label: 'Texas Real Estate Commission Consumer Protection Notice',
          link: 'https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-4-1_1.pdf',
          isAnchor: true,
          target: '_blank',
        },
      ],
      [
        {
          label: 'eXp Realty® is committed to adhering to the guidelines of The New York State Fair Housing Regulations. ',
        },
        {
          label: 'The Fair Housing Notice',
          link: 'https://dos.ny.gov/fair-housing',
          isAnchor: true,
          target: '_blank',
        },
        {
          label: ' →',
          link: 'https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf',
          target: '_blank',
        },
      ],
      [
        {
          label: '*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. ',
        },
        {
          label: 'Learn More',
          link: 'https://drive.google.com/file/d/1o1iG6CO5hFgawNGJibmd34loBFJeIqqJ/view',
          isAnchor: true,
          target: '_blank',
        },
        {
          label: ' →',
          link: 'https://drive.google.com/file/d/1o1iG6CO5hFgawNGJibmd34loBFJeIqqJ/view',
          target: '_blank',
        },
      ],
      [
        {
          label: ' ',
        },
      ],
      [
        {
          label: 'eXp Realty of California, Inc. | CA DRE# 01878277 | Deborah Penny – Designated Broker',
        },
      ],
      [
        {
          label: 'eXp Realty of Southern California, Inc. | CA DRE# 02187306 | Gina Saad – Designated Broker',
        },
      ],
      [
        {
          label: 'eXp Realty of Greater Los Angeles, Inc. | CA DRE# 01828532 | Justin DeCesare – Designated Broker',
        },
      ],
      [
        {
          label: 'eXp Realty of Northern California, Inc. | CA DRE# 01951343 | Ryan Rosas – Designated Broker',
        },
      ],
    ],
    joinUsLink: '/join-exp',
    footerImageData: {
      src: '/next/assets/images/exp-footer-pic-in-service.jpg',
      alt: 'A group of people at an event are laughing and cheering, with one man enthusiastically greeting a seated couple in the front row.',
    },
  },
  organization: {
    phoneNumber() {
      return '1 833-303-0610';
    },
    email() {
      return 'info@exprealty.net';
    },
    address() {
      return '2219 Rimland Drive, Suite 301,\nBellingham, Washington, 98226';
    },
    twitterUrl: 'https://x.com/exprealty',
    facebookUrl: 'https://www.facebook.com/eXpRealty/',
    instagramUrl: 'https://www.instagram.com/exprealty',
    linkedinUrl: 'https://www.linkedin.com/company/exp-realty',
    youtubeUrl: 'https://www.youtube.com/@eXpRealty',
    joinSectionUrl: '/join-exp',
  },
  showAccessibilityLink: false,
};

const agentSearch = {
  headerImage: {
    mobileSrc: '/next/assets/images/agent-search/exp-mobile-banner-white-flag.jpg',
    src: '/next/assets/images/agent-search/exp-banner-white-flag.jpg',
    alt: 'American flag background',
  },
  agentTypeName: 'Military Agent',
  featuredAgentDescriptor: 'Military',
  pageTitle: 'Your Military Move Experts We Are Here For You',
};

const expInServiceTenant: Tenant<ThemeConfig> = {
  id: EXP_IN_SERVICE_TENANT_ID,
  theme: ExpInServiceTheme,
  localization: {
    languages: ['en', 'fr'], // FIXME: use constants. They should match the languages in public/locales
    countries: [CountryCodeList.UNITED_STATES],
    namespaces: EXP_IN_SERVICE_L18N_NAMESPACES,
  },
  config: {
    header: headerConfig,
    home: homeConfig,
    footer: footerConfig,
    agentSearch: agentSearch,
  },
  debug: null,
};

export default expInServiceTenant;
