import Cookies from 'js-cookie';
import { getJSON } from 'utils/cookies';
import { ListingParams, ListingParamsMethods } from './types';
import { storageKey } from './utils';
import { ensureBackwardCompatibilityForHomeType } from 'data/saved-searches/utils';
import { COOKIE_SERIALIZER_FUNC, ListingParamsBuilder } from './listing-params-builder';
import { ListingParamsSerializer } from './listing-params-impl';

/**
 * Create a new ListingParams object from the given parameters.
 * Backwards compatibility is ensured by ensuring that deprecated property types are migrated.
 *
 * NOTE: If no parameters are provided, the function will attempt to retrieve the parameters from "listing-params" cookie.
 *
 * @param params The parameters to use for the listing params.
 * @returns A new ListingParams object cloned from the default listing params and merged with the provided parameters.
 */
export function createListingParams(params?: Partial<ListingParams>, serializer: ListingParamsSerializer = COOKIE_SERIALIZER_FUNC): ListingParams & ListingParamsMethods {
  let initialParams: Partial<ListingParams> = {};
  if (params) {
    initialParams = params;
  } else {
    const cookieParams = cookieDeserializer();
    if (cookieParams) {
      initialParams = cookieParams;
    }
  }

  if (initialParams?.filter?.homeType) {
    const homeTypeFilter = ensureBackwardCompatibilityForHomeType(initialParams.filter.homeType);
    delete homeTypeFilter.houseDetached;
    delete homeTypeFilter.houseSemidetached;
    delete homeTypeFilter.houseAttached;
    initialParams.filter.homeType = homeTypeFilter;
  }

  const listingParams = new ListingParamsBuilder().withInitialParams(initialParams).withSerializer(serializer).build();
  return listingParams;
}

//#region Public Functions

const cookieDeserializer: () => Partial<ListingParams> = () => getJSON<Partial<ListingParams>>(Cookies.get(storageKey));

//#endregion