import { ServerResponse } from 'http';
import cookie from 'cookie';
import { SET_COOKIE_HEADER_NAME } from 'constants/headers';
import { DEFAULT_EXPIRE_DAYS } from 'constants/cookies';
import { isDevelopment } from 'utils/environment';

/**
 * Sets a cookie in the server response headers without overriding existing cookies.
 * This function is specifically for server-side cookie management.
 *
 * @param res The server response object
 * @param name The name of the cookie to set
 * @param value The value to store in the cookie (will be JSON stringified)
 * @param options Optional cookie settings that override defaults
 */
export function setServerSideCookie(
  res: ServerResponse,
  name: string,
  value: any,
  options?: cookie.CookieSerializeOptions
) {
  const existingCookies = res.getHeader(SET_COOKIE_HEADER_NAME);

  const newCookie = cookie.serialize(name, JSON.stringify(value), {
    httpOnly: false, // Allow JavaScript access to cookies
    secure: !isDevelopment, // Use HTTPS in production
    maxAge: DEFAULT_EXPIRE_DAYS * 24 * 60 * 60, // 7 days
    path: '/', // Cookie available for entire site
    ...options, // Allow overriding defaults
  });

  if (!existingCookies) {
    // No existing cookies, set the new one
    res.setHeader(SET_COOKIE_HEADER_NAME, newCookie);
  } else {
    // Combine with existing cookies
    const cookies = Array.isArray(existingCookies)
      ? existingCookies.map(c => c.toString())
      : [existingCookies.toString()];

    // Check if this cookie name already exists
    const cookieExists = cookies.some(c => c.startsWith(`${name}=`));

    if (!cookieExists) {
      // Add new cookie to array
      cookies.push(newCookie);
      res.setHeader(SET_COOKIE_HEADER_NAME, cookies);
    }
  }
}