import { isServerSide } from './host-config';
import logger from './logger';

if (isServerSide()) {
  const originalConsoleError = console.error;
  const originalConsoleInfo = console.info;
  const originalConsoleDebug = console.debug;
  const originalConsoleTrace = console.trace;
  const originalConsoleLog = console.log;
  const originalConsoleWarn = console.warn;

  function captureConsoleOutput(fn: Function, args: any[]): string {
    const originalStdout = process.stdout.write;
    const originalStderr = process.stderr.write;
    let output = '';

    const captureWrite = (chunk: string | Uint8Array, encoding?: string | ((err?: Error) => void), callback?: (err?: Error) => void): boolean => {
      output += chunk.toString();
      if (typeof callback === 'function') callback();
      return true;
    };

    process.stdout.write = captureWrite;
    process.stderr.write = captureWrite;

    try {
      fn.call(console, ...args);
      return output;
    } finally {
      process.stdout.write = originalStdout;
      process.stderr.write = originalStderr;
    }
  }

  // Store original console methods to avoid recursion
  console.error = (message?: any, ...optionalParams: any[]) => {
    if (message instanceof Error) {
      logger.error(message as Error);
    } else {
      const output = captureConsoleOutput(originalConsoleError, [message, ...optionalParams]);
      if (output.length > 0) {
        logger.error(output);
      } else {
        logger.error(message, ...optionalParams);
      }
    }
  };

  console.info = (message?: any, ...optionalParams: any[]) => {
    const output = captureConsoleOutput(originalConsoleInfo, [message, ...optionalParams]);
    if (output?.length > 0) {
      logger.info(output);
    } else {
      logger.info(message, ...optionalParams);
    }
  };

  console.debug = (message?: any, ...optionalParams: any[]) => {
    const output = captureConsoleOutput(originalConsoleDebug, [message, ...optionalParams]);
    if (output?.length > 0) {
      logger.debug(output);
    } else {
      logger.debug(message, ...optionalParams);
    }
  };

  console.trace = (message?: any, ...optionalParams: any[]) => {
    const output = captureConsoleOutput(originalConsoleTrace, [message, ...optionalParams]);
    if (output?.length > 0) {
      logger.trace(output);
    } else {
      logger.trace(message, ...optionalParams);
    }
  };

  console.log = (message?: any, ...optionalParams: any[]) => {
    const output = captureConsoleOutput(originalConsoleLog, [message, ...optionalParams]);
    if (output?.length > 0) {
      logger.info(output);
    } else {
      logger.info(message, ...optionalParams);
    }
  };

  console.warn = (message?: any, ...optionalParams: any[]) => {
    const output = captureConsoleOutput(originalConsoleWarn, [message, ...optionalParams]);
    if (output?.length > 0) {
      logger.warn(output);
    } else {
      logger.warn(message, ...optionalParams);
    }
  };
}