
import { X_FORWARDED_FOR_HEADER_NAME } from 'constants/headers';
import { IncomingMessageType, X_VERCEL_FORWARDED_FOR_HEADER_NAME } from 'types/http';

const ipAddressRegexp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9a-f]{1,4}:){7}([0-9a-f]{1,4}|:))|(([0-9a-f]{1,4}:){6}(:[0-9a-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9a-f]{1,4}:){5}(((:[0-9a-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9a-f]{1,4}:){4}(((:[0-9a-f]{1,4}){1,3})|((:[0-9a-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){3}(((:[0-9a-f]{1,4}){1,4})|((:[0-9a-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){2}(((:[0-9a-f]{1,4}){1,5})|((:[0-9a-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){1}(((:[0-9a-f]{1,4}){1,6})|((:[0-9a-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9a-f]{1,4}){1,7})|((:[0-9a-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

/**
 * Check if the given string is a valid IP address. Supports both IPv4 and IPv6.
 * 
 * @param ipAddress - The string to check.
 * @returns True if the string is a valid IP address, false otherwise.
 */
export function isValidIpAddress(ipAddress: string) {
  return ipAddressRegexp.test(ipAddress);
}

/**
 * Get the client IP address from the X-Forwarded-For header.
 * 
 * @param req The incoming message object.
 * @returns The client IP address or null if the header is missing.
 */
export function getClientIpFromXForwardedFor(req: IncomingMessageType) {
  const xForwardedFor = req.headers[X_VERCEL_FORWARDED_FOR_HEADER_NAME] 
  || req.headers[X_FORWARDED_FOR_HEADER_NAME];

  if (!xForwardedFor) {
    return null;
  }

  let ipAddresses: string[] = [];

  if (typeof xForwardedFor === 'string') {
    ipAddresses = xForwardedFor.split(',').map(ip => ip.trim());
  } else if (Array.isArray(xForwardedFor)) {
    ipAddresses = xForwardedFor;
  }

  // Return the first IP address (usually the client IP)
  return ipAddresses.length > 0 ? ipAddresses[0] : null;
}