import configJSON from 'config.json';

/** 
 * Local Development - localhost:4200
 * 
 * NOTE: this is the default environment if no other environment is specified in the ENV environment variable
 */
export const ENVIRONMENT_DEVELOPMENT = 'development' as const;
/** Vercel Preview - https://zoocasa-next-[unique id]-zoocasa.vercel.app/ */
export const ENVIRONMENT_PREVIEW = 'preview' as const;
/** Staging - https://stage.zoocasa.com */
export const ENVIRONMENT_STAGING = 'staging' as const;
/** Production - https://www.zoocasa.com */
export const ENVIRONMENT_PRODUCTION = 'production' as const;
/** Integration - https://integration.t.zoocasa.com */
export const ENVIRONMENT_STAGE_TWO = 'stage-two' as const;

export const ENVIRONMENT_KEYS: readonly string[] = [ENVIRONMENT_PREVIEW, ENVIRONMENT_DEVELOPMENT, ENVIRONMENT_STAGING, ENVIRONMENT_PRODUCTION, ENVIRONMENT_STAGE_TWO];
export type Environment = typeof ENVIRONMENT_PREVIEW | typeof ENVIRONMENT_DEVELOPMENT | typeof ENVIRONMENT_STAGING | typeof ENVIRONMENT_PRODUCTION | typeof ENVIRONMENT_STAGE_TWO;

export const isVercel = Object.freeze(configJSON.environment === ENVIRONMENT_PREVIEW);
export const isProduction = Object.freeze(configJSON.environment === ENVIRONMENT_PRODUCTION);
export const isStaging = Object.freeze(configJSON.environment === ENVIRONMENT_STAGING);
export const isStageTwo = Object.freeze(configJSON.environment === ENVIRONMENT_STAGE_TWO);
export const isDevelopment = Object.freeze(configJSON.environment === ENVIRONMENT_DEVELOPMENT || !ENVIRONMENT_KEYS.includes(configJSON.environment));
export const isEndToEndTest = Object.freeze(configJSON.isEndToEndTest);
/**
 * Returns the environment based on the config.json file. If the environment is not valid, it returns {@link ENVIRONMENT_DEVELOPMENT} as the default environment.
 * @returns The environment based on the config.json file.
 */
export function getEnvironment(): Environment {
  if (!ENVIRONMENT_KEYS.includes(configJSON.environment)) {
    return ENVIRONMENT_DEVELOPMENT;
  } 
  return configJSON.environment as Environment;
}
