import {
  ListingParams,
  Filter,
  Sort,
  Page,
  PropertyTypeFilter,
  CondoOrTownhouseAdditionalFilter,
  Status,
  ListingParamsMethods,
} from './types';
import { DEFAULT_LISTING_PARAMS_COMMON } from './defaults';
import ListingParamsImpl from './listing-params-impl';
import { ListingParamsSerializer } from './listing-params-impl';
import { PartialDeep } from 'type-fest';
import setNestedProperty from 'set-value';
import deepmerge from 'deepmerge';
import { storageKey } from './utils';
import { DEFAULT_EXPIRE_DAYS } from 'constants/cookies';
import Cookies from 'js-cookie';

/**
 * Builder class for creating ListingParams objects.
 * Provides a fluent interface for setting all listing parameters and filters.
 *
 * Can be initialized with parameters from {@link withInitialParams}. Any
 * parameters set via the builder methods will override the initial values when {@link build()}
 * is called.
 *
 * @example
 * ```typescript
 * const params = new ListingParamsBuilder()
 *   .withInitialParams(existingParams)
 *   .withSort('-date')
 *   .withLocation(43.653226, -79.3831843, 14)
 *   .withSlug('toronto-on')
 *   .withAreaName('Toronto, ON')
 *   .build();
 * ```
 */
export class ListingParamsBuilder {

  private params: PartialDeep<ListingParams> = {};
  private initialParams?: Partial<ListingParams>;
  private serializer?: ListingParamsSerializer;

  /** Creates a new ListingParamsBuilder */
  constructor() {}

  /**
   * Sets the initial parameters to be used as a base for the builder.
   * These parameters will be merged with defaults and any subsequent changes.
   * @param params The initial ListingParams
   */
  withInitialParams(params: Partial<ListingParams>): ListingParamsBuilder {
    this.initialParams = params;
    return this;
  }

  /**
   * Sets the pagination parameters.
   * @param page The page object containing number and size
   */
  withPage(page: Page): ListingParamsBuilder {
    this.params.page = page;
    return this;
  }

  /**
   * Sets the sort criteria for the listings.
   * @param sort The sort criteria (e.g., '-date', 'price', etc.)
   */
  withSort(sort: Sort): ListingParamsBuilder {
    this.params.sort = sort;
    return this;
  }

  /**
   * Sets the complete filter object, overriding any existing filters.
   * @param filter The complete filter object
   */
  withFilter(filter: Filter): ListingParamsBuilder {
    this.params.filter = filter;
    return this;
  }

  /**
   * Sets the location slug (e.g., 'toronto-on').
   * @param slug The location slug
   */
  withSlug(slug: string): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.slug', slug);
    return this;
  }

  /**
   * Sets the geographical location coordinates and optional zoom level.
   * @param latitude The latitude coordinate
   * @param longitude The longitude coordinate
   * @param zoom Optional zoom level for map view
   */
  withLocation(latitude: number, longitude: number, zoom?: number): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.latitude', latitude);
    setNestedProperty(this.params, 'filter.longitude', longitude);
    if (zoom !== undefined) {
      setNestedProperty(this.params, 'filter.zoom', zoom);
    }
    return this;
  }

  /**
   * Sets the listing status filter.
   * @param status The status to filter by (e.g., 'available', 'not-available')
   */
  withStatus(status: Status): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.status', status);
    return this;
  }

  /**
   * Sets whether to show rental properties.
   * @param rental True to show rentals, false for sales
   */
  withRental(rental: boolean): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.rental', rental);
    return this;
  }

  /**
   * Sets the property types to include in the search.
   * @param homeType Object specifying which property types to include
   */
  withHomeType(homeType: PropertyTypeFilter): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.homeType', homeType);
    return this;
  }

  /**
   * Sets the price range for the search.
   * @param min Minimum price (null for no minimum)
   * @param max Maximum price (null for no maximum)
   */
  withPriceRange(min: number | null, max: number | null): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.priceMin', min);
    setNestedProperty(this.params, 'filter.priceMax', max);
    return this;
  }

  /**
   * Sets the minimum number of bedrooms.
   * @param bedrooms Number of bedrooms (e.g., '2+', '3+')
   */
  withBedrooms(bedrooms: string): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.bedrooms', bedrooms);
    return this;
  }

  /**
   * Sets the minimum number of bathrooms.
   * @param bathrooms Number of bathrooms (e.g., '1+', '2+')
   */
  withBathrooms(bathrooms: string): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.bathrooms', bathrooms);
    return this;
  }

  /**
   * Sets the square footage range for the search.
   * @param min Minimum square footage (null for no minimum)
   * @param max Maximum square footage (null for no maximum)
   */
  withSqftRange(min: number | null, max: number | null): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.sqftMin', min);
    setNestedProperty(this.params, 'filter.sqftMax', max);
    return this;
  }

  /**
   * Sets the minimum number of parking spaces.
   * @param parkingSpaces Number of parking spaces (e.g., '1+', '2+')
   */
  withParkingSpaces(parkingSpaces: string): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.parkingSpaces', parkingSpaces);
    return this;
  }

  /**
   * Sets the listing date range filter.
   * @param since Start date of the range (null for no start date)
   * @param to End date of the range (null for no end date)
   */
  withListingDates(since: string | null, to: string | null): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.listedSince', since);
    setNestedProperty(this.params, 'filter.listedTo', to);
    return this;
  }

  /**
   * Sets additional details specific to condos or townhouses.
   * @param details Object containing locker and maintenance fee details
   */
  withCondoOrTownhouseDetails(details: CondoOrTownhouseAdditionalFilter): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.additional.condoOrTownhouse', details);
    return this;
  }

  /**
   * Sets the area name for the search.
   * @param areaName The name of the area (e.g., 'Toronto, ON')
   */
  withAreaName(areaName: string): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.areaName', areaName);
    return this;
  }

  /**
   * Sets the boundary filter for the search.
   * @param boundary The boundary string or null
   */
  withBoundary(boundary: string | null): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.boundary', boundary);
    return this;
  }

  /**
   * Sets the provider ID filter.
   * @param providerId The provider ID or null
   */
  withProviderId(providerId: string | null): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.providerId', providerId);
    return this;
  }

  /**
   * Sets whether to only show listings with images.
   * @param hasImage True to only show listings with images
   */
  withHasImage(hasImage: boolean): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.hasImage', hasImage);
    return this;
  }

  /**
   * Sets multiple amenity filters at once.
   * @param amenities Object containing boolean flags for various amenities
   */
  withAmenities({
    openHouse = false,
    garage = false,
    pool = false,
    fireplace = false,
    waterfront = false,
  }: {
    openHouse?: boolean;
    garage?: boolean;
    pool?: boolean;
    fireplace?: boolean;
    waterfront?: boolean;
  }): ListingParamsBuilder {
    setNestedProperty(this.params, 'filter.openHouse', openHouse);
    setNestedProperty(this.params, 'filter.garage', garage);
    setNestedProperty(this.params, 'filter.pool', pool);
    setNestedProperty(this.params, 'filter.fireplace', fireplace);
    setNestedProperty(this.params, 'filter.waterfront', waterfront);
    return this;
  }

  /**
   * Sets the serializer to be used for the listing params when any properties are updated.
   * @param serializer The serializer to be used
   */
  withSerializer(serializer: ListingParamsSerializer): ListingParamsBuilder {
    this.serializer = serializer;
    return this;
  }

  /**
   * Builds and returns the final ListingParams object.
   * Merges parameters in the following order:
   * 1. Default {@link DEFAULT_LISTING_PARAMS_COMMON} with initial params (if provided)
   * 2. Any params set via builder methods
   *
   * @returns A new ListingParams object with all configured parameters
   */
  build(): ListingParams & ListingParamsMethods {
    const mergedInitialParams = deepmerge(DEFAULT_LISTING_PARAMS_COMMON, this.initialParams || {});
    const listingParams = deepmerge(mergedInitialParams, this.params);
    return new ListingParamsImpl(listingParams, this.serializer || COOKIE_SERIALIZER_FUNC);
  }
}

/**
 * Serializes the listing params to a cookie.
 * @param params The listing params to serialize
 */
export const COOKIE_SERIALIZER_FUNC: ListingParamsSerializer = (params: ListingParams) => {
  const serializedParams = JSON.stringify(params);
  Cookies.set(storageKey, serializedParams, { expires: DEFAULT_EXPIRE_DAYS });
};
