import { removeKeysWithBlankValues } from '@zoocasa/node-kit/objects/remove-keys';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import { ProvinceOrStateCode, provinceOrStateCodeFromSlug, provinceAndStateCodes, provinceOrStateSlugFromCode, provinceAndStateSlugs } from 'utils/province_or_state';
import dataJSON from './data.json';
import {
  AreaListingsRouteMatchObject,
  AREA_LISTINGS_ROUTE,
  CONDOS_HOME_TYPE,
  COUNTRY_LOCATION_TYPE,
  EXPIRED_LISTINGS_LISTING_STATUS,
  RoutePropertyType,
  HOUSES_HOME_TYPE,
  LEASED_LISTING_STATUS,
  ListingRouteMatchObject,
  ListingStatus,
  LISTING_ROUTE,
  LOCATIONS_ROUTE,
  LocationType,
  NamedContentRouteMatchObject,
  NAMED_CONTENT_ROUTE,
  NEIGHBOURHOOD_LOCATION_TYPE,
  PAST_LISTINGS_LISTING_STATUS,
  REAL_ESTATE_HOME_TYPE,
  Route,
  RouteMatchObject,
  SOLD_LISTINGS_LISTING_STATUS,
  SOLD_LISTING_STATUS,
  STREET_LOCATION_TYPE,
  TOWNHOUSES_HOME_TYPE,
  LAND_HOME_TYPE,
  COMMERCIAL_HOME_TYPE,
  FARMS_HOME_TYPE,
  EMBER_ROUTE,
} from './types';

import {
  AVAILABLE_STATUS,
  Filter,
  PropertyTypeFilter,
  NOT_AVAILABLE_OTHER_STATUS,
  NOT_AVAILABLE_SOLD_STATUS,
  Status,
} from 'contexts/preferences/listing-params/types';
import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { getFilterFromURLSearchParams } from 'utils/listing-query-helper';
import deepmerge from 'deepmerge';
import { defaultHomeType } from 'constants/listing-components';
import { EMBER_PAGE_URLS } from 'constants/ember-pages';
import { SEARCH_PAGE_ROUTE_NAME } from 'types/search_page_types';

import type { PartialDeep } from 'type-fest';
import type { matchGroup } from '@zoocasa/node-kit/strings/regex-match-in-groups';

//#region Types
type RouteMatcher = { route: Route; locationType?: LocationType | null; pathRegex: string };
type MatchInfoType = { [key: string]: string | null };
type SecondaryFilterType = Pick<Filter, 'bedrooms'> | Pick<Filter, 'bathrooms'> | Pick<Filter, 'garage'> | Pick<Filter, 'pool'> | Pick<Filter, 'waterfront'> | Pick<Filter, 'openHouse'>;
type StatusMapType = { status: Status; urlSnippet: ListingStatus | null };
//#endregion

//#region Constants
const StatusMap: readonly StatusMapType[] = [
  { status: AVAILABLE_STATUS, urlSnippet: null },
  { status: NOT_AVAILABLE_SOLD_STATUS, urlSnippet: SOLD_LISTINGS_LISTING_STATUS },
  { status: NOT_AVAILABLE_SOLD_STATUS, urlSnippet: SOLD_LISTING_STATUS },
  { status: NOT_AVAILABLE_SOLD_STATUS, urlSnippet: LEASED_LISTING_STATUS },
  { status: NOT_AVAILABLE_OTHER_STATUS, urlSnippet: PAST_LISTINGS_LISTING_STATUS },
  { status: NOT_AVAILABLE_OTHER_STATUS, urlSnippet: EXPIRED_LISTINGS_LISTING_STATUS },
];

const SupportedHomeTypes: readonly string[] = [
  HOUSES_HOME_TYPE,
  TOWNHOUSES_HOME_TYPE,
  CONDOS_HOME_TYPE,
  REAL_ESTATE_HOME_TYPE,
  LAND_HOME_TYPE,
  COMMERCIAL_HOME_TYPE,
  FARMS_HOME_TYPE,
];

const { citiesWithNeighbourhoods } = dataJSON;
const AreaPageSecondaryFilters = dataJSON.areaPageSecondaryFilters.join('|');
const KeyFilters = dataJSON.keyFilters.join('|');

const isRentalRegex = /(\/(?<isRental>for-rent))?/.source;
const listingStatusRegex = /(\/(?<listingStatus>sold|past-listings|leased|expired-listings))?/.source;
const homeTypeRegex = /(\/(?<homeType>houses|townhouses|condos|land|commercial|farms))?/.source;
const primaryFilterRegex = /(\/(?<primaryFilter>open-houses))?/.source;
const secondaryFilterRegex= `(/(?<secondaryFilter>${AreaPageSecondaryFilters}))`;
const hasFilterQueryRegex = /\/(?<hasFilterQuery>filter)/.source;
const filterQueryRegex = /(?<filterQuery>\\?.*)?/.source;
const pageQueryRegex = /((\?|&)page=([0-9]*))?/.source;
const sortQueryRegex = /((\?|&)sort=([a-zA-Z0-9\\-]*))?/.source;
const slugFallbackQueryRegex = /((\?|&)slug-fallback=([a-zA-Z0-9\\-]*))?/.source;
const extrasQueryRegex = /((\?|&).*=(.*))?/?.source;
const searchPageRegex = /^(?<url>\/search(?:\?.*)?)$/;
const RouteMatchers: readonly RouteMatcher[] = [
  ...generateRouteMatchers(AREA_LISTINGS_ROUTE, `${isRentalRegex}${listingStatusRegex}${homeTypeRegex}${primaryFilterRegex}${secondaryFilterRegex}${pageQueryRegex}${sortQueryRegex}${slugFallbackQueryRegex}`),
  ...generateRouteMatchers(AREA_LISTINGS_ROUTE, `${isRentalRegex}${listingStatusRegex}${homeTypeRegex}${primaryFilterRegex}(${secondaryFilterRegex})?${hasFilterQueryRegex}${filterQueryRegex}`),
  { route: LISTING_ROUTE, pathRegex: `(?<city>[A-Za-z-0-9\\-]+)-(?<provinceCode>${provinceAndStateCodes.join('|')})-real-estate(/(?<neighbourhood>[a-zA-Z0-9-]*))?/(?<address>[a-z]*-?(?<listingId>\\d+)[\\w\\-]+)` },
  ...generateRouteMatchers(AREA_LISTINGS_ROUTE, `${isRentalRegex}${listingStatusRegex}${homeTypeRegex}${primaryFilterRegex}(${secondaryFilterRegex})?${pageQueryRegex}${sortQueryRegex}${slugFallbackQueryRegex}${extrasQueryRegex}`),
  ...generateRouteMatchers(LOCATIONS_ROUTE, '(?<isLocation>provinces|cities|neighbourhoods|streets)'),
  { route: SEARCH_PAGE_ROUTE_NAME, pathRegex: `${searchPageRegex}` },
  { route: NAMED_CONTENT_ROUTE, pathRegex: '(?<url>.*)' },
];
//#endregion

/**
 * Check if the path is an Ember page path.
 *
 * The list of Ember page paths is defined in {@link EMBER_PAGE_URLS} and also includes any
 * path that starts with `/building` or `/education-centre`.
 *
 * @param path the path to check
 * @returns true if the path is an Ember page path, false otherwise
 */
export function isEmberPagePath(path: string) {
  return EMBER_PAGE_URLS.includes(path) || path.startsWith('/building') || path.startsWith('/education-centre');
}

export function isSearchPagePath(path: string) {
  return searchPageRegex.test(path);
}

/**
 * Identify the route name based on the given url path
 *
 * @returns the route name associated to the given url path. *undefined* when no route name is found.
 */
export function getRouteNameFromPath(path: string) {
  const pathname = (!path && window.location) ? window.location.pathname : path;
  if (isEmberPagePath(pathname)) {
    return EMBER_ROUTE;
  }
  let routeName: Route | undefined;

  const routerMatcherPredicate = (routerMatcher: RouteMatcher) => {
    const regexStr = `^/?${routerMatcher.pathRegex}/?$`;
    if (new RegExp(regexStr.replace(/\?<(.+?)>/g, '')).test(pathname)) {
      routeName = routerMatcher.route;
      return true;
    }
  };
  RouteMatchers.find(routerMatcherPredicate);
  return routeName;
}

function regexMatchInGroups(str: any, regexStr: any) {
  const groups: any = {};
  const regex = new RegExp(regexStr);
  if (regex.test(str)) {
    const matches = str.match(regex);
    const groupMatches = regexStr.match(/\?<(.+?)>/g) || [];
    groupMatches.forEach((group: any) => {
      const name = group.replace(/^\?</, '').replace(/>$/, '');
      groups[name] = matches['groups'][name];
    });
  }
  return groups;
}

export function generateRouteMatchObjectFromPath(path: string, setDefaults = true): RouteMatchObject | ListingRouteMatchObject | NamedContentRouteMatchObject | AreaListingsRouteMatchObject {
  if (!path && window.location) {
    path = window.location.pathname;
  }
  let routeMatchObject = {} as RouteMatchObject;
  const routerMatcherPredicate = (routerMatcher: RouteMatcher) => {
    const regexStr = `^/?${routerMatcher.pathRegex}/?$`;
    if (new RegExp(regexStr.replace(/\?<(.+?)>/g, '')).test(path)) {
      const matchGroup = regexMatchInGroups(path, regexStr);
      const matchInfo = extractMatchInfoObject(matchGroup);
      const { route, locationType } = routerMatcher;

      if (route === LISTING_ROUTE) {
        const listingRouteMatchObject: ListingRouteMatchObject = {
          route: route,
          locationType: locationType,
          ...listingInfo(matchGroup),
        };

        routeMatchObject = { ...listingRouteMatchObject };
        return true;
      } else if (route === NAMED_CONTENT_ROUTE) {
        const namedContentRouteMatchObject: NamedContentRouteMatchObject = {
          route: route,
          locationType: locationType,
          url: matchInfo.url as string,
        };

        routeMatchObject = { ...namedContentRouteMatchObject };
        return true;
      } else if (route === AREA_LISTINGS_ROUTE) {
        const { primaryFilter, secondaryFilter, isRental, listingStatus, hasFilterQuery, filterQuery } = matchInfo;
        let countryAreaName;
        if (matchInfo?.country) {
          if (matchInfo.country === 'us') {
            countryAreaName = 'USA';
          } else if (matchInfo.country === 'ca') {
            countryAreaName = 'Canada';
          }
        }
        const areaListingsRouteMatchObject: AreaListingsRouteMatchObject = {
          ... removeKeysWithBlankValues(matchInfo),
          route: AREA_LISTINGS_ROUTE,
          locationType: locationType,
          filter: {
            slug: buildSlug(locationType as LocationType, matchInfo),
            areaName: capitalizeWords(deDasherize(matchInfo.neighbourhood || matchInfo.city || matchInfo.province || countryAreaName || 'Canada')),
          },
        };

        // If primaryFilter is set, that means we should filter for Open Houses
        if (primaryFilter) {
          areaListingsRouteMatchObject.filter.openHouse = true;
        }

        // If secondaryFilter is set, that means that the url has one of the following filters set: bedrooms, bathrooms, waterfront, garage, pool or open-house
        if (secondaryFilter) {
          const secondaryFilterValue = getSecondaryFilterValue(secondaryFilter);
          if (secondaryFilterValue) {
            areaListingsRouteMatchObject.filter = { ...areaListingsRouteMatchObject.filter, ... secondaryFilterValue };
          }
        }

        const homeType = matchInfo.homeType || REAL_ESTATE_HOME_TYPE;
        const homeTypeFilter = getHomeTypeFilter(homeType, setDefaults);
        if (homeTypeFilter) {
          areaListingsRouteMatchObject.filter.homeType = homeTypeFilter;
          areaListingsRouteMatchObject.propertyType = homeType as RoutePropertyType;
        }

        const rental = isRental && isRental.includes('rent'); // : (setDefaults ? false : undefined);
        if (rental) {
          areaListingsRouteMatchObject.filter.rental = rental;
          areaListingsRouteMatchObject.isRental = isRental as string;
        } else if (setDefaults) {
          areaListingsRouteMatchObject.filter.rental = false;
          areaListingsRouteMatchObject.isRental = undefined;
        }

        const status = getListingStatusFilter(listingStatus || '', setDefaults);
        if (status) {
          areaListingsRouteMatchObject.filter.status = status;
          areaListingsRouteMatchObject.listingStatus = listingStatus as ListingStatus;
          if (listingStatus === LEASED_LISTING_STATUS) {
            areaListingsRouteMatchObject.filter.rental = true;
            areaListingsRouteMatchObject.isRental = 'true';
          }
        } else if (setDefaults) {
          areaListingsRouteMatchObject.filter.status = AVAILABLE_STATUS;
          areaListingsRouteMatchObject.listingStatus = undefined;
        }

        if (hasFilterQuery) {
          const filter: PartialDeep<Filter> = getURLSearchParamsAsFilter(filterQuery || '', setDefaults);
          areaListingsRouteMatchObject.filter = deepmerge(areaListingsRouteMatchObject.filter, filter);
        }

        // If filter provider feature flag is enabled, we can filter listings by providerId
        if (filterQuery && filterQuery?.includes('provider-id')) {
          const queryParams = new URLSearchParams(filterQuery);
          const providerId = queryParams.get('provider-id');
          if (typeof providerId === 'string') areaListingsRouteMatchObject.filter.providerId = providerId;
        }

        if (filterQuery && filterQuery?.includes('has-image')) {
          // Default value is false, so we only need to set it to true if the query param is present
          areaListingsRouteMatchObject.filter.hasImage = true;
        }

        routeMatchObject = { ...areaListingsRouteMatchObject };
        return true;
      }

      return false;
    }
  };
  RouteMatchers.find(routerMatcherPredicate);
  return routeMatchObject;
}

export function generatePathFromRouteMatchObject(...routeMatchObjects: RouteMatchObject[]) {
  const routeMatchObject = Object.assign({}, ...routeMatchObjects);
  const { homeType, isRental, isBuilding, isSchool, listingStatus, isLocation, locationType, province, provinceCode, city, neighbourhood, street } = routeMatchObject;
  const pageTypeSuffix = isBuilding || isSchool || isLocation || listingStatus || homeType || 'real-estate';
  const rentalSuffix = isRental && !isBuilding && !isSchool && !isLocation ? '-for-rent' : '';
  if (!locationType) {
    return `${pageTypeSuffix}${rentalSuffix}`;
  } else if (locationType === 'province') {
    return `${province}-${pageTypeSuffix}${rentalSuffix}`;
  } else if (locationType === 'city') {
    return `${city}-${provinceCode}-${pageTypeSuffix}${rentalSuffix}`;
  } else if (locationType === 'neighbourhood') {
    return `${city}-${provinceCode}-${pageTypeSuffix}${rentalSuffix}/${neighbourhood}`;
  } else if (locationType === 'street') {
    const slug = neighbourhood ? `${neighbourhood}/${street}` : street;
    return `${city}-${provinceCode}-${pageTypeSuffix}${rentalSuffix}/${slug}`;
  }
}

export function generateLocationFromRouteMatchObject(routeMatchObject: AreaListingsRouteMatchObject, detailedStreetLocation = false) {
  const { locationType, country, province, city, neighbourhood, street } = routeMatchObject;
  let formattedCountry;
  if (country === 'us') {
    formattedCountry = 'USA';
  } else if (country === 'ca') {
    formattedCountry = 'Canada';
  }
  let location = city || province || formattedCountry || 'canada';
  if (locationType === NEIGHBOURHOOD_LOCATION_TYPE) {
    location = `${neighbourhood}, ${city}`;
  } else if (locationType === STREET_LOCATION_TYPE) {
    if (neighbourhood) {
      location = `${street}, ${neighbourhood}`;
    } else {
      location = `${street}`;
    }
    if (detailedStreetLocation) {
      location += `, ${city}`;
    }
  }
  return capitalizeWords(deDasherize(location));
}

function buildSlug(locationType: LocationType, matchInfo: MatchInfoType) {
  const { province, provinceCode, city, neighbourhood, street, secondaryFilter } = matchInfo;
  if (locationType === 'street') {
    const prefix = [neighbourhood, city, provinceCode].filter(i => i).join('-');
    return `${street}-${prefix}`;
  } else if (locationType === 'neighbourhood') {
    return `${neighbourhood}-${city}-${provinceCode}`;
  } else if (locationType === 'city' || (secondaryFilter && city)) {
    return `${city}-${provinceCode}`;
  } else if (locationType === 'province' || (secondaryFilter && province)) {
    return province || '';
  } else {
    return '';
  }
}

function generateRouteMatchers(route: Route, matcherContent: string): RouteMatcher[] {
  const countryRegex = '(?<country>ca|us)';
  const provinceRegex = `(?<province>${provinceAndStateSlugs.join('|')})`;
  const listingsCityRegex = `(?<city>[A-Za-z-0-9\\-]+)-(?<provinceCode>${provinceAndStateCodes.join('|')})`;
  const locationsCityRegex = buildCityWithProvinceCode(provinceAndStateCodes.map(code => `.*?-${code}`));
  const cityThatHasNeighbourhoodRegex = buildCityWithProvinceCode(citiesWithNeighbourhoods);
  const neighbourhoodRegex = `(?<neighbourhood>(?!${KeyFilters})[A-Za-z0-9\\-]+)`;
  const streetRegex = `(?:${neighbourhoodRegex}/)?(?<street>(?!${KeyFilters})[A-Za-z\\-]+[\\dA-Za-z\\-]*)`;

  const locationTypes: {[key: string]:{ prefix?:string; suffix?:string }} = {
    country: { prefix: countryRegex },
    province: { prefix: provinceRegex },
    city: { prefix: route === AREA_LISTINGS_ROUTE ? listingsCityRegex : locationsCityRegex },
    neighbourhood: { prefix: cityThatHasNeighbourhoodRegex, suffix: neighbourhoodRegex },
    street: { prefix: locationsCityRegex, suffix: streetRegex },
  };

  return Object.keys(locationTypes).map(locationType => {
    let { prefix, suffix } = locationTypes[locationType as keyof typeof locationTypes];
    prefix = prefix ? `${prefix}-` : '';
    suffix = suffix ? `/${suffix}` : '';

    return {
      route: route,
      locationType: locationType === COUNTRY_LOCATION_TYPE ? null : locationType as LocationType,
      pathRegex: `${prefix}real-estate${suffix}${matcherContent}`,
    };
  });
}

function extractMatchInfoObject(matchGroup: matchGroup): MatchInfoType {
  const matchInfo: MatchInfoType = { ... matchGroup };
  const cityWithProvinceCode = matchGroup.cityWithProvinceCode;
  if (cityWithProvinceCode) {
    // Re-fill the city & provinceCode for citiesWithNeighbourhoods which don't get captured by the regex
    const parts = cityWithProvinceCode.split('-');
    const provinceCode = parts.pop();
    const city = parts.join('-');
    Object.assign(matchInfo, { city: city, provinceCode: provinceCode });
  }
  if (!matchInfo.province && matchInfo.provinceCode && matchInfo.provinceCode.toLowerCase()) {
    matchInfo.province = provinceOrStateSlugFromCode(matchInfo.provinceCode.toLowerCase() as ProvinceOrStateCode);
  } else if (!matchInfo.provinceCode && matchInfo.province) {
    matchInfo.provinceCode = provinceOrStateCodeFromSlug(matchInfo.province);
  }
  return matchInfo;
}

function listingInfo(matchGroup: matchGroup) {
  const isRental = !!matchGroup.isRental;
  const slugSuffix = [matchGroup.city, matchGroup.provinceCode].join('-');
  // The long listing url includes the slug suffix (ex. toronto-on) which
  // means that this is a single listing page and we can query by listingId
  if (matchGroup?.address?.match(slugSuffix) && !isRental) {
    return { listingId: matchGroup.listingId };
  } else { // otherwise we build a listingSlug from the url fragments
    return {
      listingSlug: [matchGroup.address, slugSuffix].join('-'),
    };
  }
}

function getHomeTypeFilter(homeTypeSegment: string, setDefaults = true): PropertyTypeFilter | undefined {
  if (!homeTypeSegment || !SupportedHomeTypes.includes(homeTypeSegment)) {
    return setDefaults ? defaultHomeType : undefined;
  }

  if (homeTypeSegment === REAL_ESTATE_HOME_TYPE ) {
    return defaultHomeType;
  }

  return {
    house: homeTypeSegment === HOUSES_HOME_TYPE,
    townhouse: homeTypeSegment === TOWNHOUSES_HOME_TYPE,
    condo: homeTypeSegment === CONDOS_HOME_TYPE,
    land: homeTypeSegment === LAND_HOME_TYPE,
    commercial: homeTypeSegment === COMMERCIAL_HOME_TYPE,
    farm: homeTypeSegment === FARMS_HOME_TYPE,
  };
}

function buildCityWithProvinceCode(...cityGroups: string[][]) {
  const allCities: string[] = [];
  cityGroups.forEach(group => allCities.push(...group));
  return `(?<cityWithProvinceCode>${allCities.join('|')})`;
}

function getListingStatusFilter(listingStatusUrlSegment: string, setDefaults = true) {
  if (setDefaults && listingStatusUrlSegment.trim().length === 0)
    return undefined;

  const match = StatusMap.filter(value => value.urlSnippet === (listingStatusUrlSegment || null))[0];
  if (match) {
    return match.status;
  }
}

/**
 * Parses the secondary filter path segment and try to match to one of the supported filter properties.
 *
 * @argument {string} secondaryFilter one of the following filters: `^([1-6])-bedroom`, `^([1-6])-bathroom`, `on-waterfront`, `with-garage`, `with-swimming-pool` or `open-houses`.
 * @returns the filter key and value wrapped as an object. *undefined* if the secondaryFilter is not supported.
 */
function getSecondaryFilterValue(secondaryFilter: string): SecondaryFilterType | undefined{
  const sanitizedSecondaryFilter = secondaryFilter.replace(/^\s+|\s+$/gm, '');

  if (sanitizedSecondaryFilter.includes('-bedroom')) {
    const matchIndex = sanitizedSecondaryFilter.search(/^([1-6])-bedroom/);
    if (matchIndex != -1) {
      const bedroomCount = sanitizedSecondaryFilter[matchIndex];
      return { bedrooms: `${bedroomCount}+` };
    }
  } else if (sanitizedSecondaryFilter?.includes('-bathroom')) {
    const matchIndex = sanitizedSecondaryFilter.search(/^([1-6])-bathroom/);
    if (matchIndex != -1) {
      const bathroomCount = sanitizedSecondaryFilter[matchIndex];
      return { bathrooms: `${bathroomCount}+` };
    }
  } else if (sanitizedSecondaryFilter === 'on-waterfront') {
    return { waterfront: true };
  } else if (sanitizedSecondaryFilter === 'with-garage') {
    return { garage: true };
  } else if (sanitizedSecondaryFilter === 'with-swimming-pool') {
    return { pool: true };
  } else if (sanitizedSecondaryFilter === 'open-houses') {
    return { openHouse: true };
  }

  return undefined;
}

/**
 * Parses the given url search params string and try to create a valid Filter object.
 * **Note**: `Unknown` and `out of range` values will be ignored.
 *
 * @param urlParams A string, which will be parsed from `application/x-www-form-urlencoded` format. A leading `'?'` character is ignored.
 * @param setDefaults when set to `true` will merge the parsed filter with the `defaultListingParams.filter` values.
 * @returns A Filter object based on the given urlSearchParams.
 */
function getURLSearchParamsAsFilter(urlSearchParams: string, setDefaults = true): PartialDeep<Filter> {
  const searchParams = new URLSearchParams(urlSearchParams);
  let filter = getFilterFromURLSearchParams(searchParams);
  if (setDefaults) {
    filter = deepmerge(defaultListingParams.filter, filter);
  }
  return filter;
}