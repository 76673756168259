import { humanize } from '@zoocasa/node-kit/strings';

export enum CountryCodeList {
  CANADA = 'CA',
  UNITED_STATES = 'US',
}

export type CountryCode =
  CountryCodeList.CANADA |
  CountryCodeList.UNITED_STATES;

export function convertStringToCountryCode(value: string): CountryCodeList | undefined {
  return (Object.values(CountryCodeList) as Array<string>).includes(value) ? (value as CountryCodeList) : undefined;
}

/**
 * Gets the country name from its code.
 * 
 * @param code The country code to get the name for
 * @returns The country name if the code is valid, otherwise 
 *  the code itself as an {@link humanize humanized} string
 */
export function getCountryName(code: string): string {
  switch (code.toUpperCase()) {
  case CountryCodeList.CANADA:
    return 'Canada';
  case CountryCodeList.UNITED_STATES:
    return 'USA';
  default:
    return humanize(code);
  }
}
