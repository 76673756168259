export const ACTIVECAMPAIGN_SCRIPT = 'activecampaign-script';
export const GTM_SCRIPT = 'googletagmanager-script';
export const NEWRELIC_SCRIPT = 'newrelic-script';
export const GOOGLEMAPS_SCRIPT = 'googlemaps-script';
export const HUBSPOT_TRACKING_SCRIPT = 'hs-script-loader';

export const TRUSTARC_SCRIPT = 'trustarc-script';
export const TRUSTARC_DIV_ID = 'teconsent';

export const CONSENT_BLACKBAR_DIV_ID = 'consent_blackbar';
export const COOKIE_BANNER_Z_INDEX = 9999;