import { cloneDeep } from 'lodash';
import { updatedDiff } from 'deep-object-diff';
import DEFAULT_LISTING_PARAMS from 'contexts/preferences/listing-params/defaults';
import { getTargetedUrlPropertyTypeSelection, HOMES_TARGETED_URL_PROPERTY_TYPE } from 'utils/listing-query-helper';
// import { generateRouteMatchObjectFromPath, RouteMatchObject } from '../route-matchers';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';

import {
  AREA_NAME_PROPERTY,
  BATHROOMS_PROPERTY,
  BEDROOMS_PROPERTY,
  BOUNDARY_PROPERTY,
  COMMERCIAL_PROPERTY_TYPE,
  CONDO_PROPERTY_TYPE,
  FARM_PROPERTY_TYPE,
  GARAGE_PROPERTY,
  HOUSE_ATTACHED_PROPERTY_TYPE,
  HOUSE_DETACHED_PROPERTY_TYPE,
  HOUSE_PROPERTY_TYPE,
  HOUSE_SEMIDETACHED_PROPERTY_TYPE,
  LAND_PROPERTY_TYPE,
  LATITUDE_PROPERTY,
  LONGITUDE_PROPERTY,
  NOT_AVAILABLE_SOLD_STATUS,
  OPEN_HOUSE_PROPERTY,
  POOL_PROPERTY,
  RENTAL_PROPERTY,
  SLUG_PROPERTY,
  STATUS_PROPERTY,
  TOWNHOUSE_PROPERTY_TYPE,
  WATERFRONT_PROPERTY,
  ZOOM_PROPERTY,
  type Filter,
  type FlattenFilter,
  type Sort } from 'contexts/preferences/listing-params/types';
import type { PartialDeep } from 'type-fest';
import { FILTER_ON_WATERFRONT, FILTER_OPEN_HOUSES, FILTER_WITH_GARAGE, FILTER_WITH_SWIMMING_POOL, LISTING_STATUS_FOR_RENT, LISTING_STATUS_LEASED, LISTING_STATUS_PAST_LISTINGS, LISTING_STATUS_SOLD, parseAddressPath } from 'data/addresses/go-search/addressPathParser';
import { ROOT_PATH_SUFFIX } from 'data/addresses/go-search/types';
import { AddressType } from '@zoocasa/go-search';
import { PAGE_URL_SEARCH_PARAM, SLUG_FALLBACK_URL_SEARCH_PARAM, SORT_URL_SEARCH_PARAM } from 'types/SupportedURLSearchParam';

//#region Types
export type TargetedUrlHelper = {
  /**
   * The URL to be used for the targeted URL.
   */
  url: string;
  /**
   * The filters to be used for the targeted URL.
   */
  filters: Filter;
  /**
   * The sort to be used for the targeted URL.
   */
  sort: Sort;
  /**
   * The page number to be used for the targeted URL.
   */
  pageNumber: number;
  /**
   * The fallback slug to be used for the targeted URL.
   */
  fallbackSlug?: string;
  /**
   * The root path suffix to be used for the targeted URL.
   */
  rootPathSuffix?: string;
};

type FLATTEN_DEFAULT_FILTERS_TYPE = Omit<FlattenFilter, typeof LATITUDE_PROPERTY | typeof LONGITUDE_PROPERTY | typeof ZOOM_PROPERTY | typeof AREA_NAME_PROPERTY | typeof SLUG_PROPERTY | typeof BOUNDARY_PROPERTY>;

type SeoFriendlyKeywordsHelper = {
  isSinglePropertyType: boolean;
  activePropertyType: string;
  isLeasedStatus: boolean;
  isRentalTheSame: boolean;
  isListingStatusTheSame: boolean;
  filters: Filter;
  isOpenHouseTheSame: boolean;
  isWaterfrontTheSame: boolean;
  isGarageTheSame: boolean;
  isPoolTheSame: boolean;
}

//#endregion

const FLATTEN_DEFAULT_FILTERS: FLATTEN_DEFAULT_FILTERS_TYPE = function (filter: Filter): FLATTEN_DEFAULT_FILTERS_TYPE {
  const {
    additional,
    homeType,
    slug,
    areaName,
    latitude,
    longitude,
    zoom,
    boundary,
    ...rest
  } = filter;

  return {
    ...rest,
    ...homeType,
    ...additional.condoOrTownhouse,
  } as FLATTEN_DEFAULT_FILTERS_TYPE;
}(DEFAULT_LISTING_PARAMS.filter);

const COMMON_FILTER_ATTRIBUTES_TO_IGNORE: readonly string[] = [
  'rental',
  'bedrooms',
  'bathrooms',
  'waterfront',
  'garage',
  'pool',
  'openHouse',
  'areaName',
  'slug',
  'status',
  HOUSE_PROPERTY_TYPE,
  TOWNHOUSE_PROPERTY_TYPE,
  CONDO_PROPERTY_TYPE,
  FARM_PROPERTY_TYPE,
  COMMERCIAL_PROPERTY_TYPE,
  LAND_PROPERTY_TYPE,
].sort();

const HOMES_FILTER_ATTRIBUTES_TO_IGNORE: readonly string[] = [
  ...COMMON_FILTER_ATTRIBUTES_TO_IGNORE,
  HOUSE_DETACHED_PROPERTY_TYPE,
  HOUSE_SEMIDETACHED_PROPERTY_TYPE,
  HOUSE_ATTACHED_PROPERTY_TYPE,
].sort();

const SUPPORTED_SEO_ADDRESS_TYPES = [
  AddressType.ADDRESS_TYPE_COUNTRY,
  AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE,
  AddressType.ADDRESS_TYPE_SUB_DIVISION,
  AddressType.ADDRESS_TYPE_NEIGHBOURHOOD,
];

//#region Exported Functions

export default function getTargetedUrl({
  url,
  filters,
  sort,
  pageNumber,
  fallbackSlug = '',
  rootPathSuffix = ROOT_PATH_SUFFIX,
}: TargetedUrlHelper) {
  const srcUrl = new URL(url);

  const {
    areaName,
    latitude,
    longitude,
    zoom,
    boundary,
    ...filteredFilters
  } = filters;

  const userFilters: PartialDeep<Filter> = cloneDeep(filteredFilters);
  const flattenUserFilters = getFlattenFilters(userFilters);

  const updatedFiltersDiff: Partial<FlattenFilter> = updatedDiff(FLATTEN_DEFAULT_FILTERS, flattenUserFilters);
  const extendedFiltersSearchParams = filterToURLSearchParams(updatedFiltersDiff);
  const parsedAreaPathFromUrl = parseAddressPath(srcUrl.pathname, rootPathSuffix);
  const targetPath = (parsedAreaPathFromUrl.length === 1) ?
    parsedAreaPathFromUrl[0]
    : parsedAreaPathFromUrl.filter(segment => segment.type === AddressType.ADDRESS_TYPE_NEIGHBOURHOOD)?.[0];

  // If not a supported SEO address type, return the original URL without any filters
  if (!targetPath || !SUPPORTED_SEO_ADDRESS_TYPES.includes(targetPath.type)) {
    return srcUrl;
  }
  const isHouseTheSame = !Object.hasOwn(updatedFiltersDiff, HOUSE_PROPERTY_TYPE);
  const isTownhouseTheSame = !Object.hasOwn(updatedFiltersDiff, TOWNHOUSE_PROPERTY_TYPE);
  const isCondoTheSame = !Object.hasOwn(updatedFiltersDiff, CONDO_PROPERTY_TYPE);
  const isFarmTheSame = !Object.hasOwn(updatedFiltersDiff, FARM_PROPERTY_TYPE);
  const isCommercialTheSame = !Object.hasOwn(updatedFiltersDiff, COMMERCIAL_PROPERTY_TYPE);
  const isLandTheSame = !Object.hasOwn(updatedFiltersDiff, LAND_PROPERTY_TYPE);
  const isBedroomTheSame = !Object.hasOwn(updatedFiltersDiff, BEDROOMS_PROPERTY);
  const isBathroomTheSame = !Object.hasOwn(updatedFiltersDiff, BATHROOMS_PROPERTY);
  const isWaterfrontTheSame = !Object.hasOwn(updatedFiltersDiff, WATERFRONT_PROPERTY);
  const isGarageTheSame = !Object.hasOwn(updatedFiltersDiff, GARAGE_PROPERTY);
  const isPoolTheSame = !Object.hasOwn(updatedFiltersDiff, POOL_PROPERTY);
  const isOpenHouseTheSame = !Object.hasOwn(updatedFiltersDiff, OPEN_HOUSE_PROPERTY);
  const isListingStatusTheSame = !Object.hasOwn(updatedFiltersDiff, STATUS_PROPERTY);
  const isRentalTheSame = !Object.hasOwn(updatedFiltersDiff, RENTAL_PROPERTY);

  // ----- Check which home type is different - if not check if open house is true
  const activePropertyType = getTargetedUrlPropertyTypeSelection(filters.homeType);

  if (pageNumber > 1) {
    srcUrl.searchParams.set(PAGE_URL_SEARCH_PARAM, `${pageNumber}`);
  }

  if (sort != DEFAULT_LISTING_PARAMS.sort) {
    srcUrl.searchParams.set(SORT_URL_SEARCH_PARAM, sort);
  }

  if (pageNumber > 1 && fallbackSlug?.length > 0) {
    srcUrl.searchParams.set(SLUG_FALLBACK_URL_SEARCH_PARAM, `${fallbackSlug}`);
  }

  const acceptedPathSegments = targetPath.acceptedPathSegments.join('/');
  const baseUrl = `${srcUrl.origin}/${acceptedPathSegments}`;
  const extendedFilteredUrl = getExtendedFilteredUrl(baseUrl, extendedFiltersSearchParams, srcUrl.searchParams);

  // Do not generate SEO-friendly variants for neighbourhood area pages
  if (targetPath.type === AddressType.ADDRESS_TYPE_NEIGHBOURHOOD) {
    return extendedFilteredUrl;
  }

  const isLeasedStatus = !isRentalTheSame && filters.status === NOT_AVAILABLE_SOLD_STATUS;

  // If more than 1 home-type is different -> /filter?
  const propertyTypeComparison = [isHouseTheSame, isTownhouseTheSame, isCondoTheSame, isFarmTheSame, isCommercialTheSame, isLandTheSame].filter(value => !value).length;
  const isSinglePropertyType = [isHouseTheSame, isTownhouseTheSame, isCondoTheSame, isFarmTheSame, isCommercialTheSame, isLandTheSame].filter(value => value).length === 1;
  if (propertyTypeComparison >= 1 && !isSinglePropertyType) {
    return extendedFilteredUrl;
  }

  if (!isBedroomTheSame || !isBathroomTheSame) {
    return extendedFilteredUrl;
  }

  const filtersToCheck = [!isSinglePropertyType, isWaterfrontTheSame, isGarageTheSame, isPoolTheSame, isOpenHouseTheSame, isRentalTheSame, isListingStatusTheSame];
  const filtersComparison = filtersToCheck.filter(value => !value).length;
  if (filtersComparison > 1 && !isLeasedStatus) {
    return extendedFilteredUrl;
  }

  // Special case for leased listings, since these have both the rental and listing status different from the default parameters
  const filtersToCheckWithLeased = [!isSinglePropertyType, isWaterfrontTheSame, isGarageTheSame, isPoolTheSame, isOpenHouseTheSame, !isLeasedStatus];
  const filtersToCheckWithLeasedComparison = filtersToCheckWithLeased.filter(value => !value).length;
  if (filtersToCheckWithLeasedComparison > 1) {
    return extendedFilteredUrl;
  }

  // If not then check if anything else is different
  const keysToIgnore = activePropertyType !== HOMES_TARGETED_URL_PROPERTY_TYPE ? COMMON_FILTER_ATTRIBUTES_TO_IGNORE : HOMES_FILTER_ATTRIBUTES_TO_IGNORE;
  const updatedFiltersDiffKeys = Object.keys(updatedFiltersDiff).sort();
  const difference = updatedFiltersDiffKeys.filter(x => !keysToIgnore.includes(x));

  if (difference.length > 0) {
    return extendedFilteredUrl;
  }

  const seoFriendlyKeywords = generateSeoFriendlyKeywords({
    isSinglePropertyType,
    activePropertyType,
    isLeasedStatus,
    isRentalTheSame,
    isListingStatusTheSame,
    isOpenHouseTheSame,
    isWaterfrontTheSame,
    isGarageTheSame,
    isPoolTheSame,
    filters,
  });

  srcUrl.pathname = `${acceptedPathSegments}${seoFriendlyKeywords}`;
  return srcUrl;
}

export function generateSeoFriendlyKeywords(data: SeoFriendlyKeywordsHelper) {
  let seoFriendlyKeywords = '';
  if (data.isSinglePropertyType) {
    seoFriendlyKeywords += `/${data.activePropertyType.toLowerCase()}`;
  } else if (data.isLeasedStatus) {
    seoFriendlyKeywords += `/${LISTING_STATUS_LEASED}`;
  } else if (!data.isRentalTheSame) {
    seoFriendlyKeywords += `/${LISTING_STATUS_FOR_RENT}`;
  } else if (!data.isListingStatusTheSame) {
    if (data.filters.status === NOT_AVAILABLE_SOLD_STATUS) {
      seoFriendlyKeywords += `/${LISTING_STATUS_SOLD}`;
    }
    else {
      seoFriendlyKeywords += `/${LISTING_STATUS_PAST_LISTINGS}`;
    }
  } else if (!data.isOpenHouseTheSame) {
    seoFriendlyKeywords += `/${FILTER_OPEN_HOUSES}`;
  } else if (!data.isWaterfrontTheSame) {
    seoFriendlyKeywords += `/${FILTER_ON_WATERFRONT}`;
  } else if (!data.isGarageTheSame) {
    seoFriendlyKeywords += `/${FILTER_WITH_GARAGE}`;
  } else if (!data.isPoolTheSame) {
    seoFriendlyKeywords += `/${FILTER_WITH_SWIMMING_POOL}`;
  }
  return seoFriendlyKeywords;
}

export function getExtendedFilteredUrl(url: string, extendedFiltersSearchParams: URLSearchParams, extrasSearchParams?: URLSearchParams) {
  const extendedUrl = new URL(url);
  const filterSize = [...extendedFiltersSearchParams].length;
  const extrasSize = extrasSearchParams ? [...extrasSearchParams].length : 0;
  if (filterSize > 0) {
    if (!extendedUrl.pathname.endsWith('/filter'))
      extendedUrl.pathname = `${extendedUrl.pathname}/filter`;

    for (const entry of extendedFiltersSearchParams.entries()) {
      extendedUrl.searchParams.set(entry[0], entry[1]);
    }
  }
  if (extrasSize > 0 && extrasSearchParams) {
    for (const entry of extrasSearchParams.entries()) {
      extendedUrl.searchParams.set(entry[0], entry[1]);
    }
  }

  return extendedUrl;
}

export function getFlattenFilters(filter: PartialDeep<Filter>): Partial<FlattenFilter> {
  const { additional, homeType, ...rest } = filter;
  const flattenedFilter: Partial<FlattenFilter> = { ...rest, ...homeType, ...additional?.condoOrTownhouse };
  return flattenedFilter;
}

export function filterToURLSearchParams(flattenedFilter: Partial<FlattenFilter>) {
  const urlSearchParams = new URLSearchParams();
  for (const key in flattenedFilter) {
    const originalKey = key as keyof FlattenFilter;
    const formattedKey = dasherize(key).replace('+', '').toLowerCase();
    urlSearchParams.append(formattedKey, flattenedFilter[originalKey ] as string);
  }

  return urlSearchParams;
}
//#endregion

// function grabPathName(windowPathName: string, routeMatchObject: RouteMatchObject) {
//   const { neighbourhood, street } = routeMatchObject;
//   const pathSplit = windowPathName.split('/');
//   let pathName = pathSplit[1];
//   if (street && neighbourhood) {
//     pathName = `${pathSplit[1]}/${pathSplit[2]}/${pathSplit[3]}`;
//   } else if (street) {
//     pathName = `${pathSplit[1]}/${pathSplit[2]}`;
//   } else if (neighbourhood) {
//     pathName = `${pathSplit[1]}/${pathSplit[2]}`;
//   }
//   return pathName;
// }