import { useState } from 'react';
import { useThemeContext } from 'contexts/theme';
import ZoocasaFooter from './zoocasaFooter';
import ExpRealtyFooter from './expRealtyFooter';
import CommercialFooter from './commercialFooter';
import { ThemeNames } from 'types/themes';
import LoadWhenVisible from 'components/load-when-visible';
import LoadingSkeleton from 'components/loading-skeleton';
import { useUserContext } from 'contexts';
import ExpThemedFooter from './expThemedFooter';
import expInServiceTenant from 'tenants/expInServiceTenant';

export default function ThemedFooter() {
  const { isCrawler } = useUserContext();
  const { themeName } = useThemeContext();
  const [isVisible, setIsVisible] = useState(false);

  const renderExpUSFooter = () => {
    if (isCrawler) {
      return <ExpRealtyFooter />;
    }
    return <LoadWhenVisible onValueChange={() => setIsVisible(true)}>
      {!isVisible ? <LoadingSkeleton height="15.7em" />: <ExpRealtyFooter />}
    </LoadWhenVisible>;
  };

  const renderExpCommercialFooter = () => { 
    if (isCrawler) {
      return <CommercialFooter />;
    }
    return <LoadWhenVisible onValueChange={() => setIsVisible(true)}>
      {!isVisible ? <LoadingSkeleton height="15.7em" />: <CommercialFooter />}
    </LoadWhenVisible>;
  };

  const renderExpInServiceFooter = () => { 
    if (isCrawler) {
      return <ExpThemedFooter config={expInServiceTenant.config.footer}/>;
    }
    return <LoadWhenVisible onValueChange={() => setIsVisible(true)}>
      {!isVisible ? <LoadingSkeleton height="15.7em" />: <ExpThemedFooter config={expInServiceTenant.config.footer}/>}
    </LoadWhenVisible>;
  };

  const renderDefaultFooter = () => {
    if (isCrawler) {
      return <ZoocasaFooter />;
    }
    return <LoadWhenVisible onValueChange={() => setIsVisible(true)}>
      {!isVisible ? <LoadingSkeleton height="15.7em" />: <ZoocasaFooter />}
    </LoadWhenVisible>;
  };

  switch (themeName) {
  case ThemeNames.EXP_REALTY_CA:
  case ThemeNames.EXP_REALTY_US:
    return renderExpUSFooter();
  case ThemeNames.EXP_IN_SERVICE:
    return renderExpInServiceFooter();
  case ThemeNames.EXP_COMMERCIAL:
    return renderExpCommercialFooter();
  default:
    return renderDefaultFooter();
  }
}
