import { generateSeoFriendlyKeywords } from 'components/dynamic-page/new-area-listings-page/targeted-url-helper';
import { getTargetedUrlPropertyTypeSelection, HOMES_TARGETED_URL_PROPERTY_TYPE } from 'utils/listing-query-helper';
import { LISTING_STATUS_SOLD, LISTING_STATUS_PAST_LISTINGS, LISTING_STATUS_LEASED, LISTING_STATUS_EXPIRED_LISTINGS, FILTER_WITH_GARAGE, FILTER_ON_WATERFRONT, FILTER_WITH_SWIMMING_POOL, HOME_TYPE_HOUSES, HOME_TYPE_TOWNHOUSES, HOME_TYPE_CONDOS, HOME_TYPE_LAND, HOME_TYPE_COMMERCIAL, HOME_TYPE_FARMS } from 'data/addresses/go-search/addressPathParser';

import type { ListingParams } from 'contexts/preferences/listing-params/types';
/**
 * Helper function to determine if a path is an SEO-friendly path.
 * @param pathPart - The path part to check
 * @returns True if the path is an SEO-friendly path, false otherwise
 */
function checkIfSeoPath(pathPart: string) {
  const seoFriendlyKeywordsList = [LISTING_STATUS_SOLD, LISTING_STATUS_PAST_LISTINGS, LISTING_STATUS_LEASED, LISTING_STATUS_EXPIRED_LISTINGS, FILTER_WITH_GARAGE, FILTER_ON_WATERFRONT, FILTER_WITH_SWIMMING_POOL, HOME_TYPE_HOUSES, HOME_TYPE_TOWNHOUSES, HOME_TYPE_CONDOS, HOME_TYPE_LAND, HOME_TYPE_COMMERCIAL, HOME_TYPE_FARMS];
  return seoFriendlyKeywordsList.includes(pathPart);
}

/**
 * Generates a canonical URL by removing all query parameters.
 * @param asPath - The full path from Next.js router (including query parameters).
 * @param baseHost - The base host of the application (e.g., https://www.zoocasa.com).
 * @returns The canonical URL without query parameters.
 */
export function generateCanonicalUrl(asPath = '', baseHost = '', filters?: ListingParams['filter']): string {
  // Ensure the baseHost ends with a slash for consistency
  const formattedHost = baseHost.endsWith('/') ? baseHost.slice(0, -1) : baseHost;
  
  // Extract the base path (ignore anything after `?`)
  let [basePath] = asPath.replace('/filter', '').split('?');
  // For neighbourhood pages, we just want to return the base path. For paths with more than 1 part, they are of the form
  // /[city]-real-estate/[neighbourhood] or /[city]-real-estate/[SEO friendly keyword]. If it's the former, return the base path
  const pathParts = basePath.split('/').slice(1);
  const pathPart = pathParts[1] || '';
  const isNeighbourhoodPage = !checkIfSeoPath(basePath) && pathPart.length > 0;

  if (isNeighbourhoodPage) {
    return `${formattedHost}${basePath}`;
  }

  let seoFriendlyKeywords = '';
  if (filters) {
    // Check if more than one targeted url filter is on
    const activePropertyType = getTargetedUrlPropertyTypeSelection(filters.homeType);
    const isSpecificHomeType = activePropertyType !== HOMES_TARGETED_URL_PROPERTY_TYPE;
    const isForRent = filters.rental;
    const isLeasedStatus = isForRent && filters.status === 'not-available-sold';
    const isSpecificStatus = filters.status !== 'available';
    const isGarageOn = filters.garage;
    const isOnWaterfrontOn = filters.waterfront;
    const isWithSwimmingPoolOn = filters.pool;
    const targetedVariantsOnAmount = [isSpecificHomeType, isForRent, isSpecificStatus, isGarageOn, isOnWaterfrontOn, isWithSwimmingPoolOn].filter(Boolean).length;
    const isMultipleTargetedUrlFilters = targetedVariantsOnAmount > 1;
    const isLeased = targetedVariantsOnAmount == 2 && isLeasedStatus;

    // Remove any targeted urls as this will be appended below
    basePath = basePath.replace(/(-real-estate).*/, '$1');

    if (!isMultipleTargetedUrlFilters || isLeased) {
      seoFriendlyKeywords = generateSeoFriendlyKeywords({
        isSinglePropertyType: isSpecificHomeType,
        activePropertyType,
        isLeasedStatus,
        isRentalTheSame: !isForRent,
        isListingStatusTheSame: !isSpecificStatus,
        isOpenHouseTheSame: !filters.openHouse,
        isWaterfrontTheSame: !isOnWaterfrontOn,
        isGarageTheSame: !isGarageOn,
        isPoolTheSame: !isWithSwimmingPoolOn,
        filters: filters,
      });
    }
  }
  
  // Construct and return the canonical URL
  return `${formattedHost}${basePath}${seoFriendlyKeywords}`;
}
  