import { IMPERSONATE_CRAWLER_FEATURE, USER_AGENT_HEADER_NAME, X_ZOOCASA_FEATURES_HEADER_NAME } from 'constants/headers';
import type { IncomingHttpHeaders } from 'http';
import { CloudflareIncomingMessage,
  ZoocasaIncomingMessage,
  X_KNOWN_BOT_HEADER_NAME,
} from 'types/http';

// Source: https://stackoverflow.com/a/60911528
/* cSpell:disable */
const CRAWLER_AGENTS = [
  'googlebot', 'yandexbot', 'yahoo', 'bingbot',
  'baiduspider', 'facebookexternalhit', 'twitterbot', 'rogerbot',
  'linkedinbot', 'embedly', 'quora link preview', 'showyoubot', 'outbrain',
  'pinterest/0.', 'developers.google.com/+/web/snippet',
  'slackbot', 'vkshare', 'w3c_validator', 'redditbot', 'applebot',
  'whatsapp', 'flipboard', 'tumblr', 'bitlybot', 'skypeuripreview',
  'nuzzel', 'discordbot', 'google page speed', 'headlesschrome',
  'googleother', 'ahrefsbot', 'blexbot', 'semrushbot', 'meta-externalagent',
  'barkrowler', 'bytespider', 'amazonbot', 'appsignalbot', 'imagesiftbot',
];


type IncomingMessageHeaders = ZoocasaIncomingMessage['headers'] & CloudflareIncomingMessage['headers'];

/**
 * Determines if the request is from a bot or crawler.
 *
 * This function uses a combination of methods to identify if the incoming request is from a known bot or crawler:
 * 
 * 1. **Known Bot Header**: Checks for a custom header `X_KNOWN_BOT_HEADER_NAME` which is injected by one of our header rewrite rules in Cloudflare.
 * 2. **User Agent Matching**: Compares the `User-Agent` header against a list of known crawler user agents.
 * 3. **Impersonation Feature**: Checks for the presence of a custom feature flag `IMPERSONATE_CRAWLER_FEATURE` in the `X_ZOOCASA_FEATURES_HEADER_NAME` header.
 * 
 * @param headers The incoming HTTP headers.
 * @returns  True if the request is identified as a bot or crawler, otherwise false.
 */
export default function isUserAgentCrawler(headers: IncomingMessageHeaders): boolean {
  if (!headers) return false;
  const isKnownBot = Boolean(headers?.[X_KNOWN_BOT_HEADER_NAME]);
  const userAgent = (headers?.[USER_AGENT_HEADER_NAME] || '').toLowerCase();
  const xZoocasaFeatures = headers[X_ZOOCASA_FEATURES_HEADER_NAME] || '';
  const isCrawler = CRAWLER_AGENTS.some(crawlerAgent => userAgent.indexOf(crawlerAgent) !== -1);
  const impersonatingCrawler = xZoocasaFeatures.includes(IMPERSONATE_CRAWLER_FEATURE);
  return isKnownBot || isCrawler || impersonatingCrawler;
}

export function isUserAgentLighthouse(initialUserAgent: IncomingHttpHeaders['user-agent']) {
  const userAgent = (initialUserAgent || '').toLowerCase();
  const isLighthouse = /moto g|chrome-lighthouse/.test(userAgent);
  return isLighthouse;
}