//#region Standard HTTP Headers
export const USER_AGENT_HEADER_NAME = 'user-agent';
export const X_FORWARDED_FOR_HEADER_NAME = 'x-forwarded-for';
export const SET_COOKIE_HEADER_NAME = 'Set-Cookie';
export const CACHE_CONTROL_HEADER_NAME = 'Cache-Control';

/**
 * This value is considered fresh for 30 minutes (s-maxage=1800).
 * If a request is repeated within the next 30 minutes, the previously cached
 * value will still be fresh. If the request is repeated before 60 seconds,
 * the cached value will be stale but still render (stale-while-revalidate=60).
 */
export const CACHE_CONTROL_HEADER_VALUE =
  'public, s-maxage=1800, stale-while-revalidate=60';
//#endregion

//#region Zoocasa Headers (used for internal requests)
export const X_ZOOCASA_WEBVIEW_HEADER_NAME = 'x-zoocasa-webview';
export const ZOOCASA_API_KEY_HEADER_NAME = 'x-zoocasa-api-key';
export const ZOOCASA_TENANT_HEADER_NAME = 'x-zoocasa-tenant';
/**
 * The name of the header used to turn features on or off using request headers.
 * This header is sent with the name of the features followed by a boolean value and separated by a comma `,`
 */
export const X_ZOOCASA_FEATURES_HEADER_NAME = 'x-zoocasa-features';
export const CONSENT_API_KEY_HEADER_NAME = 'x-api-key';
export const X_ZOOCASA_REQUEST_SOURCE_HEADER_NAME = 'X-Zoocasa-Request-Source';
export const X_ZOOCASA_GENERATION_HEADER_NAME = 'X-Zoocasa-Generation';
export const X_ZOOCASA_MLML_HEADER_NAME = 'x-zoocasa-mlml';

export const IMPERSONATE_CRAWLER_FEATURE = 'impersonateCrawler=true';
//#endregion
