import Cookies from 'js-cookie';
import defaultListingParams, { DEFAULT_LISTING_PARAMS_US } from 'contexts/preferences/listing-params/defaults';
import { getJSON, getObjectFromRequestCookie } from 'utils/cookies';
import { LISTING_PARAMS_COOKIE_NAME } from 'constants/cookies';
import { getSiteLocationFromRequest } from 'utils/site-location';

import type { IncomingMessage } from 'http';
import { DeprecatedPropertyTypeFilter, HOUSE_ATTACHED_PROPERTY_TYPE, HOUSE_DETACHED_PROPERTY_TYPE, HOUSE_SEMIDETACHED_PROPERTY_TYPE, PropertyTypeFilter, type ListingParams } from 'contexts/preferences/listing-params/types';

export const storageKey = LISTING_PARAMS_COOKIE_NAME;

export function extractListingParamsData(req?: IncomingMessage): ListingParams {
  const siteLocation = getSiteLocationFromRequest(req);
  const listingParams = siteLocation === 'US' ? DEFAULT_LISTING_PARAMS_US : defaultListingParams;
  return getObjectFromRequestCookie<ListingParams>(storageKey, req) || getJSON(Cookies.get(storageKey)) || listingParams;
}

/**
 * Ensure backwards compatibility with deprecated property types. If homeType includes attached, detached, or semidetached properties it will be a migrated to house.
 *
 * NOTE: deprecated properties are removed from the returned PropertyTypeFilter object. If house is not set or migrated, it will be set to true by default.
 *
 * @param propertyType The home type filter to update.
 * @returns The updated property type filter.
 */
function ensureBackwardCompatibilityForHomeType(propertyType: PropertyTypeFilter): PropertyTypeFilter {
  const deprecatedProperties: (keyof DeprecatedPropertyTypeFilter)[] = [HOUSE_DETACHED_PROPERTY_TYPE, HOUSE_SEMIDETACHED_PROPERTY_TYPE, HOUSE_ATTACHED_PROPERTY_TYPE];
  const hasDeprecatedProperty = deprecatedProperties.some(prop =>
    Object.prototype.hasOwnProperty.call(propertyType, prop)
  );

  if (hasDeprecatedProperty) {
    const isAnyDeprecatedPropertyTrue = deprecatedProperties.some(prop =>
      propertyType[prop as keyof PropertyTypeFilter]
    );

    deprecatedProperties.forEach(prop => {
      delete propertyType[prop as keyof PropertyTypeFilter];
    });
    propertyType.house = isAnyDeprecatedPropertyTrue;
  } else if (!Object.prototype.hasOwnProperty.call(propertyType, 'house')) {
    propertyType.house = true;
  }

  return propertyType;
}