import Cookies from 'js-cookie';
import { getJSON } from './cookies/request-cookies';
import jqueryParam from 'jquery-param';
import {
  underscoreKeys,
  dasherizeKeys,
  camelizeKeys,
} from '@zoocasa/node-kit/objects/transform-keys';
import { fetchWithRetry } from './fetchWithRetry';
import {
  FetchOptions,
  RetryOnStatusOptions,
} from 'types/fetchWithRetry';
import { HttpStatusCode } from 'types/HttpStatusCode';
import {
  X_ZOOCASA_GENERATION_HEADER_NAME,
  X_ZOOCASA_MLML_HEADER_NAME,
  X_ZOOCASA_REQUEST_SOURCE_HEADER_NAME,
} from 'constants/headers';
import { MLML_LEAD_COOKIE_NAME } from 'constants/cookies';
import { HttpRequestMethodTypes } from 'types/HttpRequestMethodTypes';
import {
  getClientAppHost,
  isServerSide,
} from './host-config';
import { isDevelopment, isEndToEndTest } from './environment';

import type { User } from 'contexts/user';

//#region types
type ErrorObject = {
  source?: {
    pointer?: string;
  };
  title?: string;
};

export type ErrorResponse = {
  errors: ErrorObject[];
  meta?: {
    totalPages?: number;
    totalCount?: number;
    pageNumber?: number;
    pageSize?: number;
    endpoint?: string;
  };
}

export type ApiHeaders = {
  'Content-Type': 'application/json';
  [X_ZOOCASA_REQUEST_SOURCE_HEADER_NAME]: 'zoocasa.com';
  [X_ZOOCASA_GENERATION_HEADER_NAME]: 'next';
  'Authorization'?: string;
  [X_ZOOCASA_MLML_HEADER_NAME]?: string;
};

//#endregion


const RETRY_ON_STATUS: RetryOnStatusOptions = { statusCodes: [
  HttpStatusCode.TOO_MANY_REQUESTS,
  HttpStatusCode.BAD_GATEWAY,
  HttpStatusCode.GATEWAY_TIMEOUT,
  HttpStatusCode.SERVICE_UNAVAILABLE,
]} as const;

//#region public functions

/**
 * This is a wrapper around the fetchWithRetry function that adds some
 * additional functionality to handle client-app api endpoints.
 *
 * It expects the responses to follow the JSON API spec and in case
 * of error, it will reject the promise with an {@link ErrorResponse} object.
 *
 * @param apiEndpoint The endpoint url
 * @param method The method to use. defaults to
 *  {@link HttpRequestMethodTypes.GET}
 * @param request The request to send to the endpoint. When the method
 *  is {@link HttpRequestMethodTypes.GET}, the request is added to the url
 *  as query parameters. When the method is {@link HttpRequestMethodTypes.POST},
 *  {@link HttpRequestMethodTypes.PUT}, {@link HttpRequestMethodTypes.PATCH} or
 *  {@link HttpRequestMethodTypes.DELETE}, the request is added as the body.
 * @param headers The headers to send. Defaults to the result
 *  of {@link apiHeaders}
 * @param signal a signal to abort the request.
 * @param host The host to use. Defaults to the result of {@link getHost}
 * @returns The response from the endpoint. If the response is an
 * {@link ErrorResponse} object, it will reject the promise with the
 * {@link ErrorResponse} object as cause.
 */
export default async function endpoint<T>(
  apiEndpoint: string,
  method: keyof typeof HttpRequestMethodTypes = HttpRequestMethodTypes.GET,
  request?: Record<string, unknown>,
  headers: FetchOptions['headers'] = apiHeaders(),
  signal?: AbortSignal,
  host: string = getHost()
): Promise<T> {

  let url = host + apiEndpoint;

  const options: FetchOptions = {
    method,
    headers,
    signal,
  };

  if (request) {
    const payload = transformDataKeys(url, request);
    if (method === HttpRequestMethodTypes.GET) {
      url = addDataToUrl(url, payload);
    } else {
      options.body = JSON.stringify(payload);
    }
  }

  try {
    const response = await fetchWithRetry(url, options, RETRY_ON_STATUS);

    if (response.status === HttpStatusCode.NO_CONTENT) {
      console.debug(`endpoint=${url},status=204,message=no content`);
      return Promise.resolve<T>({} as T); // Nothing to return
    }

    const data = await response.json();

    // Check if is an ErrorResponse object
    if (isErrorResponse(data)) {
      console.error(`endpoint=${url},error=${JSON.stringify(data)}`); // FIXME: this is temporary to identify issues with client-app api endpoints
      return Promise.reject(data); // Reject directly if it is
    }

    let result: any;
    if (!(data instanceof Array)) {
      result = camelizeKeys(data as Record<string, unknown>);
    }

    return Promise.resolve(result as T);
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === 'AbortError') {
        console.debug(`endpoint=${url},error=request was aborted`);
        return Promise.resolve<T>({} as T); // Nothing to return
      } else {
        const errorMessage = error.message ?? 'request error';
        console.error(`endpoint=${url},error=${errorMessage}`);
        return Promise.reject<T>(createErrorResponse(errorMessage, url));
      }
    }
    throw error;
  }
}

/**
 * Creates and returns the standard headers used for client-app API requests.
 *
 * This function sets up the following headers:
 * - Content-Type: application/json
 * - {@link X_ZOOCASA_REQUEST_SOURCE_HEADER_NAME X-Zoocasa-Request-Source}: zoocasa.com
 * - {@link X_ZOOCASA_GENERATION_HEADER_NAME X-Zoocasa-Generation}: next
 * - Authorization: Bearer {jwt} (if user is authenticated)
 * - {@link X_ZOOCASA_MLML_HEADER_NAME X-Zoocasa-MLML}: {cookieValue} (if MLML lead cookie exists)
 *
 * @returns An object containing all the necessary headers for client-app API requests
 */
export function apiHeaders(): ApiHeaders {
  const headers: ApiHeaders = {
    'Content-Type': 'application/json',
    [X_ZOOCASA_REQUEST_SOURCE_HEADER_NAME]: 'zoocasa.com',
    [X_ZOOCASA_GENERATION_HEADER_NAME]: 'next',
  };
  const user = getJSON(Cookies.get('user')) as User | null;
  if (user && user.jwt) {
    headers['Authorization'] = `Bearer ${user.jwt}`;
  }
  const cookieValue = Cookies.get(MLML_LEAD_COOKIE_NAME);
  if (cookieValue) {
    headers[X_ZOOCASA_MLML_HEADER_NAME] = cookieValue;
  }
  return headers;
}

// This is a type guard to check if the response is an error response
export function isErrorResponse(response: unknown): response is ErrorResponse {
  return (
    typeof response === 'object' &&
    response !== null &&
    'errors' in response &&
    Array.isArray((response as ErrorResponse).errors)
  );
}
//#endregion

//#region private functions

/**
 * Transforms the keys of a data object based on the client-app API version in the URL.
 *
 * The transformation rules are:
 * - For API v2: keys are transformed to snake_case (e.g., 'firstName' -> 'first_name')
 * - For API v1 and v3: keys are transformed to kebab-case (e.g., 'firstName' -> 'first-name')
 *
 * @param url The API endpoint URL that contains the version information
 * @param data The data object whose keys need to be transformed
 * @returns A new object with transformed keys based on the API version. If the client-app API
 *  version is not specified in the URL, the data is returned unchanged.
 * @example
 * // For v2 API
 * transformDataKeys('/services/api/v2/endpoint', { firstName: 'Potato' })
 * // Returns: { first_name: 'Potato' }
 *
 * // For v1/v3 API
 * transformDataKeys('/services/api/v1/endpoint', { firstName: 'Potato' })
 * // Returns: { 'first-name': 'Potato' }
 */
function transformDataKeys(url: string, data: Record<string, unknown>) {
  data = Object.assign({}, data);
  if (url.match(/api\/v2\//)) {
    data = underscoreKeys(data);
  } else if (url.match(/api\/v[1,3]\//)) {
    data = dasherizeKeys(data);
  }
  return data;
}

/**
 * Adds query parameters to a URL for GET requests.
 *
 * This function:
 * 1. Creates a copy of the data object to avoid mutations
 * 2. Filters out falsy values (null, undefined, empty strings, etc.)
 * 3. Converts the remaining data into URL query parameters using jquery-param
 *
 * @param url The base URL to append parameters to
 * @param data The data object to convert into query parameters
 * @returns The URL with query parameters appended
 * @example
 * addDataToUrl('https://www.zoocasa.com/services/api/v3/endpoint', {
 *   name: 'Potato',
 *   age: 40,
 *   empty: ''
 * })
 * // Returns: 'https://www.zoocasa.com/services/api/v3/endpoint?name=Potato&age=40'
 */
function addDataToUrl(url: string, data: Record<string, unknown>) {
  data = Object.assign({}, data);
  const values = Object.keys(data).map(key => data[key]).filter(i => i);
  if (values.length) {
    url += '?' + jqueryParam(data);
  }
  return url;
}

/**
 * Creates a standardized error response object following the JSON API specification.
 *
 * @param message The error message to display
 * @param url Optional URL of the endpoint that caused the error
 * @returns An ErrorResponse object with the error message and metadata
 * @example
 * createErrorResponse('Network error', 'https://www.zoocasa.com/services/api/v3/endpoint')
 * // Returns:
 * // {
 * //   errors: [{ title: 'Network error' }],
 * //   meta: {
 * //     totalPages: 0,
 * //     totalCount: 0,
 * //     pageNumber: 0,
 * //     pageSize: 0,
 * //     endpoint: 'https://www.zoocasa.com/services/api/v3/endpoint'
 * //   }
 * // }
 */
function createErrorResponse(message: string, url?: string): ErrorResponse {
  return {
    errors: [{
      title: message,
    }],
    meta: {
      totalPages: 0,
      totalCount: 0,
      pageNumber: 0,
      pageSize: 0,
      endpoint: url,
    },
  };
}

/**
 * Returns the host to use for the client-app API requests.
 *
 * NOTE: When running in development mode or during end-to-end tests,
 * it returns the local host as it will be proxied by the dev server
 * using `http-proxy-middleware`.
 *
 * @returns The host to use for the client-app API requests.
 */
function getHost() {
  if (isDevelopment || isEndToEndTest) {
    return 'http://localhost:4200';
  }
  return getClientAppHost(isServerSide());
}
//#endregion