import ExprealtyCaTheme from './exprealtyCA';
import ExprealtyUsTheme from './exprealtyUS';
import ZoocasaTheme from './zoocasa';
import ExpCommercialTenant from './expCommercial';
import ExpInServiceTheme from './expInService';
import {
  ThemeNames,
  Themes,
} from 'types/themes';
import { ThemeConfig } from './themeConfig';

export type { ThemeConfig } from './themeConfig';

export const themes: Record<Themes, ThemeConfig> = {
  [ThemeNames.ZOOCASA]: ZoocasaTheme,
  [ThemeNames.EXP_REALTY_CA]: ExprealtyCaTheme,
  [ThemeNames.EXP_REALTY_US]: ExprealtyUsTheme,
  [ThemeNames.EXP_IN_SERVICE]: ExpInServiceTheme,
  [ThemeNames.EXP_COMMERCIAL]: ExpCommercialTenant,
};

export type ThemeName = keyof typeof themes;
