import { 
  AddressAttributes, 
  NEIGHBOURHOOD_ADDRESS_TYPE,
  PLACE_NAME_ADDRESS_TYPE,
  PROVINCE_ADDRESS_TYPE,
  SUB_DIVISION_ADDRESS_TYPE,
  ADDRESS_ADDRESS_TYPE,
} from './types';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import { provinceOrStateNameFromCode } from 'utils/province_or_state';

export function generateBreadcrumbLink(address: AddressAttributes, addressPath?: string): string {
  let path = '';
  const addressType = address.addressType;
  const { provinceCode, subDivision, neighbourhood } = address;
  const pathSuffix = 'real-estate';
  
  if (addressType === PROVINCE_ADDRESS_TYPE) {
    path = `/${cleanDashedString(provinceOrStateNameFromCode(provinceCode))}-${pathSuffix}`;
  } else if ((addressType === SUB_DIVISION_ADDRESS_TYPE || addressType === PLACE_NAME_ADDRESS_TYPE) && subDivision) {
    path = `/${cleanDashedString(subDivision.toLowerCase())}-${provinceCode}-${pathSuffix}`;
  } else if (addressType === NEIGHBOURHOOD_ADDRESS_TYPE && neighbourhood && subDivision) {
    path = `/${cleanDashedString(subDivision.toLowerCase())}-${provinceCode}-${pathSuffix}/${cleanDashedString((neighbourhood.toLowerCase()))}`;
  } else if (addressType === ADDRESS_ADDRESS_TYPE && addressPath) {
    path = addressPath;
  }
  return path.toLowerCase();
}
  
export function cleanDashedString(value: string) {
  return dasherize(value.replace(/[^a-zA-Z0-9-/ ]/g, ''));
}

export function toJson(data: any) {
  return JSON.parse(JSON.stringify(data));
}