import { X_ZOOCASA_FEATURES_HEADER_NAME, X_FORWARDED_FOR_HEADER_NAME } from 'constants/headers';
import { IncomingHttpHeaders, IncomingMessage } from 'http';

//#region Zoocasa Incoming Message
export type ZoocasaIncomingMessage = IncomingMessage & {
  headers: {
    /**
     * The X-Zoocasa-Features header contains a list of feature overrides that are enabled/disabled for the request.
     * 
     * Example value: `impersonateCrawler=true`
     */
    [X_ZOOCASA_FEATURES_HEADER_NAME]?: string | undefined;
  } & IncomingHttpHeaders;
};
//#endregion

//#region Cloudflare Incoming Message
export const CF_IPCOUNTRY_HEADER_NAME = 'cf-ipcountry';
export const CF_IPCITY_HEADER_NAME = 'cf-ipcity';
export const CF_REGION_HEADER_NAME = 'cf-region';
export const CF_REGION_CODE_HEADER_NAME = 'cf-region-code';
export const CF_IPLATITUDE_HEADER_NAME = 'cf-iplatitude';
export const CF_IPLONGITUDE_HEADER_NAME = 'cf-iplongitude';
export const CF_CONNECTING_IP_HEADER_NAME = 'cf-connecting-ip';
export const CF_RAY_HEADER_NAME = 'cf-ray';
export const X_KNOWN_BOT_HEADER_NAME = 'x-known-bot';
export const X_THREAT_SCORE_HEADER_NAME = 'x-threat-score';

/** 
 * The CloudflareIncomingMessage type extends the {@link IncomingMessage} type to include the
 * Cloudflare headers and the {@link IncomingHttpHeaders} type.
 * 
 * @see https://developers.cloudflare.com/fundamentals/get-started/reference/http-request-headers/
 */
export type CloudflareIncomingMessage = IncomingMessage & {
    headers: {
      /**
       * The CF-IPCountry header contains a two-character country code of the originating visitor’s country.
       * 
       * Example value: US 
       */
      [CF_IPCOUNTRY_HEADER_NAME]?: string | undefined;
      /**
       * The CF-IPCity header contains the city name of the originating visitor’s location.
       * 
       * Example value: Toronto 
       */
      [CF_IPCITY_HEADER_NAME]?: string | undefined;
      /**
       * The CF-Region header contains the region name of the originating visitor’s location.
       * 
       * Example value: Texas 
       */
      [CF_REGION_HEADER_NAME]?: string | undefined;
      /**
       * The CF-Region-Code header contains the region code of the originating visitor’s location.
       * 
       * Example value: TX 
       */
      [CF_REGION_CODE_HEADER_NAME]?: string | undefined;
      /**
       * The CF-IPLatitude header contains the latitude of the originating visitor’s location. 
       * 
       * Example value: 40.7128 
       */
      [CF_IPLATITUDE_HEADER_NAME]?: string | undefined;
      /** 
       * The CF-IPLongitude header contains the longitude of the originating visitor’s
       * location. 
       * 
       * Example value: -74.0060 
       */
      [CF_IPLONGITUDE_HEADER_NAME]?: string | undefined;
      /**
       * The CF-Connecting-IP header contains the IP address of the originating visitor’s\
       * location. 
       * 
       * Example value: 192.168.1.1
       */
      [CF_CONNECTING_IP_HEADER_NAME]?: string | undefined;
      /**
       * The CF-ray header (otherwise known as a Ray ID) is a hashed value that encodes 
       * information about the data center and the visitor’s request. 
       * 
       * Example value: 230b030023ae2822-SJC 
       */
      [CF_RAY_HEADER_NAME]?: string | undefined;
      /**
       * The X-Known-Bot header contains a value that indicates whether the request is from a known bot.
       * 
       * When `true`, this field indicates the request originated from a known good bot or crawler. 
       * 
       * Example value: true
       */
      [X_KNOWN_BOT_HEADER_NAME]?: string | undefined;
      /**
       * The X-Threat-Score header contains a value that indicates a Cloudflare threat score from 0–100, where 0 indicates low risk.
       * 
       * Values above 10 may represent spammers or bots, and values above 40 identify bad actors on the Internet. It is rare to
       * see values above 60. A common recommendation is to challenge requests with a score above 10 and to block those above 50.
       * 
       * Example value: 0
       */
      [X_THREAT_SCORE_HEADER_NAME]?: string | undefined;
    } & IncomingHttpHeaders;
  };
//#endregion

//#region Vercel Incoming Message

export const X_VERCEL_HOST_HEADER_NAME = 'host';
export const X_VERCEL_ID_HEADER_NAME = 'x-vercel-id';
export const X_VERCEL_FORWARDED_FOR_HEADER_NAME = 'x-vercel-forwarded-for';
export const X_REAL_IP_HEADER_NAME = 'x-real-ip';
export const X_VERCEL_DEPLOYMENT_URL_HEADER_NAME = 'x-vercel-deployment-url';
export const X_VERCEL_IP_CONTINENT_HEADER_NAME = 'x-vercel-ip-continent';
export const X_VERCEL_IP_COUNTRY_HEADER_NAME = 'x-vercel-ip-country';
export const X_VERCEL_IP_COUNTRY_REGION_HEADER_NAME = 'x-vercel-ip-country-region';
export const X_VERCEL_IP_CITY_HEADER_NAME = 'x-vercel-ip-city';
export const X_VERCEL_IP_LATITUDE_HEADER_NAME = 'x-vercel-ip-latitude';
export const X_VERCEL_IP_LONGITUDE_HEADER_NAME = 'x-vercel-ip-longitude';
export const X_VERCEL_IP_TIMEZONE_HEADER_NAME = 'x-vercel-ip-timezone';
export const X_VERCEL_IP_POSTAL_CODE_HEADER_NAME = 'x-vercel-ip-postal-code';
export const X_VERCEL_SIGNATURE_HEADER_NAME = 'x-vercel-signature';

/**
 * The VercelIncomingMessage type extends the {@link IncomingMessage} type to include the Vercel
 * headers and the {@link IncomingHttpHeaders} type.
 * 
 * @see https://vercel.com/docs/edge-network/headers/request-headers#request-headers
 */
export type VercelIncomingMessage = IncomingMessage & {
  headers: {
    /**
     * This header represents the domain name as it was accessed by the client. If the deployment
     * has been assigned to a preview URL or production domain and the client visits the domain URL,
     * it contains the custom domain instead of the underlying deployment URL.
     * 
     * Example value: `zoocasa-next-git-<branch-name>-zoocasa.vercel.app
     */
    [X_VERCEL_HOST_HEADER_NAME]?: string | undefined;

    /**
     * This header contains a list of Edge regions your request hit, as well as the region the 
     * function was executed in (for both Edge and Serverless).
     */
    [X_VERCEL_ID_HEADER_NAME]?: string | undefined;

    /**
     * This header is identical to the `x-forwarded-for` header. However, `x-forwarded-for` could be
     * overwritten if you're using a proxy on top of Vercel.
     * 
     * Example value: 123.123.123.123
     */
    [X_VERCEL_FORWARDED_FOR_HEADER_NAME]?: string | undefined;

    /**
     * This header is identical to the `x-forwarded-for` header.
     * 
     * Example value: 123.123.123.123
     */
    [X_REAL_IP_HEADER_NAME]?: string | undefined;

    /**
     * This header represents the unique deployment, not the preview URL or production domain. For example,
     * `*.vercel.app` is the deployment URL.
     * 
     * Example value: `https://zoocasa-next-<build-id>-zoocasa.vercel.app/`
     */
    [X_VERCEL_DEPLOYMENT_URL_HEADER_NAME]?: string | undefined;

    /**
     * A two-character ISO 3166-1 code representing the continent associated with the location of the
     * requester's public IP address. Codes used to identify continents are as follows:
     * 
     * - AF for Africa
     * - AN for Antarctica
     * - AS for Asia
     * - EU for Europe
     * - NA for North America
     * - OC for Oceania
     * - SA for South America
     * 
     * Example value: `AS`
     */
    [X_VERCEL_IP_CONTINENT_HEADER_NAME]?: string | undefined;

    /**
     * A two-character ISO 3166-1 country code for the country associated with the location of
     * the requester's public IP address.
     * 
     * Example value: `US`
     */
    [X_VERCEL_IP_COUNTRY_HEADER_NAME]?: string | undefined;

    /**
     * A string of up to three characters containing the region-portion of the ISO 3166-2 code
     * for the first level region associated with the requester's public IP address. Some countries
     * have two levels of subdivisions, in which case this is the least specific one. For example,
     * in the United Kingdom this will be a country like "England", not a county like "Devon".
     */
    [X_VERCEL_IP_COUNTRY_REGION_HEADER_NAME]?: string | undefined;

    /**
     * The city name for the location of the requester's public IP address. Non-ASCII 
     * characters are encoded according to RFC3986.
     */
    [X_VERCEL_IP_CITY_HEADER_NAME]?: string | undefined;

    /**
     * The latitude for the location of the requester's public IP address.
     * 
     * For example, 37.7749.
     */
    [X_VERCEL_IP_LATITUDE_HEADER_NAME]?: string | undefined;

    /**
     * The longitude for the location of the requester's public IP address.
     * 
     * For example, -122.4194.
     */
    [X_VERCEL_IP_LONGITUDE_HEADER_NAME]?: string | undefined;

    /**
     * The name of the time zone for the location of the requester's public IP
     * address in ICANN Time Zone Database name format such as America/Chicago.
     */
    [X_VERCEL_IP_TIMEZONE_HEADER_NAME]?: string | undefined;

    /** The postal code close to the user's location. */
    [X_VERCEL_IP_POSTAL_CODE_HEADER_NAME]?: string | undefined;

    /**
     * The signature of the request. This header is used to verify that the request
     * was sent by Vercel, and contains a hash signature you can use to validate
     * the request body.
     */
    [X_VERCEL_SIGNATURE_HEADER_NAME]?: string | undefined;

  } & IncomingHttpHeaders;
};
//#endregion

//#region Incoming Message
export type IncomingMessageType = ZoocasaIncomingMessage & CloudflareIncomingMessage & VercelIncomingMessage;
//#endregion