import defaultListingParams from './defaults';
import { cloneDeep } from 'lodash';
import deepmerge from 'deepmerge';
import {
  DEFAULT_HAS_IMAGE_VALUE,
} from './types';
import {
  ensureBackwardCompatibilityForHomeType,
} from 'data/saved-searches/utils';
import { diff } from 'deep-object-diff';

import type {
  CondoOrTownhouseAdditionalFilter,
  Filter,
  PropertyTypeFilter,
  Sort,
  ListingParams,
  ListingParamsMethods,
  AREA_NAME_PROPERTY,
  HAS_IMAGE_PROPERTY,
  BOUNDARY_PROPERTY,
  PRICE_MIN_PROPERTY,
  PRICE_MAX_PROPERTY,
  MAINTENANCE_FEE_PROPERTY,
  LOCKER_PROPERTY,
  LISTED_TO_PROPERTY,
  LISTED_SINCE_PROPERTY,
  PARKING_SPACES_PROPERTY,
  SQFT_MAX_PROPERTY,
  SQFT_MIN_PROPERTY,
  BATHROOMS_PROPERTY,
  BEDROOMS_PROPERTY,
  RENTAL_PROPERTY,
  STATUS_PROPERTY,
  ZOOM_PROPERTY,
  LATITUDE_PROPERTY,
  LONGITUDE_PROPERTY,
  SLUG_PROPERTY,
} from './types';

//#region Types

export type ListingParamsSerializer = (params: ListingParams) => void;

/**
 * The ListingParamsImpl class is responsible for managing the listing parameters object used throughout the application as a way to persist the user's filter and sort preferences.
 *
 * This implementation ensures backward compatibility with deprecated property types and updates the "listing-params" cookie whenever the object is updated.
 */
export default class ListingParamsImpl implements ListingParams, ListingParamsMethods {
  sort: Sort;
  filter: Filter;
  private serialize?: ListingParamsSerializer;

  constructor(listingParams: Record<string, unknown>, serialize?: ListingParamsSerializer) {
    Object.assign(this, listingParams);
    this.serialize = serialize;
  }

  setSort = (sort: Sort) => {
    if (this.sort !== sort) {
      this.sort = sort;
      this.serialize?.(this);
    }
  };

  /**
   * Set the filter for the listing params Object. The listing params object will be serialized and stored in the "listing-params" cookie.
   *
   * NOTE: This method ensures backward compatibility with deprecated property types.
   *
   * @param filter The filter to set.
   */
  setFilter = (filter: Filter) => {
    const filterDiff = diff(this.filter, filter);
    const propsAreEqual = (Object.keys(filterDiff).length === 0);
    if (!propsAreEqual) {
      const originalFilter = cloneDeep(filter);
      if (originalFilter?.homeType) {
        const homeTypeFilter = ensureBackwardCompatibilityForHomeType(originalFilter.homeType);
        delete homeTypeFilter.houseDetached;
        delete homeTypeFilter.houseSemidetached;
        delete homeTypeFilter.houseAttached;
        originalFilter.homeType = homeTypeFilter;
      }
      this.filter = cloneDeep(deepmerge(defaultListingParams.filter, originalFilter));
      this.serialize?.(this);
    }
  };

  setSlug = (slug: Filter[typeof SLUG_PROPERTY]) => {
    if (this.filter.slug !== slug) {
      this.filter.slug = slug;
      this.serialize?.(this);
    }
  };

  setLongitude = (longitude: Filter[typeof LONGITUDE_PROPERTY]) => {
    if (this.filter.longitude !== longitude) {
      this.filter.longitude = longitude;
      this.serialize?.(this);
    }
  };

  setLatitude = (latitude: Filter[typeof LATITUDE_PROPERTY]) => {
    if (this.filter.latitude !== latitude) {
      this.filter.latitude = latitude;
      this.serialize?.(this);
    }
  };

  setZoom = (zoom: Filter[typeof ZOOM_PROPERTY]) => {
    if (zoom > 1 && zoom < 23) {
      this.filter.zoom = Math.round(zoom);
    }
    this.serialize?.(this);
  };

  setStatus = (status: Filter[typeof STATUS_PROPERTY]) => {
    if (this.filter.status !== status) {
      this.filter.status = status;
      this.serialize?.(this);
    }
  };

  setRental = (rental: Filter[typeof RENTAL_PROPERTY]) => {
    if (this.filter.rental !== rental) {
      this.filter.rental = rental;
      this.filter.priceMin = 0;
      this.filter.priceMax = null;
      this.serialize?.(this);
    }
  };

  setBedrooms = (bedrooms: Filter[typeof BEDROOMS_PROPERTY]) => {
    if (this.filter.bedrooms !== bedrooms) {
      this.filter.bedrooms = bedrooms;
      this.serialize?.(this);
    }
  };

  setBathrooms = (bathrooms: Filter[typeof BATHROOMS_PROPERTY]) => {
    if (this.filter.bathrooms !== bathrooms) {
      this.filter.bathrooms = bathrooms;
      this.serialize?.(this);
    }
  };

  setSqftMin = (sqftMin: Filter[typeof SQFT_MIN_PROPERTY]) => {
    if (this.filter.sqftMin !== sqftMin) {
      this.filter.sqftMin = sqftMin;
      this.serialize?.(this);
    }
  };

  setSqftMax = (sqftMax: Filter[typeof SQFT_MAX_PROPERTY]) => {
    if (this.filter.sqftMax !== sqftMax) {
      this.filter.sqftMax = sqftMax;
      this.serialize?.(this);
    }
  };

  setParkingSpaces = (parkingSpaces: Filter[typeof PARKING_SPACES_PROPERTY]) => {
    if (this.filter.parkingSpaces !== parkingSpaces) {
      this.filter.parkingSpaces = parkingSpaces;
      this.serialize?.(this);
    }
  };

  setListedSince = (listedSince: Filter[typeof LISTED_SINCE_PROPERTY]) => {
    if (this.filter.listedSince !== listedSince) {
      this.filter.listedSince = listedSince;
      this.serialize?.(this);
    }
  };

  setListedTo = (listedTo: Filter[typeof LISTED_TO_PROPERTY]) => {
    if (this.filter.listedTo !== listedTo) {
      this.filter.listedTo = listedTo;
      this.serialize?.(this);
    }
  };

  setLocker = (locker: CondoOrTownhouseAdditionalFilter[typeof LOCKER_PROPERTY]) => {
    if (this.filter.additional.condoOrTownhouse.locker !== locker) {
      this.filter.additional.condoOrTownhouse.locker = locker;
      this.serialize?.(this);
    }
  };

  setMaintenanceFee = (maintenanceFee: CondoOrTownhouseAdditionalFilter[typeof MAINTENANCE_FEE_PROPERTY]) => {
    if (this.filter.additional.condoOrTownhouse.maintenanceFee !== maintenanceFee) {
      this.filter.additional.condoOrTownhouse.maintenanceFee = maintenanceFee;
      this.serialize?.(this);
    }
  };

  setPriceMin = (priceMin: Filter[typeof PRICE_MIN_PROPERTY]) => {
    if (this.filter.priceMin !== priceMin) {
      this.filter.priceMin = priceMin;
      this.serialize?.(this);
    }
  };

  setPriceMax = (priceMax: Filter[typeof PRICE_MAX_PROPERTY]) => {
    if (this.filter.priceMax !== priceMax) {
      this.filter.priceMax = priceMax;
      this.serialize?.(this);
    }
  };

  setPrice = (priceMin: Filter[typeof PRICE_MIN_PROPERTY], priceMax: Filter[typeof PRICE_MAX_PROPERTY]) => {
    if (this.filter.priceMin !== priceMin || this.filter.priceMax !== priceMax) {
      this.filter.priceMin = priceMin;
      this.filter.priceMax = priceMax;
      this.serialize?.(this);
    }
  };

  /**
   * Sets the property type filter for the listing params object.
   *
   * NOTE: This method ensures backward compatibility with deprecated property types.
   *
   * @param homeType The property type filter to set.
   */
  setHomeType = (homeType: PropertyTypeFilter) => {
    const filterDiff = diff(this.filter.homeType, homeType);
    const propsAreEqual = (Object.keys(filterDiff).length === 0);
    if (!propsAreEqual) {
      const originalFilter = ensureBackwardCompatibilityForHomeType(cloneDeep(homeType));
      delete originalFilter.houseDetached;
      delete originalFilter.houseSemidetached;
      delete originalFilter.houseAttached;
      this.filter.homeType = cloneDeep(deepmerge(defaultListingParams.filter.homeType, originalFilter));
      this.serialize?.(this);
    }
  };

  setBoundary = (boundary: Filter[typeof BOUNDARY_PROPERTY]) => {
    if (this.filter.boundary !== boundary) {
      this.filter.boundary = boundary;
      this.serialize?.(this);
    }
  };

  setAreaName = (areaName: Filter[typeof AREA_NAME_PROPERTY]) => {
    if (this.filter.areaName !== areaName) {
      this.filter.areaName = areaName;
      this.serialize?.(this);
    }
  };

  setHasImage = (hasImage: Filter[typeof HAS_IMAGE_PROPERTY]) => {
    const newHasImage = hasImage || DEFAULT_HAS_IMAGE_VALUE;
    if (this.filter.hasImage !== newHasImage) {
      this.filter.hasImage = newHasImage;
      this.serialize?.(this);
    }
  };
}

//#endregion