import { Point } from 'data/listing';
import { AutocompletePredictionTypes } from 'utils/google-maps/types';

export interface ParsedAddress {
  [key: string]: string | Point | undefined;
  position?: Point;
}

/**
 * Parse Google Place API object to ParsedAddress object
 *
 * @param place - return object from Google Place API
 * @returns
 */
export const parseGooglePlaceApiObject = (place: any): ParsedAddress => {
  const addressComponents = place.address_components;
  const parsedAddress: ParsedAddress = {};

  addressComponents.forEach((component: any) => {
    component.types.forEach((type: string) => {
      if (Object.values(AutocompletePredictionTypes).includes(type as AutocompletePredictionTypes)) {
        // If it's province or state, we want to store the short name
        if (['administrative_area_level_1', 'route'].includes(type)) {
          parsedAddress[type] = component.short_name;
        } else {
          parsedAddress[type] = component.long_name;
        }
      }
      // AutocompletePredictionTypes is missing street_number handle is separately
      if (type === 'street_number') {
        parsedAddress[type] = component.long_name;
      }
    });
  });

  if (place.geometry && place.geometry.location) {
    parsedAddress.position = new Point({
      type: 'Point',
      coordinates: [place.geometry.location.lng, place.geometry.location.lat],
    });
  }

  return parsedAddress;
};

export const cleanSlug = (str: string): string => {
  str = str.trim().toLowerCase();
  str = str.replace(/ /g, '-');
  str = str.replace(/&/g, 'and');
  str = transformAccentCharacters(str);
  str = removeSpecialCharacters(str);
  return removeMultipleDashes(str);
};

const removeSpecialCharacters = (str: string): string => {
  return str.replace(/[^a-z0-9-]+/g, '');
};

const transformAccentCharacters = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const removeMultipleDashes = (str: string): string => {
  return str.replace(/-+/g, '-');
};

/**
 * Create slug from parsed address object
 *
 * @param address - Parsed address object from Google Place API
 * @returns
 */
export const getSlugFromAddress = (address: ParsedAddress): string => {
  const parts: string[] = [
    address['unit_number'] as string,
    address['street_number'] as string,
    address['route'] as string,
    address['locality'] as string,
    address['administrative_area_level_1'] as string,
  ].filter(Boolean);

  const slug = parts.map(part => cleanSlug(part)).join('-');

  return slug;
};