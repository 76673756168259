import { ReadonlyDeep } from 'type-fest';
import { CountryCode, CountryCodeList } from 'types/countries';

export const ZOOCASA_TENANT_NAME = 'zoocasa' as const;
export const EXP_IN_SERVICE_TENANT_NAME = 'expInService' as const;
export const EXP_REALTY_CA_TENANT_NAME = 'exprealtyCA' as const;
export const EXP_REALTY_US_TENANT_NAME = 'exprealtyUS' as const;
export const EXP_COMMERCIAL_TENANT_NAME = 'expCommercial' as const;

export type TenantName = typeof ZOOCASA_TENANT_NAME | typeof EXP_IN_SERVICE_TENANT_NAME | typeof EXP_REALTY_CA_TENANT_NAME | typeof EXP_REALTY_US_TENANT_NAME | typeof EXP_COMMERCIAL_TENANT_NAME;


type FooterColItem = {
    label: string;
    link?: string;
    target?: string;
    isAnchor?: boolean;
  };

  interface FooterImageData {
    src: string;
    alt: string;
  }

export type ExpFooterComponentProps = {
    config: FooterConfig;
  }

export type HeaderConfig = ReadonlyDeep<{
  mainButtonCopy: string,
  authButtonCopy: string,
  hasJoinExpPage: boolean,
  authMenuItems: {
    label: string;
    link?: string;
    id: string;
    signOut?: boolean;
  }[];
  menuItems: {
    label: string;
    link?: string;
    id: string;
    newTab?: boolean;
  }[];
}>;

export type ContentCard = {
  title: string,
  image: string,
  titleColor: string,
  backgroundColor: string,
  content: string,
  link: string,
  desktopTransform?: string,
  tabletTransform?: string,
  translateYDesktop?: string,
  translateYTablet?: string,
  rounded?: boolean,
  altText?: string,
  buttonText?: string,
};
export type TestimonialProps = {
  text: string;
  image: string;
  alt: string;
  name: string;
  company: string;
  key: number;
};

export type HomeConfig = ReadonlyDeep<{
  heroImage: string,
  heroImageAlt: string,
  testimonialBlock: {
    backgroundImage: string,
    heading: string,
    testimonials: TestimonialProps[],
    },
    joinUsSection:{
      link: string,
    title: string,
    title2: string,
    description: string,
    imageUrl: string,
    buttonText: string,
  },
  contentCards: {
    sectionTitleParts: string[],
    cards: ContentCard[],
  },
  carouselImages?: string[]
}>;

export type FooterConfig = ReadonlyDeep<{
    spotlightImage: string;
    links: {
      homeLink: string;
    };
    footerColumns?: {
      column1?: FooterColItem[];
      column2?: FooterColItem[];
      column3?: FooterColItem[];
      bottom?: FooterColItem[][];
      joinUsLink?: string;
      footerImageData?: FooterImageData;
    };
    organization: {
      phoneNumber: (isOnPreConPage?: boolean) => string | null;
      email: (isOnPreConPage?: boolean) => string | null;
      address: (siteLocation?: CountryCodeList) => string | null;
      twitterUrl: string;
      facebookUrl: string;
      instagramUrl: string;
      linkedinUrl: string;
      youtubeUrl?: string;
      joinSectionUrl?: string;
    };
    showAccessibilityLink?: boolean;
  }>

export type AgentSearchConfig = ReadonlyDeep<{
    headerImage: {
        mobileSrc: string;
        src: string;
        alt: string;
    };
    agentTypeName: string;
    featuredAgentDescriptor: string,
    pageTitle: string;
}>

export type Tenant<T> = ReadonlyDeep<{
  id: string;
  theme: T; // This is only styles and colors and we need to migrate to MUI theming
  localization: {
    languages: string[]; // This is the languages that are supported by the tenant
    countries: CountryCode[]; // This is the country that the tenant can be rendered in
    namespaces: string[]; // This is the i18n namespaces required for the tenant. Files are located in public/locales
  };
  config: {
    header?: HeaderConfig;
    home?: HomeConfig;
    footer?: FooterConfig;
    agentSearch?: AgentSearchConfig;
  };
  debug:{
    forceTenantHeaderValue?: string;
  }
}>
