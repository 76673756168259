import { ThemeNames } from 'types/themes';
import { isTenantExpCA, isTenantExpUS } from './tenant/which-tenant';
import { isClientSide } from 'utils/host-config';

/**
 * Determines if the accessibility widget should be shown for a given tenant.
 * Currently only available for eXp CA, eXp US, and Zoocasa tenants.
 *
 * @param tenant - The name of the theme/tenant
 * @returns boolean indicating if the accessibility widget should be shown
 */
export const shouldShowAccessibilityWidget = (
  tenant: ThemeNames
): boolean => {
  return (
    tenant === ThemeNames.ZOOCASA ||
    isTenantExpCA(tenant) ||
    isTenantExpUS(tenant)
  );
};

// Type definitions for EqualWeb
export interface EqualWebA11y {
  openMenu: () => void;
}

export interface EqualWeb {
  a11y?: EqualWebA11y;
}

declare global {
  interface Window {
    interdeal?: EqualWeb;
  }
}

/**
 * Opens the EqualWeb accessibility menu if available
 * @returns boolean indicating if the menu was successfully opened
 */
export const openEqualWebMenu = (): boolean => {
  try {
    if (!isClientSide()) {
      console.warn('EqualWeb accessibility menu can only be opened in browser environment.');
      return false;
    }

    const interdeal = window?.interdeal;
    if (!interdeal) {
      console.warn('EqualWeb is not initialized. Please ensure the script is loaded correctly.');
      return false;
    }

    // Handle both v4.6.12 and v5.0.13
    if (interdeal.a11y?.openMenu) {
      // Newer v5.x.x method
      interdeal.a11y.openMenu();
      return true;
    } else {
      // Older v4.x.x method
      // For backward compatibility with older versions of EqualWeb
      const element = document.getElementById('INDmenu-btn');
      if (element) {
        element.click();
        return true;
      }
    }

    console.warn('EqualWeb accessibility menu is not available. Please ensure the script is loaded correctly.');
    return false;
  } catch (error) {
    console.error('Error opening EqualWeb accessibility menu:', error);
    return false;
  }
};
