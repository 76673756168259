import formatCurrency from 'utils/format-currency';
import dayjs from 'dayjs';
import {
  AreaPageListingResponse,
  AreaPageListing_Provider,
  AreaPageListing_Provider as ProviderId,
} from '@zoocasa/go-search';
import { camelizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import { AgreementTypes } from 'types/agreement-storage-types';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import endpoint, { ErrorResponse } from 'utils/endpoint';
import { HttpRequestMethodTypes } from 'types';
import { OpenHouse, Room } from 'data/listing/property';
import { SimilarListingsRequest } from '@zoocasa/go-search';
import { SimilarListingsResponse } from '@zoocasa/go-search';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { LocationSimilarListingsRequest, Status } from '@zoocasa/go-search/dist/similarlistings';
import { getGoSearchHost, getInsightsHost, isServerSide } from 'utils/host-config';
import { cleanDashedString } from 'data/addresses';
import { withTryAsync } from 'utils/withTry';

import type { Miscellaneous } from 'types/listings';
import type { Meta } from 'utils/types';
import type { Cluster } from 'components/search/types';

export class Point {
  type: 'Point';
  coordinates: number[];

  constructor(point: Record<string, unknown>) {
    Object.assign(this, point);
  }

  get longitude() {
    return this.coordinates[0];
  }
  get latitude() {
    return this.coordinates[1];
  }
}

export interface GetSimilarListingsByLocationProps {
  position: Point;
  size: number;
  homeType: string;
  bedrooms: number;
  bathrooms: number;
  sqft: number;
  status?: string;
  minPrice?: number;
  maxPrice?: number;
  isRental?: boolean;
}

export default class Listing {
  id: number;
  type: string | null;
  isRental: boolean | null;
  price: number | null;
  soldPrice: number | null;
  status: string | null;
  addressPath: string | null;
  isStandardized: string;
  bedrooms: number;
  bathrooms: number;
  bedroomsPartial: number | null;
  bathroomsPartial: number | null;
  providerId: number | null;
  isVow: boolean;
  isItsoVow: boolean;
  isCrea: boolean;
  isImageReady: boolean;
  imageUrls: string[];
  thumbnailUrls: string[];
  position: Point | null;
  unitNumber: string | null;
  mlsNum: string | null;
  streetNumber: string;
  streetName: string;
  city: string | null;
  province: string;
  postalCode: string;
  openHouses: null | OpenHouse[];
  styleName: string;
  imageUrl: string | null;
  thumbnailUrl: string;
  path: string;
  parking: number | null;
  addressSlug: string;
  availableAt: string | null;
  addedAt: Date;
  expiredAt: Date | null;
  updatedOn: Date | null;
  soldAt: Date | null;
  lastStatus: null;
  squareFootage: Record<'min' | 'max', number> | null;
  virtualTourUrl: string | null;
  levels: string | null;
  locker: string | null;
  maintenanceFees: number | null;
  taxes: number | null;
  exterior: string | null;
  garage: string | null;
  driveway: string | null;
  heat: string | null;
  ac: string | null;
  basement: string | null;
  heatingFuel: string | null;
  rooms: Room[] | null;
  lotFrontage: string | null;
  lotDepth: string | null;
  style: 'house' | 'condo' | 'land';
  extras: string;
  description: string | null;
  brokerage: string | null;
  brokerageEmail: string | null;
  brokeragePhone: string | null;
  disclaimer: string | null;
  misc: Miscellaneous | null;
  neighbourhoodName: string | null;
  url: string | null;
  imageDesc: string | null;
  termsOfUseRequired: boolean | null;
  statusId: number | null;
  imageDescs: Map<string, string> | null;
  neighbourhood: Record<'id', number> | null;
  pool: string | null;
  buyerInfo: {
    agentFirstName?: string | null;
    agentLastName?: string | null;
    officeName?: string | null;
  } | null;
  lastFetched: Date | null;
  expAgentId: number | null;

  constructor(listing: Record<string, unknown>) {
    const camelizedListing = camelizeKeys(listing);
    const attributes = camelizedListing.attributes as Record<string, unknown>;
    const relationships = camelizedListing.relationships as Record<
      string,
      unknown
    >;
    const neighbourhood = relationships?.neighbourhood as Record<string, unknown> | null;
    const neighbourhoodData = neighbourhood && 'data' in neighbourhood ? neighbourhood.data as Record<string, unknown> : {};

    const hasImageDescs = !!attributes?.imageDescs;
    const imageDescsMap = hasImageDescs ? new Map() : null;
    if (hasImageDescs) {
      const imageDescs = attributes.imageDescs as Record<string, string>;
      for (const key in imageDescs) {
        if (Object.hasOwnProperty.call(imageDescs, key)) {
          (imageDescsMap as Map<string, string>).set(key, imageDescs[key]);
        }
      }
    }

    const formattedListing = {
      ...attributes,
      ...relationships,
      id: Number(camelizedListing.id),
      price: Number(attributes.price),
      soldPrice: attributes.soldPrice ? Number(attributes.soldPrice) : null,
      position: attributes.position ? new Point(attributes.position as Record<string, unknown>) : null,
      openHouses: attributes.openHouses ? (attributes.openHouses as Record<string, unknown>[])?.map(o => new OpenHouse(o)) : null,
      rooms: attributes.rooms ? (attributes.rooms as Record<string, unknown>[])?.map(r => new Room(r)) : null,
      addedAt: new Date(attributes.addedAt as string),
      expiredAt: attributes.expiredAt ? new Date(attributes.expiredAt as string) : null,
      updatedOn: attributes.updatedOn ? new Date(attributes.updatedOn as string) : null,
      lastFetched: attributes.lastFetched ? new Date(attributes.lastFetched as string) : null,
      soldAt: attributes.soldAt ? new Date(attributes.soldAt as string) : null,
      imageDescs: imageDescsMap,
      parking: attributes.parking ? Number(attributes.parking) : null,
      neighbourhood: { ...neighbourhoodData },
    } as Listing;
    Object.assign(this, formattedListing);
  }

  get isCondo() {
    return this.style === 'condo';
  }

  get isAvailable() {
    return this.status === 'available';
  }

  get isSold() {
    return this.status === 'not_available_sold';
  }

  get isSoldWithoutSoldPrice() {
    return this.isSold && !this.soldPrice;
  }

  get avgSqft() {
    const { min, max } = this.squareFootage || {};
    if (min && max) {
      return Math.ceil((min + max) / 2);
    } else if (min) {
      return min;
    } else if (max) {
      return max;
    } else {
      return null;
    }
  }

  get statusLabel() {
    let lastStatusLabel: string | null = null;
    if (this.lastStatus) {
      const lastStatusMappings: Record<string, string> = {
        Bom: 'Back on Market',
        Dft: 'Deal Fell Through',
        Exp: 'Expired',
        Ext: 'Extended',
        Lc: 'Leased Conditionally',
        Lsd: 'Leased',
        New: 'New',
        Pc: 'Price Change',
        Sc: 'Sold Conditionally',
        Sld: 'Sold',
        Sus: 'Suspended',
        Ter: 'Terminated',
      };
      lastStatusLabel = lastStatusMappings[this.lastStatus];
    }
    if (lastStatusLabel && lastStatusLabel !== 'New') {
      return lastStatusLabel;
    } else if (this.isAvailable) {
      return this.isRental ? 'For Rent' : 'For Sale';
    } else if (this.isSold) {
      return this.isRental ? 'Leased' : 'Sold';
    } else {
      return 'Inactive';
    }
  }

  get isPrivate() {
    return this.isVow || !this.isAvailable;
  }

  get isPillar9Vow() {
    return this.providerId === ProviderId.Pillar9Vow;
  }

  get isRebgvVow() {
    return this.providerId === ProviderId.RebgvVow;
  }

  get isVancouverFeed() {
    return this.providerId === ProviderId.RebgvVow || this.providerId === ProviderId.RebgvIdx;
  }

  get isTrebIdxInactive() {
    return this.providerId === AreaPageListing_Provider.TrebIdx && !this.isAvailable;
  }

  get miscellaneous() {
    return this.misc ? camelizeKeys(this.misc as any) as Record<string, string | number | boolean> : null;
  }

  get daysOnMarket() {
    if (this.addedAt) {
      const addedAt = dayjs(this.addedAt);
      return dayjs().diff(addedAt, 'day');
    } else {
      return null;
    }
  }

  get sqftLabel() {
    let sqft = '-';
    if (this.squareFootage) {
      const { min, max } = this.squareFootage;
      // Note: if max sqft is 499 always show 0-499, for anything else show min-max or just min or max when only one available
      sqft = max === 499 ? '0-499' : [min, max].filter(item => item).join('-');
      sqft = `${sqft} sqft`;
    }
    return sqft;
  }

  get lotSize() {
    const { lotFrontage, lotDepth } = this;
    if (lotFrontage && lotDepth) {
      return `${lotFrontage} x ${lotDepth}`;
    }
    return null;
  }

  get homeType() {
    const condos = ['condo', 'condo-highrise', 'condo-lowrise', 'condo-new-development', 'condo-other'];
    const houses = ['house-detached', 'house-attached', 'house-semidetached', 'townhouse', 'house-other'];
    const styleName: string | null = this.styleName;
    if (!styleName) {
      return null;
    } else if (condos.includes(styleName)) {
      return 'condo';
    } else if (houses.includes(styleName)) {
      return styleName;
    }
  }

  get priceBySqft() {
    if (this.avgSqft && this.price) {
      return formatCurrency(Math.floor(this.price / this.avgSqft));
    }
    return null;
  }

  get seoType() {
    let type = this.homeType?.split('-')[0];
    if (type) {
      type = type.replace(/\bhouse/i, 'House');
      return type[0].toUpperCase() + type.slice(1).toLowerCase();
    }
  }

  get addressPathWithFallback() {
    return cleanDashedString(this.addressPath || this.path);
  }

  get isSignInAndTermsOfUseRequired() {
    return this.termsOfUseRequired ? true : false;
  }

  get isTreb() {
    return !this.isCrea;
  }

  get agreementType() {
    if (this.isPillar9Vow){
      return AgreementTypes.PILLAR9_VOW;
    } else if (this.isRebgvVow){
      return AgreementTypes.REBGV_VOW;
    } else if (this.isItsoVow){
      return AgreementTypes.ITSO_VOW;
    } else if (this.isCrea){
      return AgreementTypes.CREA;
    }
  }

  get priceLabel() {
    const price = this.price;
    return price ? formatCurrency(price) : '';
  }

  get isOffMarket() {
    return this.status === 'deleted';
  }

  getStreet = (isAuthenticated: boolean) => {
    return capitalizeWords(!isAuthenticated && this.isPrivate ? this.streetName : this.seoStreet);
  };

  get seoStreet() {
    const unitNumber = this.unitNumber || '';
    const streetAddress = this.streetAddress;
    const seoStreet = unitNumber.length > 0 ? `${unitNumber} - ${streetAddress}` : streetAddress;
    return seoStreet;
  }

  get streetAddress() {
    const { streetNumber, streetName } = this;
    return streetNumber ? `${streetNumber} ${streetName}` : streetName;
  }
}

export async function getListings(query: Record<string, unknown>, isServerSide = false, abortSignal?: AbortSignal) {
  const [response, error] = await withTryAsync<{data: Record<string, unknown>[]; meta: Meta}, ErrorResponse>(endpoint)('/services/api/v3/listings', HttpRequestMethodTypes.GET, query, undefined, abortSignal);

  if (error) {
    return { listings: [], meta: error.meta as Meta };
  }
  const { data, meta } = response;

  if (isServerSide) {
    return { listings: data as unknown as Listing[], meta };
  }
  const listings = data.map(d => new Listing(d));
  return { listings, meta };
}

export async function getListingByMls(mls: string[], limit = 15, isServerSide = false) {
  const goSearchHost = getGoSearchHost(isServerSide);
  const searchMlsUrl = `${goSearchHost}/api/search-mls?mls=${mls}&limit=${limit}`;
  const response = await fetchWithRetry(searchMlsUrl, { method: 'GET' });
  const content = await response.blob();
  const buffer = await content.arrayBuffer();
  const { data } = AreaPageListingResponse.decode(new Uint8Array(buffer));
  return data;
}

/**
 * @deprecated
 *
 * @see SearchPageModel#getClustersWithinBoundary
 * @see SearchPageModel#getListingsWithinBoundary
 */
export async function getClustersAndListings(params: Record<string, unknown> = {}, isServerSide = false) {
  const { data, meta } = await endpoint<{data: Record<string, unknown>[]; meta: Meta}>('/services/api/v3/listings', HttpRequestMethodTypes.GET, params);
  const filteredListings = data.filter(data => data.type === 'listings');
  const clusters = data.filter(data => data.type === 'clusters') as unknown as Cluster[];

  if (isServerSide) {
    return { listings: filteredListings, clusters, meta };
  }
  const listings = filteredListings.map(d => new Listing(d));
  return { listings, clusters, meta: meta };
}

export async function getListingsByIds(ids: number[], limit = 100, isServerSide = false) {
  const goSearchHost = getGoSearchHost(isServerSide);
  const searchIdsPageUrl = `${goSearchHost}/api/search-ids?ids=${ids}&limit=${limit}`;
  const response = await fetchWithRetry(searchIdsPageUrl, { method: 'GET' });
  const content = await response.blob();
  const buffer = await content.arrayBuffer();
  const { data } = AreaPageListingResponse.decode(new Uint8Array(buffer));
  return data;
}

export async function getListingsBySlug(
  { slug,
    getDeleted = false,
    serializer = 'full',
    isServerSide = false,
  }:
    { slug: string;
      getDeleted?: boolean;
      serializer?: string;
      isServerSide?: boolean; }, abortSignal?: AbortSignal) {
  const params = {
    filter: {
      slug: slug,
      status: {
        available: true,
        notAvailable: true,
        notAvailableSold: true,
        notAvailableOther: true,
        deleted: getDeleted,
      },
    },
    serializer,
  };

  const { listings, meta } = await getListings(params, isServerSide, abortSignal);
  return { listings: sortListingsByDate(listings), meta };
}

export async function getListingById(id: string | number, isServerSide = false, abortSignal?: AbortSignal) {
  const [response, error] = await withTryAsync<{data: Record<string, unknown>}, ErrorResponse>(endpoint)(`/services/api/v3/listings/${id}`, HttpRequestMethodTypes.GET, undefined, undefined, abortSignal);

  if (error) {
    return undefined;
  }
  const { data } = response;
  return isServerSide ? data as unknown as Listing : new Listing(data);
}

export async function getSimilarListings(id: string | number, size: number, status?: string, minPrice?: number, maxPrice?: number, isRental=false) {
  const host = getInsightsHost(isServerSide());
  const similarListingsUrl = `${host}/insights/listings/${id}/similar`;
  let resp: SimilarListingsResponse;
  let statusId: Status | undefined = undefined;

  if (status === 'not-available-sold') {
    statusId = Status.NotAvailableSold;
  }

  try {
    const similarListingsRequest = SimilarListingsRequest.fromPartial({ pageSize: size, statusId, minPrice, maxPrice });
    const requestBody = SimilarListingsRequest.encode(similarListingsRequest).finish();
    const response = await fetchWithRetry(similarListingsUrl, { method: 'POST', body: requestBody });
    const content = await response.blob();
    const buffer = await content.arrayBuffer();
    resp = SimilarListingsResponse.decode(new Uint8Array(buffer));
  } catch (error: any) {
    console.error('Failed to fetch search page listings: %s', error);
    resp = SimilarListingsResponse.fromPartial({});
  }
  return resp.data;
}

export async function getSimilarListingsByLocation({ position, size, homeType, bedrooms, bathrooms, sqft, status, minPrice, maxPrice }: GetSimilarListingsByLocationProps) {
  const host = getInsightsHost(isServerSide());
  const url = `${host}/insights/similar`;
  let resp: SimilarListingsResponse;

  // Get int value from status name
  let statusId: Status = Status.Available;
  if (status === 'not-available-sold') statusId = Status.NotAvailableSold;

  try {
    const request = LocationSimilarListingsRequest.fromPartial({ position: { type: 'Point', coordinates: position.coordinates }, homeType, bedrooms, bathrooms, sqft, size, statusId, minPrice, maxPrice });
    const requestBody = LocationSimilarListingsRequest.encode(request).finish();
    const response = await fetchWithRetry(url, { method: 'POST', body: requestBody });
    const content = await response.blob();
    const buffer = await content.arrayBuffer();
    resp = SimilarListingsResponse.decode(new Uint8Array(buffer));
  } catch (error: any) {
    console.error('Failed to fetch search page listings: %s', error);
    resp = SimilarListingsResponse.fromPartial({});
  }
  return resp.data;
}

interface Item<T = any> {
  [key: string]: T;
}
type ValueGetter<T = any> = (item: T) => string | number;

export const ASCENDING_ORDER = 'ascending';
export const DESCENDING_ORDER = 'descending';
export type SortingOrder = typeof ASCENDING_ORDER | typeof DESCENDING_ORDER;

export function sortBy<T extends Item>(array: T[], key: ValueGetter<T>, order: SortingOrder = ASCENDING_ORDER) {
  if (order === ASCENDING_ORDER) {
    return [...array].sort((a, b) => key(a) > key(b) ? 1 : -1 );
  }
  return [...array].sort((a, b) => key(a) > key(b) ? -1 : 1 );
}

export function sortListingsByDate(listings: Listing[]) {
  return listings.sort((a, b) => a.addedAt < b.addedAt ? 1 : -1);
}

export function sortListingsByThumbnail(listings: Listing[]) {
  return listings.sort(a => a.thumbnailUrl ? -1 : 1);
}

export function averageListingPrice<T extends Item>(listings: T[], type?: string) {
  let listingsWithPrice = listings.filter(listing => listing.price != null);
  if (type == 'condo') {
    listingsWithPrice = listingsWithPrice.filter(listing => listing?.homeType == 'condo' || (listing?.propertyType?.includes('Condo') && !listing?.propertyType?.includes('Townhouse')));
  }
  else if (type == 'townhouse') {
    listingsWithPrice = listingsWithPrice.filter(listing => listing?.homeType == 'townhouse' || listing?.propertyType?.includes('Townhouse'));
  }
  else if (type == 'house') {
    listingsWithPrice = listingsWithPrice.filter(listing => listing?.homeType?.includes('house') || /Detached|Attached/.test(listing?.propertyType));
  }
  const average = listingsWithPrice.reduce((total, next) => total + (next.price || 0), 0) / listingsWithPrice.length;
  return average;
}

export function toJson(data: any) {
  return JSON.parse(JSON.stringify(data));
}