/**
 * The response from the ipify API.
 * 
 * @see https://www.ipify.org/
 */
type IpifyResponse = {
  ip: string;
}

/**
 * Fetches the user IP address from the ipify API.
 * 
 * @returns The user IP address or undefined if the request fails.
 */
export async function getUserIpFromIpify(): Promise<string | undefined> {
  const response = await fetch('https://api.ipify.org?format=json');
  if (response.ok) {
    const data = await response.json() as IpifyResponse;
    if ('ip' in data) {
      return data.ip;
    }
  }
  return undefined;
}