
import { IpLocationResponse } from 'pages/api/ip-location';
import { HttpStatusCode } from 'types/HttpStatusCode';

//#region Types

export type IpLocationErrorResponseType = {
  /** Indicates that the request failed */
  error: true;
  /** Reason for the error */
  reason: string;
  /** HTTP Status Code */
  statusCode: HttpStatusCode;
};

export type IpLocationApiResponseType = IpLocationResponse | IpLocationErrorResponseType;

//#endregion

//#region Constants

export const IP_LOCATION_API_PATH = '/api/ip-location' as const;
export const IP_LOCATION_API_USER_IP_QUERY_PARAM = 'user_ip' as const;
export const IP_LOCATION_API_DEVICE_ID_QUERY_PARAM = 'device_id' as const;

export const IP_LOCATION_API_ERROR_FAILED_TO_FETCH = 'Failed to fetch user location from ip-location API' as const;
export const IP_LOCATION_API_ERROR_FAILED_TO_PARSE_ERROR_JSON = 'Failed to parse JSON from ip-location API error response' as const;
export const IP_LOCATION_API_ERROR_FAILED_TO_PARSE_RESPONSE_JSON = 'Failed to parse JSON from ip-location API response' as const;

//#endregion

//#region Functions

/**
 * Returns the path for the IP location API endpoint with the given user IP and device ID.
 *
 * @param userIp The IP address of the user. E.g. "123.123.123.123"
 * @param deviceId The device ID of the user. E.g. "1234567890"
 * 
 * @returns The string representing the path for the IP location API endpoint in the format of `/api/ip-location?user_ip=123.123.123.123&device_id=1234567890`
 */
export function getIpLocationApiPath(userIp: string, deviceId: string) {
  return `${IP_LOCATION_API_PATH}?${IP_LOCATION_API_USER_IP_QUERY_PARAM}=${userIp}&${IP_LOCATION_API_DEVICE_ID_QUERY_PARAM}=${deviceId}`;
}

/**
 * Fetches the user location from our internal IP location API endpoint.
 *
 * @param ip The IP address of the user. E.g. "123.123.123.123"
 * @param deviceId The device ID of the user. E.g. "1234567890"
 * @param apiPath A function that returns the path for the ip-location API endpoint given the user IP and device ID. Defaults to {@link getIpLocationApiPath}
 * @returns The user location or an error if the request fails.
 */
export async function getUserLocationFromIp(ip?: string, deviceId?: string, apiPath: (userIp: string, deviceId: string) => string = getIpLocationApiPath): Promise<IpLocationApiResponseType> {
  let response: Response;

  try {
    response = await fetch(apiPath(ip, deviceId), { method: 'GET' });
  } catch (error) {
    const errorResult: IpLocationErrorResponseType = {
      error: true,
      reason: error instanceof Error ? error.message : IP_LOCATION_API_ERROR_FAILED_TO_FETCH,
      statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
    };
    return errorResult;
  }

  // Handle error responses
  if (!response.ok) {
    // ip-location API returns errors as standard HTTP status codes and a JSON body with error details
    if (response.body) {
      try {
        const errorData = await response.json();
        if ('error' in errorData) {
          const errorResult: IpLocationErrorResponseType = {
            error: true,
            reason: errorData.reason,
            statusCode: response.status,
          };
          return errorResult;
        }
      } catch (jsonError) {
        console.debug(`${IP_LOCATION_API_ERROR_FAILED_TO_PARSE_ERROR_JSON}, ${jsonError}`);
      }
    }

    // Default error when no valid error response body
    const errorResult: IpLocationErrorResponseType = {
      error: true,
      reason: response.statusText,
      statusCode: response.status,
    };
    return errorResult;
  }

  // Handle success responses
  try {
    const result = await response.json() as IpLocationResponse;
    return result;
  } catch (error) {
    return {
      error: true,
      reason: IP_LOCATION_API_ERROR_FAILED_TO_PARSE_RESPONSE_JSON,
      statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
    };
  }
}

//#endregion
