/**
 * Converts a string to kebab-case (dash-case) format.
 * 
 * @param str - The input string to convert (can be camelCase, PascalCase, snake_case, or space-separated)
 * @returns The kebab-case formatted string
 * @example
 * dasherize("camelCase")      // → "camel-case"
 * dasherize("PascalCase")     // → "pascal-case"
 * dasherize("snake_case")     // → "snake-case"
 * dasherize("Regular Text")   // → "regular-text"
 */
export function dasherize(str: string) {
  return str
    .replace(/([A-Z])/g, '-$1') // Finds capital letters and adds a dash before them
    .replace(/[\s_-]+/g, '-') // Replaces any sequence of spaces, underscores, or dashes with a single dash
    .replace(/^-/, '') // Removes any dash at the start of the string
    .toLowerCase(); // Converts everything to lowercase
}
