import { AddressType, addressTypeFromJSON } from '@zoocasa/go-search';
import { CountryCodeList, getCountryName } from 'types/countries';
import {
  isCanadianProvinceSlug,
  isUsStateSlug,
  ProvinceOrStateCode,
  provinceOrStateCodeFromSlugMappings,
  provinceOrStateNameFromCode,
  provinceOrStateSlugFromCode,
} from 'utils/province_or_state';
import { ROOT_PATH_SUFFIX } from './types';
import { deDasherize, humanize } from '@zoocasa/node-kit/strings';
import { Filter,
  Status,
  AVAILABLE_STATUS,
  NOT_AVAILABLE_SOLD_STATUS,
  NOT_AVAILABLE_OTHER_STATUS,
  Sort,
} from 'contexts/preferences/listing-params';
import { Nullable } from 'types/nullable';
import { PartialDeep } from 'type-fest';
import { getFilterFromURLSearchParams, getPageFromURLSearchParams, getSortFromURLSearchParams } from 'utils/listing-query-helper';
import setNestedProperty from 'set-value';
import { PAGE_URL_SEARCH_PARAM, SORT_URL_SEARCH_PARAM } from 'types/SupportedURLSearchParam';

//#region types
export const LISTING_STATUS_SOLD = 'sold';
export const LISTING_STATUS_PAST_LISTINGS = 'past-listings';
export const LISTING_STATUS_LEASED = 'leased';
export const LISTING_STATUS_EXPIRED_LISTINGS = 'expired-listings';
export const LISTING_STATUS_FOR_RENT = 'for-rent';
export type ListingStatusFilter = typeof LISTING_STATUS_SOLD | typeof LISTING_STATUS_PAST_LISTINGS | typeof LISTING_STATUS_LEASED | typeof LISTING_STATUS_EXPIRED_LISTINGS | typeof LISTING_STATUS_FOR_RENT;

export const HOME_TYPE_HOUSES = 'houses';
export const HOME_TYPE_TOWNHOUSES = 'townhouses';
export const HOME_TYPE_CONDOS = 'condos';
export const HOME_TYPE_LAND = 'land';
export const HOME_TYPE_COMMERCIAL = 'commercial';
export const HOME_TYPE_FARMS = 'farms';

export type HomeTypeFilter = typeof HOME_TYPE_HOUSES | typeof HOME_TYPE_TOWNHOUSES | typeof HOME_TYPE_CONDOS | typeof HOME_TYPE_LAND | typeof HOME_TYPE_COMMERCIAL | typeof HOME_TYPE_FARMS;

export const FILTER_OPEN_HOUSES = 'open-houses';
export const FILTER_ON_WATERFRONT = 'on-waterfront';
export const FILTER_WITH_GARAGE = 'with-garage';
export const FILTER_WITH_SWIMMING_POOL = 'with-swimming-pool';
export type PropertyFeatureFilter = typeof FILTER_OPEN_HOUSES | typeof FILTER_ON_WATERFRONT | typeof FILTER_WITH_GARAGE | typeof FILTER_WITH_SWIMMING_POOL;

export type SeoFilters = {
  listingStatus?: ListingStatusFilter;
  homeType?: HomeTypeFilter;
  propertyFeature?: PropertyFeatureFilter;
}

/**
 * Represents a parsed location segment with both its display name and URL slug
 */
export type ParsedLocation = {
  name: string;
  slug: string;
}

/**
 * Represents the parsed components of an address URL path
 */
export type ParsedAddressPath = {
  type: AddressType;
  country?: ParsedLocation;
  provinceOrState?: ParsedLocation;
  subDivision?: ParsedLocation;
  neighbourhood?: ParsedLocation;
  address?: ParsedLocation;
  acceptedSegments?: string[];
  discardedSegments?: string[];
  acceptedPathSegments?: string[];
  acceptedSeoFilterSegment?: string;
  sort?: Sort;
  page?: number;
  slug?: string;
  filter?: PartialDeep<Filter>;
  seoFilters?: SeoFilters;
}
//#endregion

//#region constants
const LISTING_STATUS: ListingStatusFilter[] = [LISTING_STATUS_FOR_RENT, LISTING_STATUS_SOLD, LISTING_STATUS_PAST_LISTINGS, LISTING_STATUS_LEASED, LISTING_STATUS_EXPIRED_LISTINGS];
const HOME_TYPES: HomeTypeFilter[] = [HOME_TYPE_HOUSES, HOME_TYPE_TOWNHOUSES, HOME_TYPE_CONDOS, HOME_TYPE_LAND, HOME_TYPE_COMMERCIAL, HOME_TYPE_FARMS];
const PROPERTY_FEATURE: PropertyFeatureFilter[] = [FILTER_OPEN_HOUSES, FILTER_ON_WATERFRONT, FILTER_WITH_GARAGE, FILTER_WITH_SWIMMING_POOL];

type SeoStatusFilterToStatusMapType = { status: Status; seoFilter: Nullable<ListingStatusFilter> };
const StatusMap: readonly SeoStatusFilterToStatusMapType[] = [
  { status: AVAILABLE_STATUS, seoFilter: LISTING_STATUS_FOR_RENT },
  { status: NOT_AVAILABLE_SOLD_STATUS, seoFilter: LISTING_STATUS_SOLD },
  { status: NOT_AVAILABLE_SOLD_STATUS, seoFilter: LISTING_STATUS_LEASED },
  { status: NOT_AVAILABLE_OTHER_STATUS, seoFilter: LISTING_STATUS_PAST_LISTINGS },
  { status: NOT_AVAILABLE_OTHER_STATUS, seoFilter: LISTING_STATUS_EXPIRED_LISTINGS },
];

//#endregion

//#region exported functions

/**
 * Parses a URL path to determine possible address types and extract relevant segments.
 *
 * The function analyzes the URL structure based on these patterns:
 * 1. `/<country-code>-<root-path-suffix>`
 *    - Country level (e.g., /ca-<root-path-suffix>)
 *    - With SEO filter (e.g., /ca-<root-path-suffix>/houses)
 *
 * 2. `/<province-or-state-slug>-<root-path-suffix>`
 *    - Province/State level (e.g., /ontario-<root-path-suffix>)
 *    - With SEO filter (e.g., /ontario-<root-path-suffix>/sold)
 *
 * 3. `/<sub-division>-<root-path-suffix>`
 *    - City level (e.g., /toronto-on-<root-path-suffix>)
 *    - With SEO filter (e.g., /toronto-on-<root-path-suffix>/with-garage)
 *
 * 4. `/<sub-division>-<root-path-suffix>/<segment>`
 *    - Could be either:
 *      - Neighbourhood (e.g., /toronto-on-<root-path-suffix>/downtown)
 *      - Address without neighbourhood (e.g., /toronto-on-<root-path-suffix>/123-main-st)
 *      - SEO filter, determined by checking against known filters:
 *        - Listing Status: sold, past-listings, leased, expired-listings
 *        - Home Types: houses, townhouses, condos, land, commercial, farms
 *        - Other Filters: open-houses, on-waterfront, with-garage, with-swimming-pool
 *
 * 5. `/<sub-division>-<root-path-suffix>/<neighbourhood>/<address>`
 *    - Address with neighbourhood (e.g., /toronto-on-<root-path-suffix>/downtown/123-main-st)
 *
 * Special cases:
 * - If a path has more than 3 segments, segments beyond the third are stored in `discardedSegments`
 * - For 2-segment paths without SEO filters, both neighbourhood and address interpretations are returned
 * - For 3-or-more segment paths, only the address-with-neighbourhood interpretation is returned
 * - SEO filters can be applied at country, province/state, or city level
 *
 * @param path The URL path to parse
 * @param rootPathSuffix The suffix to use for the root path. Defaults to {@link ROOT_PATH_SUFFIX}.
 * @returns Array of possible ParsedAddressPath objects
 */
export function parseAddressPath(path: string, rootPathSuffix: string = ROOT_PATH_SUFFIX): ParsedAddressPath[] {
  // Remove leading/trailing slashes and split into segments
  const cleanPath = path.replace(/^\/|\/$/g, '');
  const rawSegments = cleanPath.split('/');
  const segments: string[] = [];

  let filterSearchParams: URLSearchParams | undefined;
  let searchParams: URLSearchParams | undefined;

  for (const segment of rawSegments) {
    if (isFilterSegment(segment)) {
      const [, params] = parseSegmentForSearchParams(segment);
      filterSearchParams = params;
    } else {
      const [baseSegment, params] = parseSegmentForSearchParams(segment);
      searchParams = params;
      segments.push(baseSegment);
    }
  }

  // Handle invalid or empty paths
  if (!segments.length) {
    return [];
  }

  // Check if it's a basic root path
  if (segments[0] === ROOT_PATH_SUFFIX) {
    return [];
  }

  // Parse the first segment which should be "<slug>-real-estate"
  const firstSegment = segments[0];
  if (!firstSegment.endsWith(`-${rootPathSuffix}`)) {
    return [];
  }

  const result: ParsedAddressPath[] = [];

  const countryValues = Object.values(CountryCodeList).map(code => code.toLowerCase());

  const paginationParams = filterSearchParams || searchParams || new URLSearchParams();

  const sort: Sort = getSortFromURLSearchParams(paginationParams);
  const page: number = getPageFromURLSearchParams(paginationParams);

  for (let index = 0; index < segments.length; index++) {
    const segment = segments[index].toLowerCase();

    if (index === 0) {
      // Handle the first segment which should be "<slug>-real-estate"
      // It can be a country, province/state, or sub-division
      const slug = segment.slice(0, -(`-${rootPathSuffix}`).length);

      if (slug.length === 0) { //handle empty slug case: "/-real-estate"
        break;
      }

      if (countryValues.includes(slug)) { //handle country case: "/ca-real-estate"\
        result.push({
          type: AddressType.ADDRESS_TYPE_COUNTRY,
          country: {
            name: getCountryName(slug),
            slug: slug,
          },
          discardedSegments: [],
          acceptedSegments: [segment],
          acceptedPathSegments: [segment],
          acceptedSeoFilterSegment: undefined,
          slug,
          sort,
          page,
        });
      } else if (provinceOrStateCodeFromSlugMappings[slug]) { //handle province/state case: "/ontario-real-estate"
        const country = getCountryFromProvinceOrStateSlug(slug);
        result.push({
          type: AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE,
          country,
          provinceOrState: {
            name: provinceOrStateNameFromCode(provinceOrStateCodeFromSlugMappings[slug]),
            slug: slug,
          },
          discardedSegments: [],
          acceptedSegments: [segment],
          acceptedPathSegments: [segment],
          acceptedSeoFilterSegment: undefined,
          slug,
          sort,
          page,
        });
      } else { //handle sub-division case: "/toronto-on-real-estate"
        const lastDashIndex = slug.lastIndexOf('-');
        const dashedSubDivision = lastDashIndex !== -1 ? slug.slice(0, lastDashIndex) : slug;
        const provinceOrStateCode = lastDashIndex !== -1 ? slug.slice(lastDashIndex + 1) : slug;
        const provinceOrState = getProvinceOrStateFromCode(provinceOrStateCode as ProvinceOrStateCode);
        const country = getCountryFromProvinceOrStateSlug(provinceOrState.slug);

        result.push({
          type: AddressType.ADDRESS_TYPE_SUB_DIVISION,
          country,
          provinceOrState,
          subDivision: {
            name: humanize(deDasherize(dashedSubDivision)),
            slug: slug,
          },
          discardedSegments: [],
          acceptedSegments: [segment],
          acceptedPathSegments: [segment],
          acceptedSeoFilterSegment: undefined,
          slug,
          sort,
          page,
        });
      }

      const hasNextSegment = segments[index + 1] !== undefined;
      if (!hasNextSegment) {
        result[0].filter = filterSearchParams ? createFilterFromFilterParams(filterSearchParams) : undefined;
      }
    } else if (index === 1) {
      // Handle the second segment which can be a SEO filter, a filter, a neighbourhood, or an address
      const seoFilters = parseSeoFilter(segment);
      const nextSegment = segments[index + 1];
      const nextSegmentSeoFilters = parseSeoFilter(nextSegment);
      const hasNextSegment = nextSegment !== undefined;
      const hasFilters = filterSearchParams ? Array.from(filterSearchParams.entries()).length > 0 : false;
      const isSeoFilterSegment = seoFilters !== undefined;
      const isSegmentBeforeSeoFilters = nextSegmentSeoFilters !== undefined;
      const isSegmentBeforeFilters = !hasNextSegment && hasFilters;
      const isSegmentBeforeAddress = hasNextSegment && !isSegmentBeforeSeoFilters && !isSegmentBeforeFilters;
      const isSegmentBeforePagination = !hasNextSegment && (paginationParams.has(PAGE_URL_SEARCH_PARAM) || paginationParams.has(SORT_URL_SEARCH_PARAM));

      if (isSeoFilterSegment) {
        // handle SEO filter case: "/toronto-on-real-estate/<supported seo filter value>"
        //
        // If this segment is a SEO filter, we update the first result with values related to the SEO filter,
        // discard the rest of the segments that wont be used.

        result[0].seoFilters = seoFilters;
        result[0].discardedSegments = segments.slice(2);
        result[0].acceptedSegments.push(segment);
        result[0].acceptedSeoFilterSegment = segment;
        result[0].filter = createFilterFromSeoFilter(seoFilters);
        break;
      } else if (isSegmentBeforeFilters || isSegmentBeforeAddress || isSegmentBeforeSeoFilters || isSegmentBeforePagination) {
        // Handle neighbourhood cases:
        // 1 - "/toronto-on-real-estate/downtown/123-main-st"
        // 2 - "/toronto-on-real-estate/downtown/filter?param=value"
        // 3 - "/toronto-on-real-estate/downtown/sold" (deprecated)
        // 4 - "/toronto-on-real-estate/downtown?page=1&sort=date"
        // 5 - "/toronto-on-real-estate/downtown/filter?param=value&page=1&sort=date"
        //
        // NOTE: we don't support neighbourhoods with SEO filters but we can use that to infer that this path must be a neighbourhood
        // and not an address. As such, we will not use it as the the filter and will add it to the discardedSegments.

        const cityResult = result.pop();
        if (cityResult) {
          const slug = `${segment}-${cityResult.subDivision.slug}`;
          const name = humanize(deDasherize(segment));
          const acceptedSegments = [...cityResult.acceptedSegments, segment];
          const acceptedPathSegments = [...cityResult.acceptedPathSegments, segment];
          const discardedSegments = isSegmentBeforeSeoFilters ? segments.slice(2) : [];
          const filter = filterSearchParams ? createFilterFromFilterParams(filterSearchParams) : undefined;
          result.push({
            type: AddressType.ADDRESS_TYPE_NEIGHBOURHOOD,
            country: { ... cityResult.country },
            provinceOrState: { ... cityResult.provinceOrState },
            subDivision: { ... cityResult.subDivision },
            neighbourhood: {
              name: name,
              slug: slug,
            },
            discardedSegments,
            acceptedSegments,
            acceptedPathSegments,
            acceptedSeoFilterSegment: undefined,
            slug,
            filter,
            sort,
            page,
          });
        }
        if (!isSegmentBeforeAddress) { // we want to continue parsing the rest of the path if this is an address path
          break;
        }
      } else {
        // Handle ambiguous case: "/toronto-on-real-estate/downtown"
        //
        // If the url only has 2 segments we can infer that it's a neighbourhood or an address and as such
        // we add both interpretations to the result.

        const cityResult = result.pop(); // By this point, we should have only one result and it should be a city
        if (cityResult) {
          const slug = `${segment}-${cityResult.subDivision.slug}`;
          const name = humanize(deDasherize(segment));
          const acceptedSegments = [...cityResult.acceptedSegments, segment];
          const acceptedPathSegments = [...cityResult.acceptedPathSegments, segment];
          result.push({
            type: AddressType.ADDRESS_TYPE_NEIGHBOURHOOD,
            country: { ... cityResult.country },
            provinceOrState: { ... cityResult.provinceOrState },
            subDivision: { ... cityResult.subDivision },
            neighbourhood: {
              name: name,
              slug: slug,
            },
            discardedSegments: segments.slice(2),
            acceptedSegments,
            acceptedPathSegments,
            acceptedSeoFilterSegment: undefined,
            slug,
            sort,
            page,
          });
          result.push({
            type: AddressType.ADDRESS_TYPE_ADDRESS,
            country: { ... cityResult.country },
            provinceOrState: { ... cityResult.provinceOrState },
            subDivision: { ... cityResult.subDivision },
            address: {
              name: name,
              slug: slug,
            },
            discardedSegments: segments.slice(2),
            acceptedSegments,
            acceptedPathSegments,
            acceptedSeoFilterSegment: undefined,
            slug,
          });
        }
        break;
      }
    } else if (index === 2) {
      // handle address with neighbourhood case: "/toronto-on-real-estate/downtown/123-main-st"
      //
      // If there are 3 or more segments, the first segment is a city, the second segment is a
      // neighbourhood and the third segment is an address. At this point the result array should
      // have only one result and it should be a neighbourhood. So we need to remove the neighbourhood
      // result and add the address as a the result.
      //
      // NOTE: Addresses do not support filters and we need to discard any filter value previously parsed.
      const neighbourhoodResult = result.pop();
      if (neighbourhoodResult) {
        const addressSlug = `${segment}-${neighbourhoodResult.subDivision.slug}`;
        const addressName = humanize(deDasherize(segment));
        const acceptedSegments = [...neighbourhoodResult.acceptedSegments, segment];
        const acceptedPathSegments = [...neighbourhoodResult.acceptedPathSegments, segment];
        const discardedSegments = segments.slice(3);

        result.push({
          type: AddressType.ADDRESS_TYPE_ADDRESS,
          country: { ... neighbourhoodResult.country },
          provinceOrState: { ... neighbourhoodResult.provinceOrState },
          neighbourhood: { ... neighbourhoodResult.neighbourhood },
          subDivision: { ... neighbourhoodResult.subDivision },
          address: {
            name: addressName,
            slug: addressSlug,
          },
          discardedSegments,
          acceptedSegments,
          acceptedPathSegments,
          acceptedSeoFilterSegment: undefined,
          slug: addressSlug,
        });
      }
      break;
    }
  }

  return result;
}

/**
 * Creates a strongly-typed ParsedAddressPath from a plain object
 * @param obj Object representing a ParsedAddressPath
 * @returns ParsedAddressPath object with proper typing
 */
export function parsedAddressPathFromJson(obj: Record<string, unknown>): ParsedAddressPath | undefined {
  if (!obj) return undefined;

  const parsedLocation = (loc: any): ParsedLocation | undefined => {
    if (!loc) return undefined;
    return {
      name: String(loc.name),
      slug: String(loc.slug),
    };
  };

  return {
    type: addressTypeFromJSON(obj.type),
    country: parsedLocation(obj.country),
    provinceOrState: parsedLocation(obj.provinceOrState),
    subDivision: parsedLocation(obj.subDivision),
    neighbourhood: parsedLocation(obj.neighbourhood),
    address: parsedLocation(obj.address),
    acceptedSegments: Array.isArray(obj.acceptedSegments) ? obj.acceptedSegments.map(String) : undefined,
    discardedSegments: Array.isArray(obj.discardedSegments) ? obj.discardedSegments.map(String) : [],
    sort: obj.sort as Sort | undefined,
    page: typeof obj.page === 'number' ? obj.page : undefined,
    slug: typeof obj.slug === 'string' ? obj.slug : undefined,
    filter: obj.filter as PartialDeep<Filter> | undefined,
    seoFilters: obj.seoFilters as SeoFilters | undefined,
  };
}

//#region private functions
function parseSeoFilter(segment: string): SeoFilters | undefined {
  if (LISTING_STATUS.includes(segment as ListingStatusFilter)) {
    return { listingStatus: segment as ListingStatusFilter };
  }
  if (HOME_TYPES.includes(segment as HomeTypeFilter)) {
    return { homeType: segment as HomeTypeFilter };
  }
  if (PROPERTY_FEATURE.includes(segment as PropertyFeatureFilter)) {
    return { propertyFeature: segment as PropertyFeatureFilter };
  }
  return undefined;
}

export function createFilterFromSeoFilter(seoFilter: SeoFilters) {
  const filter: PartialDeep<Filter> = {};
  if (seoFilter.listingStatus) {
    const match = StatusMap.find(value => value.seoFilter === seoFilter.listingStatus);
    if (match) {
      filter.status = match.status;

      /** Handle the case where the listing status is for `rent` or `leased` by setting the rental filter to true as
       * there is no {@link Status} specific to leasing */
      if (seoFilter.listingStatus === LISTING_STATUS_FOR_RENT || seoFilter.listingStatus === LISTING_STATUS_LEASED) {
        filter.rental = true;
      }
    }
  } else if (seoFilter.homeType) {
    setNestedProperty(filter, 'homeType.house', seoFilter.homeType === HOME_TYPE_HOUSES);
    setNestedProperty(filter, 'homeType.townhouse', seoFilter.homeType === HOME_TYPE_TOWNHOUSES);
    setNestedProperty(filter, 'homeType.condo', seoFilter.homeType === HOME_TYPE_CONDOS);
    setNestedProperty(filter, 'homeType.land', seoFilter.homeType === HOME_TYPE_LAND);
    setNestedProperty(filter, 'homeType.commercial', seoFilter.homeType === HOME_TYPE_COMMERCIAL);
    setNestedProperty(filter, 'homeType.farm', seoFilter.homeType === HOME_TYPE_FARMS);
  } else if (seoFilter.propertyFeature) {
    if (seoFilter.propertyFeature === FILTER_OPEN_HOUSES) {
      filter.openHouse = true;
    } else if (seoFilter.propertyFeature === FILTER_ON_WATERFRONT) {
      filter.waterfront = true;
    } else if (seoFilter.propertyFeature === FILTER_WITH_GARAGE) {
      filter.garage = true;
    } else if (seoFilter.propertyFeature === FILTER_WITH_SWIMMING_POOL) {
      filter.pool = true;
    }
  }
  return filter;
}

/**
 * Checks if a segment is a filter segment.
 * A filter segment must be the string "filter" followed by search parameters.
 *
 * @example
 * isFilterSegment('filter?bedrooms=2') // returns true
 * isFilterSegment('filter') // returns true
 * isFilterSegment('filter?') // returns false
 * isFilterSegment('not-filter?param=1') // returns false
 *
 * @param segment The URL path segment to check
 * @returns true if the segment is a filter segment with parameters
 */
function isFilterSegment(segment: string): boolean {
  return segment === 'filter' || segment.startsWith('filter?');
}

/**
 * Parses a URL path segment to extract the base segment and any search parameters.
 *
 * @param segment - The URL path segment to parse.
 * @returns A tuple where the first element is the base segment without search parameters,
 *  and the second element is an instance of {@link URLSearchParams} if search parameters are present, otherwise undefined.
 */
function parseSegmentForSearchParams(segment: string): [string, URLSearchParams | undefined] {
  try {
    const parts = segment.split('?');
    if (parts.length < 2) {
      return [segment, undefined];
    } else {
      const params = new URLSearchParams(parts[1]);
      return [parts[0], Array.from(params.entries()).length > 0 ? params : undefined];
    }
  } catch {
    return [segment, undefined];
  }
}

function createFilterFromFilterParams(filterParams: URLSearchParams): PartialDeep<Filter> {
  const filter: PartialDeep<Filter> = getFilterFromURLSearchParams(filterParams);

  // The getFilterFromURLSearchParams always set the homeType.house to true when no house filter is present
  // so we need to search the filterParams for the keys that are associated with setting the homeType.house
  // and if not available, remove it from the filter
  const homeTypeHouseKeys = ['house', 'attached', 'semidetached', 'detached'];
  const hasHomeTypeHouseKey = homeTypeHouseKeys.some(key => filterParams.has(key));
  if (!hasHomeTypeHouseKey) {
    delete filter.homeType.house;
    if (Object.keys(filter.homeType).length === 0) {
      delete filter.homeType;
    }
  }
  return filter;
}

function getCountryFromProvinceOrStateSlug(slug: string): ParsedLocation {
  const country = {
    name: getCountryName('unknown'),
    slug: ROOT_PATH_SUFFIX,
  };

  if (isCanadianProvinceSlug(slug)) {
    country.name = getCountryName(CountryCodeList.CANADA);
    country.slug = CountryCodeList.CANADA.toLowerCase();
  } else if (isUsStateSlug(slug)) {
    country.name = getCountryName(CountryCodeList.UNITED_STATES);
    country.slug = CountryCodeList.UNITED_STATES.toLowerCase();
  }
  return country;
}

function getProvinceOrStateFromCode(provinceOrStateCode: ProvinceOrStateCode): ParsedLocation {
  const provinceOrState: ParsedLocation = {
    name: 'Unknown',
    slug: ROOT_PATH_SUFFIX,
  };
  const provinceOrStateSlug = provinceOrStateSlugFromCode(provinceOrStateCode);
  if (provinceOrStateSlug) {
    provinceOrState.name = provinceOrStateNameFromCode(provinceOrStateCode);
    provinceOrState.slug = provinceOrStateSlug;
  }
  return provinceOrState;
}

//#endregion