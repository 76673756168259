import { Prettify } from 'types/prettify';

//#region Filter Property Names
export const SLUG_PROPERTY = 'slug' as const;
export const LONGITUDE_PROPERTY = 'longitude' as const;
export const LATITUDE_PROPERTY = 'latitude' as const;
export const ZOOM_PROPERTY = 'zoom' as const;
export const STATUS_PROPERTY = 'status' as const;
export const RENTAL_PROPERTY = 'rental' as const;
export const BEDROOMS_PROPERTY = 'bedrooms' as const;
export const BATHROOMS_PROPERTY = 'bathrooms' as const;
export const SQFT_MIN_PROPERTY = 'sqftMin' as const;
export const SQFT_MAX_PROPERTY = 'sqftMax' as const;
export const PARKING_SPACES_PROPERTY = 'parkingSpaces' as const;
export const LISTED_SINCE_PROPERTY = 'listedSince' as const;
export const LISTED_TO_PROPERTY = 'listedTo' as const;
export const LOCKER_PROPERTY = 'locker' as const;
export const MAINTENANCE_FEE_PROPERTY = 'maintenanceFee' as const;
export const PRICE_MIN_PROPERTY = 'priceMin' as const;
export const PRICE_MAX_PROPERTY = 'priceMax' as const;
export const HOME_TYPE_PROPERTY = 'homeType' as const;
export const BOUNDARY_PROPERTY = 'boundary' as const;
export const AREA_NAME_PROPERTY = 'areaName' as const;
export const HAS_IMAGE_PROPERTY = 'hasImage' as const;
export const OPEN_HOUSE_PROPERTY = 'openHouse' as const;
export const GARAGE_PROPERTY = 'garage' as const;
export const POOL_PROPERTY = 'pool' as const;
export const FIREPLACE_PROPERTY = 'fireplace' as const;
export const WATERFRONT_PROPERTY = 'waterfront' as const;
export const ADDITIONAL_PROPERTY = 'additional' as const;
export const PROVIDER_ID_PROPERTY = 'providerId' as const;
export const CONDO_OR_TOWNHOUSE_ADDITIONAL_PROPERTY = 'condoOrTownhouse' as const;
//#endregion

//#region Listing Params Property Names
export const PAGE_PROPERTY = 'page' as const;
export const SORT_PROPERTY = 'sort' as const;
export const FILTER_PROPERTY = 'filter' as const;
//#endregion

export const PRICE_SORT = 'price';
export const PRICE_DESCENDING_SORT = '-price';
export const DATE_SORT = 'date';
export const DATE_DESCENDING_SORT = '-date';
export const BEDROOMS_SORT = 'bedrooms';
export const BEDROOMS_DESCENDING_SORT = '-bedrooms';
export const BATHROOMS_SORT = 'bathrooms';
export const BATHROOMS_DESCENDING_SORT = '-bathrooms';
export const SORT_KEYS: readonly string[] = [PRICE_SORT, PRICE_DESCENDING_SORT, DATE_SORT, DATE_DESCENDING_SORT, BEDROOMS_SORT, BEDROOMS_DESCENDING_SORT, BATHROOMS_SORT, BATHROOMS_DESCENDING_SORT];
export type Sort = typeof PRICE_SORT | typeof PRICE_DESCENDING_SORT | typeof DATE_SORT | typeof DATE_DESCENDING_SORT | typeof BEDROOMS_SORT | typeof BEDROOMS_DESCENDING_SORT | typeof BATHROOMS_SORT | typeof BATHROOMS_DESCENDING_SORT;

export const AVAILABLE_STATUS = 'available';
export const NOT_AVAILABLE_STATUS = 'not-available';
export const NOT_AVAILABLE_SOLD_STATUS = 'not-available-sold';
export const NOT_AVAILABLE_OTHER_STATUS = 'not-available-other';
export const INACTIVE_STATUS = 'inactive';
export const EXPIRED_STATUS = 'expired';
export const STATUS_KEYS: readonly string[] = [AVAILABLE_STATUS, NOT_AVAILABLE_STATUS, NOT_AVAILABLE_SOLD_STATUS, NOT_AVAILABLE_OTHER_STATUS];
export type Status = typeof AVAILABLE_STATUS | typeof NOT_AVAILABLE_STATUS | typeof NOT_AVAILABLE_SOLD_STATUS | typeof NOT_AVAILABLE_OTHER_STATUS;

export const ANY_LOCKER_VALUE = 'any';
export const YES_LOCKER_VALUE = 'yes';
export const NO_LOCKER_VALUE = 'no';
export const LOCKER_VALUES: readonly string[] = [ANY_LOCKER_VALUE, YES_LOCKER_VALUE, NO_LOCKER_VALUE];
export type Locker = typeof ANY_LOCKER_VALUE | typeof YES_LOCKER_VALUE | typeof NO_LOCKER_VALUE;

export type Bedroom = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const DEFAULT_HAS_IMAGE_VALUE = false;
/** @deprecated use HOUSE_PROPERTY_TYPE instead */
export const HOUSE_DETACHED_PROPERTY_TYPE = 'houseDetached';
/** @deprecated use HOUSE_PROPERTY_TYPE instead */
export const HOUSE_SEMIDETACHED_PROPERTY_TYPE = 'houseSemidetached';
/** @deprecated use HOUSE_PROPERTY_TYPE instead */
export const HOUSE_ATTACHED_PROPERTY_TYPE = 'houseAttached';

export const HOUSE_PROPERTY_TYPE = 'house';
export const TOWNHOUSE_PROPERTY_TYPE = 'townhouse';
export const CONDO_PROPERTY_TYPE = 'condo';
export const LAND_PROPERTY_TYPE = 'land';
export const COMMERCIAL_PROPERTY_TYPE = 'commercial';
export const FARM_PROPERTY_TYPE = 'farm';

export type DeprecatedPropertyType = typeof HOUSE_DETACHED_PROPERTY_TYPE |
  typeof HOUSE_SEMIDETACHED_PROPERTY_TYPE |
  typeof HOUSE_ATTACHED_PROPERTY_TYPE;

export type SupportedPropertyType = typeof HOUSE_PROPERTY_TYPE |
  typeof TOWNHOUSE_PROPERTY_TYPE |
  typeof CONDO_PROPERTY_TYPE |
  typeof LAND_PROPERTY_TYPE |
  typeof COMMERCIAL_PROPERTY_TYPE |
  typeof FARM_PROPERTY_TYPE;

export type PropertyType = SupportedPropertyType;

/** @deprecated this filter is not used anymore and will be removed in the future */
export type DeprecatedPropertyTypeFilter = {
  /** @deprecated use house instead */
  houseDetached: boolean;
  /** @deprecated use house instead */
  houseSemidetached: boolean;
  /** @deprecated use house instead */
  houseAttached: boolean;
};

export type PropertyTypeFilter = Record<PropertyType, boolean>;

/** @deprecated this filter is not used anymore */
export type HouseAdditionalFilter = {
  singleFamily: boolean;
  basementApartment: boolean;
  duplex: boolean;
  triplex: boolean;
  'fourplex+': boolean;
};

export type CondoOrTownhouseAdditionalFilter = {
  [LOCKER_PROPERTY]: Locker;
  [MAINTENANCE_FEE_PROPERTY]: number | null;
};

export type FlattenFilter = Omit<Filter, typeof HOME_TYPE_PROPERTY | typeof ADDITIONAL_PROPERTY> & CondoOrTownhouseAdditionalFilter & PropertyTypeFilter;
export type FlattenFilterKeys = keyof FlattenFilter;
export type FilterKeys = keyof Filter;

export type LocationFilter = {
  [SLUG_PROPERTY]: string;
  [LONGITUDE_PROPERTY]: number;
  [LATITUDE_PROPERTY]: number;
  [ZOOM_PROPERTY]: number;
  [AREA_NAME_PROPERTY]: string;
  [BOUNDARY_PROPERTY]: string | null;
};

/**
 * Represents a filter object that combines all the different filters.
 */
export type Filter = Prettify<LocationFilter & {
  [RENTAL_PROPERTY]: boolean;
  [STATUS_PROPERTY]: Status;
  [HOME_TYPE_PROPERTY]: PropertyTypeFilter;
  [PRICE_MIN_PROPERTY]: number | null;
  [PRICE_MAX_PROPERTY]: number | null;
  [BEDROOMS_PROPERTY]: string;
  [SQFT_MIN_PROPERTY]: number | null;
  [SQFT_MAX_PROPERTY]: number | null;
  [LISTED_SINCE_PROPERTY]: string | null;
  [LISTED_TO_PROPERTY]: string | null;
  [BATHROOMS_PROPERTY]: string;
  [PARKING_SPACES_PROPERTY]: string;
  [OPEN_HOUSE_PROPERTY]: boolean;
  [GARAGE_PROPERTY]: boolean;
  [POOL_PROPERTY]: boolean;
  [FIREPLACE_PROPERTY]: boolean;
  [WATERFRONT_PROPERTY]: boolean;
  [ADDITIONAL_PROPERTY]: {
    [CONDO_OR_TOWNHOUSE_ADDITIONAL_PROPERTY]: CondoOrTownhouseAdditionalFilter;
  };
  [BOUNDARY_PROPERTY]: string | null;
  [PROVIDER_ID_PROPERTY]: string | null;
  [HAS_IMAGE_PROPERTY]: boolean;
}>;

export type Page = {
  number: number;
  size: number;
};

export type ListingParams = {
  [PAGE_PROPERTY]?: Page;
  [SORT_PROPERTY]: Sort;
  [FILTER_PROPERTY]: Filter;
};

export type ListingParamsMethods = {
  setSort: (sort: Sort) => void;
  setFilter: (filter: Filter) => void;
  setSlug: (slug: Filter[typeof SLUG_PROPERTY]) => void;
  setLongitude: (longitude: Filter[typeof LONGITUDE_PROPERTY]) => void;
  setLatitude: (latitude: Filter[typeof LATITUDE_PROPERTY]) => void;
  setZoom: (zoom: Filter[typeof ZOOM_PROPERTY]) => void;
  setStatus: (status: Filter[typeof STATUS_PROPERTY]) => void;
  setRental: (rental: Filter[typeof RENTAL_PROPERTY]) => void;
  setBedrooms: (bedrooms: Filter[typeof BEDROOMS_PROPERTY]) => void;
  setBathrooms: (bathrooms: Filter[typeof BATHROOMS_PROPERTY]) => void;
  setSqftMin: (sqftMin: Filter[typeof SQFT_MIN_PROPERTY]) => void;
  setSqftMax: (sqftMax: Filter[typeof SQFT_MAX_PROPERTY]) => void;
  setParkingSpaces: (parkingSpaces: Filter[typeof PARKING_SPACES_PROPERTY]) => void;
  setListedSince: (listedSince: Filter[typeof LISTED_SINCE_PROPERTY]) => void;
  setListedTo: (listedTo: Filter[typeof LISTED_TO_PROPERTY]) => void;
  setLocker: (locker: CondoOrTownhouseAdditionalFilter[typeof LOCKER_PROPERTY]) => void;
  setMaintenanceFee: (maintenanceFee: CondoOrTownhouseAdditionalFilter[typeof MAINTENANCE_FEE_PROPERTY]) => void;
  setPriceMin: (priceMin: Filter[typeof PRICE_MIN_PROPERTY]) => void;
  setPriceMax: (priceMax: Filter[typeof PRICE_MAX_PROPERTY]) => void;
  setPrice: (priceMin: Filter[typeof PRICE_MIN_PROPERTY], priceMax: Filter[typeof PRICE_MAX_PROPERTY]) => void;
  setHomeType: (homeType: PropertyTypeFilter) => void;
  setBoundary: (boundary: Filter[typeof BOUNDARY_PROPERTY]) => void;
  setAreaName: (areaName: Filter[typeof AREA_NAME_PROPERTY]) => void;
  setHasImage: (hasImage: Filter[typeof HAS_IMAGE_PROPERTY]) => void;
};