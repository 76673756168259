import Address from './client-app/types';
export default Address;
export type {
  AddressInfo,
  AddressPayload,
  AddressAttributes,
  AddressCategory,
} from './client-app/types';
export {
  NEIGHBOURHOOD_ADDRESS_TYPE,
  PLACE_NAME_ADDRESS_TYPE,
  PROVINCE_ADDRESS_TYPE,
  SUB_DIVISION_ADDRESS_TYPE,
  ADDRESS_ADDRESS_TYPE,
} from './client-app/types';
export { cleanDashedString, generateBreadcrumbLink, toJson } from './client-app/utils';
export { getAddressesBySlug, getAddressesByParams } from './client-app/fetcher';

export { parseAddressPath, parsedAddressPathFromJson, type ParsedAddressPath, type ParsedLocation } from './go-search/addressPathParser';
export { createAddressHierarchyPath } from './go-search/types';
export type { AddressHierarchyPath } from './go-search/types';

export { createAddressesApi } from './go-search/api';
export type { AddressesApiType } from './go-search/api';
