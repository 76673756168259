import React, { createContext, ReactNode, useContext } from 'react';

/* import site themes here */
import { themes, ThemeName, ThemeConfig } from 'themes';
import { themeNameOrDefault } from 'utils/themes';

/**
 * The name of the header used to select the theme via request headers.
 * This header is sent with the name of the theme.
 */
export const X_MULTITENANCY_THEME_HEADER_NAME = 'x-mulitenancy-theme';

export interface ITheme {
  themeName: ThemeName;
  theme: ThemeConfig;
  changeTheme: React.Dispatch<React.SetStateAction<string>>;
  originUrl: string;
}

export const ThemeContext = createContext<ITheme | Record<string, unknown>>({});

export function useThemeContext<T extends ITheme | Record<string, unknown> = ITheme>() {
  return useContext(ThemeContext) as T;
}

interface ThemeContextProviderProps {
  themeName?: string;
  originUrl: string;
  children: ReactNode;
}

export default function ThemeContextProvider({
  children,
  themeName: maybeThemeName,
  originUrl,
}: ThemeContextProviderProps) {
  const themeName = themeNameOrDefault(maybeThemeName);
  const theme = themes[themeName];

  return (
    <ThemeContext.Provider value={{ themeName, theme, themes, originUrl }}>
      {children}
    </ThemeContext.Provider>
  );
}
