import isGeojsonPolygon from '../utils/is-geojson-polygon';

import type { GeoJsonPolygon } from '../types';

export default function mapkitBoundaryFromGeojsonPolygon(geojsonPolygon?: GeoJsonPolygon) {
  if (geojsonPolygon && isGeojsonPolygon(geojsonPolygon)) {
    return geojsonPolygon.coordinates.map(coordinates => {
      coordinates = coordinates.slice(0, coordinates.length - 1);
      return coordinates.map(coordinate => {
        const [lng, lat] = coordinate;
        return new mapkit.Coordinate(lat, lng);
      });
    });
  }
}
