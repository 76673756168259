import {
  ANY_LOCKER_VALUE,
  AVAILABLE_STATUS,
  DATE_DESCENDING_SORT,
  LocationFilter,
  BOUNDARY_PROPERTY,
  type Filter,
  type ListingParams,
} from 'contexts/preferences/listing-params/types';
import { CountryCodeList, getCountryName } from 'types/countries';

export const DEFAULT_LISTING_PARAMS_SORT = DATE_DESCENDING_SORT;
export const DEFAULT_LISTING_PARAMS_FILTER_STATUS = AVAILABLE_STATUS;
export const DEFAULT_LISTING_PARAMS_FILTER_ZOOM = 14;
export const DEFAULT_LISTING_PARAMS_FILTER_HAS_IMAGE = false;

type LocationFilterWithoutBoundary = Omit<LocationFilter, typeof BOUNDARY_PROPERTY>;

/**
 * Toronto, ON location.
 * Latitude: 43.653226
 * Longitude: -79.3831843
 * Slug: toronto-on
 * Area Name: Toronto, ON
 */
export const TORONTO_LOCATION: LocationFilterWithoutBoundary = {
  latitude: 43.653226,
  longitude: -79.3831843,
  slug: 'toronto-on',
  areaName: 'Toronto, ON',
  zoom: DEFAULT_LISTING_PARAMS_FILTER_ZOOM,
};

/**
 * Tampa, FL location.
 * Latitude: 27.9506
 * Longitude: -82.4572
 * Slug: tampa-fl
 * Area Name: Tampa, FL
 */
export const TAMPA_LOCATION: LocationFilterWithoutBoundary = {
  latitude: 27.9506,
  longitude: -82.4572,
  slug: 'tampa-fl',
  areaName: 'Tampa, FL',
  zoom: DEFAULT_LISTING_PARAMS_FILTER_ZOOM,
};

/**
 * Center of the US location.
 * Latitude: 37.09024
 * Longitude: -95.712891
 * Slug: ''
 * Area Name: USA
 * Zoom: 5
 */
export const CENTER_US_LOCATION: LocationFilterWithoutBoundary = {
  latitude: 37.09024,
  longitude: -95.712891,
  slug: '',
  areaName: getCountryName(CountryCodeList.UNITED_STATES),
  zoom: 5,
};

export const DEFAULT_LISTING_PARAMS_FILTER_SHARED= Object.freeze<Filter>({
  rental: false,
  status: DEFAULT_LISTING_PARAMS_FILTER_STATUS,
  homeType: {
    house: true,
    townhouse: true,
    condo: true,
    land: true,
    commercial: true,
    farm: true,
  },
  priceMin: null,
  priceMax: null,
  listedSince: null,
  listedTo: null,
  bedrooms: '0+',
  sqftMin: null,
  sqftMax: null,
  bathrooms: '1+',
  parkingSpaces: '0+',
  openHouse: false,
  garage: false,
  pool: false,
  fireplace: false,
  waterfront: false,
  additional: {
    condoOrTownhouse: {
      locker: ANY_LOCKER_VALUE,
      maintenanceFee: null,
    },
  },
  boundary: null,
  providerId: null,
  slug: '',
  latitude: 0,
  longitude: 0,
  zoom: DEFAULT_LISTING_PARAMS_FILTER_ZOOM,
  areaName: '',
  hasImage: DEFAULT_LISTING_PARAMS_FILTER_HAS_IMAGE,
});

/**
 * Common listing params that are the base for all listings.
 *
 * This is sorted by {@link DEFAULT_LISTING_PARAMS_SORT} and filtered by
 * {@link DEFAULT_LISTING_PARAMS_FILTER_SHARED}.
 */
export const DEFAULT_LISTING_PARAMS_COMMON = Object.freeze<ListingParams>({
  sort: DATE_DESCENDING_SORT,
  filter: { ...DEFAULT_LISTING_PARAMS_FILTER_SHARED },
});

/**
 * Default listing params for Canada. It defaults to Toronto, ON.
 *
 * This is sorted by {@link DEFAULT_LISTING_PARAMS_SORT} and filtered by
 * {@link DEFAULT_LISTING_PARAMS_FILTER_SHARED} and {@link TORONTO_LOCATION}.
 */
const DEFAULT_LISTING_PARAMS = Object.freeze<ListingParams>({
  sort: DEFAULT_LISTING_PARAMS_SORT,
  filter: {
    ...DEFAULT_LISTING_PARAMS_FILTER_SHARED,
    ...TORONTO_LOCATION,
  },
});

/**
 * Default listing params for the US. It defaults to Tampa, FL.
 *
 * This is sorted by {@link DEFAULT_LISTING_PARAMS_SORT} and filtered by
 * {@link DEFAULT_LISTING_PARAMS_FILTER_SHARED} and {@link TAMPA_LOCATION}.
 */
export const DEFAULT_LISTING_PARAMS_US = Object.freeze<ListingParams>({
  sort: DEFAULT_LISTING_PARAMS_SORT,
  filter: {
    ...DEFAULT_LISTING_PARAMS_FILTER_SHARED,
    ...TAMPA_LOCATION,
  },
});

/**
 * Default listing params for the whole US. It defaults to the center of the US.
 *
 * This is sorted by {@link DEFAULT_LISTING_PARAMS_SORT} and filtered by
 * {@link DEFAULT_LISTING_PARAMS_FILTER_SHARED} and {@link CENTER_US_LOCATION}.
 */
export const DEFAULT_LISTING_PARAMS_WHOLE_US = Object.freeze<ListingParams>({
  sort: DEFAULT_LISTING_PARAMS_SORT,
  filter: {
    ...DEFAULT_LISTING_PARAMS_FILTER_SHARED,
    ...CENTER_US_LOCATION,
  },
});

export default DEFAULT_LISTING_PARAMS;
