import { FeaturesType } from 'contexts';
import { ThemeNames } from 'types/themes';

export const isThemeExpCA = (theme: ThemeNames) => theme === ThemeNames.EXP_REALTY_CA;
export const isThemeExpUS = (theme: ThemeNames) => theme === ThemeNames.EXP_REALTY_US;
export const isThemeExpInService = (theme: ThemeNames) => theme === ThemeNames.EXP_IN_SERVICE;
export const isThemeZoocasa = (theme: ThemeNames) => theme === ThemeNames.ZOOCASA;

export const isExpTheme = (theme: ThemeNames) => isThemeExpCA(theme) || isThemeExpUS(theme) || isThemeExpInService(theme);

const tenantFeatureFlags = Object.freeze({
  expCA: 'useExpRealtyCA',
  expUS: 'useExpRealtyUS',
  expInService: 'useExpInService',
});

/**
 * Determines the impersonated tenant based on the provided features.
 *
 * @param {FeaturesType} [features] - The features object that may contain tenant information.
 * @returns {ThemeNames} - The name of the tenant theme to be used.
 *
 * If no features are provided, it defaults to `ThemeNames.ZOOCASA`.
 * If the `useTenant` feature is provided, it checks for specific tenant feature flags:
 * - If `useTenant` matches `useExpRealtyCA`, it returns `ThemeNames.EXP_REALTY_CA`.
 * - If `useTenant` matches `useExpRealtyUS`, it returns `ThemeNames.EXP_REALTY_US`.
 * - If `useTenant` matches `useExpInService`, it returns `ThemeNames.EXP_IN_SERVICE`.
 * - If none of the above conditions are met, it defaults to `ThemeNames.ZOOCASA`.
 *
 * Note - this is a case-insensitive match to make the override flexible.
 */
const getImpersonatedTenant = (features?: FeaturesType) => {
  if (!features) return ThemeNames.ZOOCASA;
  if (typeof features.useTenant !=='string') return ThemeNames.ZOOCASA;
  const featureOverride = features?.useTenant?.toLowerCase() ?? '';
  switch (featureOverride) {
  case tenantFeatureFlags.expCA.toLowerCase():
    return ThemeNames.EXP_REALTY_CA;
  case tenantFeatureFlags.expUS.toLowerCase():
    return ThemeNames.EXP_REALTY_US;
  case tenantFeatureFlags.expInService.toLowerCase():
    return ThemeNames.EXP_IN_SERVICE;
  default:
    return ThemeNames.ZOOCASA;
  }
};

const isImpersonatingExpCA = (features: FeaturesType) => getImpersonatedTenant(features) === ThemeNames.EXP_REALTY_CA;
const isImpersonatingExpUS = (features: FeaturesType) => getImpersonatedTenant(features) === ThemeNames.EXP_REALTY_US;
const isImpersonatingExpInService = (features: FeaturesType) => getImpersonatedTenant(features) === ThemeNames.EXP_IN_SERVICE;
const isImpersonatingZoocasa = (features: FeaturesType) => getImpersonatedTenant(features) === ThemeNames.ZOOCASA;

export const isTenantExpCA = (theme: ThemeNames, features?: FeaturesType) => isThemeExpCA(theme) || isImpersonatingExpCA(features);
export const isTenantExpUS = (theme: ThemeNames, features?: FeaturesType) => isThemeExpUS(theme) || isImpersonatingExpUS(features);
export const isTenantExpInService = (theme: ThemeNames, features?: FeaturesType) => isThemeExpInService(theme) || isImpersonatingExpInService(features);
export const isZoocasaTenant = (theme: ThemeNames, features?: FeaturesType) => isThemeZoocasa(theme) || isImpersonatingZoocasa(features);
