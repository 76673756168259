import pino, { Logger } from 'pino';
import { isDevelopment } from './environment';
import { isServerSide } from './host-config';

let logger: Logger;
if (isDevelopment) {
  logger = pino({
    level: 'trace',
    formatters: {
      level: label => {
        return { level: label.toUpperCase() };
      },
    },
    transport: {
      target: 'pino-pretty',
      options: {
        colorize: true,
      },
    },
  });
} else if (isServerSide()) {
  logger = pino({
    level: 'warn',
    formatters: {
      level: label => {
        return { level: label.toUpperCase() };
      },
    },
    timestamp: pino.stdTimeFunctions.isoTime,
  });
} else {
  logger = pino({
    browser: {
      asObject: true,
    },
    level: 'error',
    formatters: {
      level: label => {
        return { level: label.toUpperCase() };
      },
    },
    timestamp: pino.stdTimeFunctions.isoTime,
  });
}
export default logger;