import { ZOOCASA_TENANT_HEADER_NAME } from 'constants/headers';
import { MiddlewareFunction } from 'types/fetchWithRetry';
import { defaultMiddleware } from './fetchWithRetry';

const urlInterceptSegments = [
  'https://search-stage.zoocasa.com',
  'https://search.t.zoocasa.com',
  'https://search.zoocasa.com',
  '/api/search',
  '/api/map',
  '/api/search-predictions',
];

/**
   * Middleware factory to create a middleware that injects the {@link ZOOCASA_TENANT_HEADER_NAME} header.
   *
   * @param tenantHeaderValue The value to set for the tenant header.
   * @returns The middleware function.
   */
export function createTenantHeaderMiddleware(tenantHeaderValue: string): MiddlewareFunction {
  return async (url, options) => {
    // Check if the URL matches any of the intercept segments
    if (tenantHeaderValue && urlInterceptSegments.some(segment => url.includes(segment))) {
      // Ensure headers object exists and is properly initialized
      const headers = new Headers(options.headers || {});

      // Only set if not already present
      if (!headers.has(ZOOCASA_TENANT_HEADER_NAME)) {
        headers.set(ZOOCASA_TENANT_HEADER_NAME, tenantHeaderValue);
      }

      // Update the options with the new headers
      options.headers = headers;
    }

    return await defaultMiddleware(url, options);
  };
}