import React, { CSSProperties, HTMLAttributes } from 'react';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';
import { PropsWithTestId } from 'types';

export const DEFAULT_WIDTH = '100%';
export const DEFAULT_HEIGHT = '100%';

type LoadingSkeletonProps = PropsWithTestId<{
  /**
   * The class name to be appended to the skeleton.
   */
  className?: HTMLAttributes<HTMLDivElement>['className'];
  /**
   * The width of the skeleton.
   *
   * Default is `100%`.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/width
   */
  width?: CSSProperties['width'];
  /**
   * The height of the skeleton.
   *
   * Default is `100%`.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/height
   */
  height?: CSSProperties['height'];
}>;

/**
 * A component that displays a loading skeleton with a shimmering animation effect.
 * The skeleton appears as a gradient background that animates from left to right,
 * creating a subtle loading indicator. The gradient transitions between different
 * shades of grey (grey-2 and grey-3) in a continuous loop.
 *
 * @param testId The test ID of the component. Default is "{@link testIds.loadingSkeleton loadingSkeleton}".
 * @param className The class name to be appended to the skeleton.
 * @param width The width of the skeleton. Default is `100%`. @see https://developer.mozilla.org/docs/Web/CSS/width
 * @param height The height of the skeleton. Default is `100%`. @see https://developer.mozilla.org/docs/Web/CSS/height
 */
export default function LoadingSkeleton({ className, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT, testId = testIds.loadingSkeleton }: LoadingSkeletonProps) {
  return <div className={ `${styles.component} ${className}` } style={ { width, height } } data-testid={testId} />;
}
