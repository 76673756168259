import { AddressType, SingleAddress } from '@zoocasa/go-search';
import { cloneDeep } from 'lodash';

export const ROOT_PATH_SUFFIX = 'real-estate';
export const DEFAULT_ROOT_PATH = `/${ROOT_PATH_SUFFIX}`;

export type PathSegmentKeys = Exclude<AddressType, AddressType.UNRECOGNIZED | AddressType.ADDRESS_TYPE_UNSPECIFIED>;

export type PathSegment = {
  readonly [key in PathSegmentKeys]?: string;
}

/**
 * An immutable address hierarchy path object.
 */
export type AddressHierarchyPath = {
  /** The slug of the most specific address in the hierarchy path. e.g. for a hierarchy path of ["Canada", "Ontario", "Toronto"], the slug is "toronto-on". */
  readonly slug: string

  /** The label of the most specific address in the hierarchy path. e.g. for a hierarchy path of ["Canada", "Ontario", "Toronto"], the label is "Toronto". */
  readonly label: string

  /**
   * The segments of the address hierarchy path in hierarchical order from least specific to most specific: country, province/state,
   * sub-division, neighbourhood, address.
   *
   * The segments are immutable and frozen.
   */
  readonly segments: readonly SingleAddress[]

  /**
   * The URL path based on the hierarchy of the address segments.
   *
   * The path format is determined by examining the type of the segments in the {@link segments} array and
   * generating the path with the following rules:
   *
   * 1. **Country, Province/State, or Sub-Division**:
   *    - If the last segment is of type {@link AddressType.ADDRESS_TYPE_COUNTRY}, {@link AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE},
   *    or {@link AddressType.ADDRESS_TYPE_SUB_DIVISION}, the path is constructed as `/<slug>-real-estate`, where `<slug>` is the
   *    slug of the last segment.
   *
   * 2. **Neighbourhood**:
   *    - If the last segment is of type {@link AddressType.ADDRESS_TYPE_NEIGHBOURHOOD}, the function searches for a preceding segment
   *        of type {@link AddressType.ADDRESS_TYPE_SUB_DIVISION}.
   *    - If found, the path is constructed as `/<sub-division-slug>-real-estate/<neighbourhood-name>`, where `<neighbourhood-name>`
   *        is derived from the neighbourhood's slug.
   *    - If no sub-division is found, the default path `/real-estate` is returned.
   *
   * 3. **Listing Address**:
   *    - If the last segment is of type {@link AddressType.ADDRESS_TYPE_ADDRESS}, the function searches for preceding segments of
   *        types {@link AddressType.ADDRESS_TYPE_SUB_DIVISION} and {@link AddressType.ADDRESS_TYPE_NEIGHBOURHOOD}.
   *    - If only a sub-division is found, the path is constructed as `/<sub-division-slug>-real-estate/<address>`.
   *    - If both a sub-division and neighbourhood are found, the path is constructed
   *        as `/<sub-division-slug>-real-estate/<neighbourhood-name>/<address>`.
   *    - If no sub-division or neighbourhood is found, the default path `/real-estate` is returned.
   */
  readonly path: string

  /** The path segments of the address hierarchy path. */
  readonly pathSegments: PathSegment

  /** The type of the most specific address in the hierarchy path. */
  readonly type: AddressType

  /** The SingleAddress object for the country. */
  readonly country: SingleAddress;

  /** The SingleAddress object for the province or state. */
  readonly provinceOrState: SingleAddress;

  /** The SingleAddress object for the sub-division. */
  readonly subDivision: SingleAddress;

  /** The SingleAddress object for the neighbourhood. */
  readonly neighbourhood: SingleAddress;

  /** The SingleAddress object for the address. */
  readonly address: SingleAddress;
}

/**
 * Create an immutable address hierarchy path object from an array of SingleAddress objects.
 *
 * @param segments An array of at least one SingleAddress object to create the address hierarchy path from.
 * @returns An immutable address hierarchy path object. If the array is empty, the function returns undefined.
 */
export function createAddressHierarchyPath(segments?: Readonly<SingleAddress[]>): AddressHierarchyPath | undefined {
  if (!segments || segments.length === 0) {
    return undefined;
  }
  const sortedSegments = sortSegments(segments);
  const lastSegment = sortedSegments[sortedSegments.length - 1]; // last segment is the most specific address
  const slug: string = lastSegment.slug;
  const path = getPath(sortedSegments);
  const label = getLabel(sortedSegments);

  const pathSegments: PathSegment = {
    [AddressType.ADDRESS_TYPE_COUNTRY]: getCountryPath(sortedSegments),
    [AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE]: getProvincePath(sortedSegments),
    [AddressType.ADDRESS_TYPE_SUB_DIVISION]: getSubDivisionPath(sortedSegments),
    [AddressType.ADDRESS_TYPE_NEIGHBOURHOOD]: getNeighbourhoodPath(sortedSegments),
    [AddressType.ADDRESS_TYPE_ADDRESS]: getAddressPath(sortedSegments),
  };

  const country = segments.find(segment => segment.addressType === AddressType.ADDRESS_TYPE_COUNTRY);
  const provinceOrState = segments.find(segment => segment.addressType === AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE);
  const subDivision = segments.find(segment => segment.addressType === AddressType.ADDRESS_TYPE_SUB_DIVISION);
  const neighbourhood = segments.find(segment => segment.addressType === AddressType.ADDRESS_TYPE_NEIGHBOURHOOD);
  const address = segments.find(segment => segment.addressType === AddressType.ADDRESS_TYPE_ADDRESS);

  const frozenSegments = sortedSegments.map(segment => Object.freeze(cloneDeep(segment)));
  const addressHierarchyPath: AddressHierarchyPath = {
    segments: Object.freeze(frozenSegments),
    slug,
    label,
    path,
    pathSegments: Object.freeze(pathSegments),
    country,
    provinceOrState,
    subDivision,
    neighbourhood,
    address,
    type: lastSegment.addressType,
  };
  return Object.freeze(addressHierarchyPath);
}

/**
 * Sort the segments array by address type in the following order: country, province/state, sub-division, neighbourhood, address.
 *
 * @param segments The segments array to sort.
 * @returns A new array with the segments sorted by address type.
 */
function sortSegments(segments: Readonly<SingleAddress[]>): readonly SingleAddress[] {
  const addressTypeOrder = [
    AddressType.ADDRESS_TYPE_COUNTRY,
    AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE,
    AddressType.ADDRESS_TYPE_SUB_DIVISION,
    AddressType.ADDRESS_TYPE_NEIGHBOURHOOD,
    AddressType.ADDRESS_TYPE_ADDRESS,
  ];

  return [...segments].sort(
    (a, b) =>
      addressTypeOrder.indexOf(a.addressType) -
      addressTypeOrder.indexOf(b.addressType)
  );
}

function getLabel(segments: readonly SingleAddress[]): string {
  const lastSegment = segments[segments.length - 1];
  let label = lastSegment.label;
  if (lastSegment.addressType === AddressType.ADDRESS_TYPE_ADDRESS) {
    if (lastSegment.label.includes(',')) {
      const addressPart = lastSegment.label.split(',');
      label = addressPart?.[0] || lastSegment.label;
    }
  }
  return label;
}

/**
 * Generates a URL path based on the hierarchy of address segments provided.
 *
 * The function determines the path format by examining the type of the last segment in the array.
 * It supports multiple address types, each with its own path structure:
 *
 * 1. **Country, Province/State, or Sub-Division**:
 *    - If the last segment is of type {@link AddressType.ADDRESS_TYPE_COUNTRY}, {@link AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE},
 *    or {@link AddressType.ADDRESS_TYPE_SUB_DIVISION}, the path is constructed as `/<slug>-real-estate`, where `<slug>` is the
 *    slug of the last segment.
 *
 * 2. **Neighbourhood**:
 *    - If the last segment is of type {@link AddressType.ADDRESS_TYPE_NEIGHBOURHOOD}, the function searches for a preceding segment
 *        of type {@link AddressType.ADDRESS_TYPE_SUB_DIVISION}.
 *    - If found, the path is constructed as `/<sub-division-slug>-real-estate/<neighbourhood-name>`, where `<neighbourhood-name>`
 *        is derived from the neighbourhood's slug.
 *    - If no sub-division is found, the default path `/real-estate` is returned.
 *
 * 3. **Listing Address**:
 *    - If the last segment is of type {@link AddressType.ADDRESS_TYPE_ADDRESS}, the function searches for preceding segments of
 *        types {@link AddressType.ADDRESS_TYPE_SUB_DIVISION} and {@link AddressType.ADDRESS_TYPE_NEIGHBOURHOOD}.
 *    - If only a sub-division is found, the path is constructed as `/<sub-division-slug>-real-estate/<address>`.
 *    - If both a sub-division and neighbourhood are found, the path is constructed
 *        as `/<sub-division-slug>-real-estate/<neighbourhood-name>/<address>`.
 *    - If no sub-division or neighbourhood is found, the default path `/real-estate` is returned.
 *
 * @param segments An array of `SingleAddress` objects representing the address hierarchy.
 * @returns A string representing the URL path for the address hierarchy.
 */
function getPath(segments: readonly SingleAddress[]): string {
  const lastSegment = segments[segments.length - 1];
  let path: string | undefined;

  switch (lastSegment.addressType) {
  case AddressType.ADDRESS_TYPE_COUNTRY:
    path = getCountryPath(segments);
    break;
  case AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE:
    path = getProvincePath(segments);
    break;
  case AddressType.ADDRESS_TYPE_SUB_DIVISION:
    path = getSubDivisionPath(segments);
    break;
  case AddressType.ADDRESS_TYPE_NEIGHBOURHOOD:
    path = `${getSubDivisionPath(segments)}${getNeighbourhoodPath(segments)}`;
    break;
  case AddressType.ADDRESS_TYPE_ADDRESS: {
    const subDivisionPath = getSubDivisionPath(segments);
    const neighbourhoodPath = getNeighbourhoodPath(segments);
    const addressPath = getAddressPath(segments);

    if (subDivisionPath && addressPath) {
      path = subDivisionPath;
      if (neighbourhoodPath) {
        path += neighbourhoodPath;
      }
      path += addressPath;
    }
    break;
  }
  }
  return path || DEFAULT_ROOT_PATH;
}

/**
 * Generates a URL path for a country by adding the country slug to the root path prefix.
 *
 * For example, if the country slug is `ca`, the path is `/ca-real-estate`.
 *
 * @param segments An array of `SingleAddress` objects representing the address hierarchy.
 * @returns A string representing the URL path for the country or undefined if the country slug is not found.
 */
function getCountryPath(
  segments: readonly SingleAddress[]
): string | undefined {
  const countryAddress = segments.find(
    segment => segment.addressType === AddressType.ADDRESS_TYPE_COUNTRY
  );
  return countryAddress
    ? `/${countryAddress.slug}-${ROOT_PATH_SUFFIX}`
    : undefined;
}

/**
 * Generates a URL path for a province or state by adding the province or state slug to the root path prefix.
 *
 * For example, if the province or state slug is `on`, the path is `/on-real-estate`.
 *
 * @param segments An array of `SingleAddress` objects representing the address hierarchy.
 * @returns A string representing the URL path for the province or state or undefined if the province or state slug is not found.
 */
function getProvincePath(
  segments: readonly SingleAddress[]
): string | undefined {
  const provinceAddress = segments.find(
    segment =>
      segment.addressType === AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE
  );
  return provinceAddress
    ? `/${provinceAddress.slug}-${ROOT_PATH_SUFFIX}`
    : undefined;
}

/**
 * Generates a URL path for a sub-division by adding the sub-division slug to the root path prefix.
 *
 * For example, if the sub-division slug is `brant-on`, the path is `/brant-on-real-estate`.
 *
 * @param segments An array of `SingleAddress` objects representing the address hierarchy.
 * @returns A string representing the URL path for the sub-division or undefined if the sub-division slug is not found.
 */
function getSubDivisionPath(
  segments: readonly SingleAddress[]
): string | undefined {
  const subDivisionAddress = segments.find(
    segment => segment.addressType === AddressType.ADDRESS_TYPE_SUB_DIVISION
  );
  return subDivisionAddress
    ? `/${subDivisionAddress.slug}-${ROOT_PATH_SUFFIX}`
    : undefined;
}

/**
 * Generates a URL path for a neighbourhood by removing the sub-division slug from the neighbourhood slug.
 *
 * For example, if the neighbourhood slug is `2110-st-george-brant-on` and the sub-division slug is `brant-on`, the path is `/2110-st-george`.
 *
 * @param segments An array of `SingleAddress` objects representing the address hierarchy.
 * @returns A string representing the URL path for the neighbourhood or undefined if the sub-division slug is not found.
 */
function getNeighbourhoodPath(
  segments: readonly SingleAddress[]
): string | undefined {
  const neighbourhoodAddress = segments.find(
    segment => segment.addressType === AddressType.ADDRESS_TYPE_NEIGHBOURHOOD
  );
  const subDivisionAddress = segments.find(
    segment => segment.addressType === AddressType.ADDRESS_TYPE_SUB_DIVISION
  );
  if (subDivisionAddress && neighbourhoodAddress) {
    return `/${neighbourhoodAddress.slug?.replace(
      `-${subDivisionAddress.slug}`,
      ''
    )}`;
  }
  return undefined;
}

/**
 * Generates a URL path for a listing address by removing the sub-division slug from the listing address slug.
 *
 * For example, if the listing address slug is `290-highway-5-highway-brant-on` and the sub-division slug is `brant-on`, the path is `/290-highway-5-highway`.
 *
 * @param segments An array of `SingleAddress` objects representing the address hierarchy.
 * @returns A string representing the URL path for the listing address or undefined if the sub-division slug is not found.
 */
function getAddressPath(
  segments: readonly SingleAddress[]
): string | undefined {
  const subDivisionAddress = segments.find(
    segment => segment.addressType === AddressType.ADDRESS_TYPE_SUB_DIVISION
  );
  const listingAddress = segments.find(
    segment => segment.addressType === AddressType.ADDRESS_TYPE_ADDRESS
  );
  if (listingAddress && subDivisionAddress) {
    return `/${listingAddress.slug?.replace(`-${subDivisionAddress.slug}`, '')}`;
  }
  return undefined;
}
