import React, { useState } from 'react';
import AccessibilityIcon from 'components/icon/accessibility-icon';
import CloseIcon from 'components/icon/close-icon';
import styles from './style.module.scss';
import storage from 'utils/storage';
import { openEqualWebMenu } from 'utils/accessibility';

export default function AccessibilityWidget() {
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(true);


  const closeWidget = () => {
    setIsVisible(false);
    storage.set('accessibilityWidgetClosed', true);
  };

  const handleLeaveEvent = () => {
    setTimeout(() => setIsHovered(false), 1000);
  };

  if (!isVisible) return null;

  return (
    <div className={styles.component}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleLeaveEvent}>
      <AccessibilityIcon onClick={openEqualWebMenu} />
      {isHovered && (
        <CloseIcon className={styles['close-button']} onClick={closeWidget} />
      )}
    </div>
  );
}
