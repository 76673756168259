import type { ParsedUrlQuery } from 'querystring';

export const DEFAULT_PAGE_NUMBER = 1;

export default function extractPageFromUrl(urlQuery: ParsedUrlQuery) {
  let pageNumber = DEFAULT_PAGE_NUMBER;
  const page = urlQuery?.page;
  if (typeof page === 'string') {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(page)) {
      pageNumber = parseInt(page);
    }
  }
  return pageNumber;
}
