import { ThemeConfig } from './themeConfig';
import { SiteLanguage } from 'constants/locale';
import keywordsUS from './keywordsUS';

export const EXP_MENU_LINK_LABEL_SIGN_IN = 'Sign In' as const;
export const EXP_MENU_LINK_LABEL_MY_SEARCHES = 'My Searches' as const;

const ExprealtyUsTheme: ThemeConfig = {
  name: 'eXp Commercial Brokerage',
  title: 'Experts - eXp Commercial Brokerage',
  faviconPath: 'wp-content/uploads/2024/06/cropped-Single-X-Black-270x270-1-50x50.png',
  metaDescription: 'eXp Commercial are pioneers of the virtual commercial &amp; residential real estate industry, using a cloud-based model for agents globally.',
  largeDesktopHeaderImage: '/next/assets/images/header-image-large-desktop-exp-ca.jpg',
  desktopHeaderImage: '/next/assets/images/header-image-desktop-exp-ca.jpg',
  mobileHeaderImage: '/next/assets/images/header-image-mobile-exp-ca.jpg',
  smallMobileImage: '/next/assets/images/header-image-small-mobile-exp-ca.jpg',
  ogSiteName: 'Experts - eXp Commercial Brokerage',
  ogImage: 'https://expcommercial.com/wp-content/uploads/2024/07/exp-commercial-logo.png',
  ogImageAlt: 'Experts - eXp Commercial Brokerage',
  schemaUrl: 'https://expcommercial.com',
  schemaLogo: 'https://expcommercial.com/wp-content/uploads/2024/07/exp-commercial-logo.png',
  schemaLocation: {
    addressCountry: 'United States',
    addressLocality: '',
    addressRegion: '',
    postalCode: '',
    streetAddress: '',
  },
  schemaFoundingDate: '2002',
  schemaFounders: [
    {
      '@type': 'Person',
      name: 'Sanford Glenn Darrel',
    },
    {
      '@type': 'Person',
      name: 'Brian Culhane',
    },
  ],
  colors: {
    primaryVeryLight: '#8ECFFF',
    primaryLight:'#E8EDF5',
    primary: '#0C0F24',
    primaryDark: '#0C0F24',
    primaryVeryDark: '#0C0F24',
    primaryBackground: '#FFF',
    primaryForSale: '#FFF',
    primarySold: '#6D071A',
    primarySoldFocus: '#6D071A',
    primaryFilters: '#0C0F24',
    primaryFontColor: '#0C0F24',
    primaryLinkColor: '#1B489B',
    primaryFocusLinkColor: '#1B489B',
    primaryInvertedBackground: '#FFFFFF',
    primaryBorderColor: '#5A5A5A',
    primaryFocusColor: '#F3F4F4',
    primaryFocusBorderColor: '#0C0F24',
    primaryChart: '#F3F3F4',
    secondaryBackground: '#FFF',
    secondaryBorderColor: '#5A5A5A',
    secondaryLinkColor: '#3D3F50',
    secondaryHoverColor: '#0C0F24',
    secondaryFocusColor: '#3D3F50',
    secondaryFocusBorderColor: '#5A5A5A',
    tertiaryBackground: '#EFEFEF',
    tertiaryBackgroundLight: '#3D3F50',
    tertiaryBorderColor: '#5A5A5A',
    tertiaryLinkColor: '#1B489B',
    quaternaryLinkColor: '#496DAF',
    quaternaryBorderColor: '#5A5A5A',
    quaternaryBackgroundLight: '#FFFFFF',
    transparentBackground: 'rgba(255, 255, 255, 0.1)',
    mapPinForSale: '#1B489B',
    mapPinForSaleFocus: '#1B489B',
    mapControlBackground: '#FFF',
    mapControlBackgroundHover: '#F3F4F4',
    mapControlBackgroundActive: '#E7E7E9',
    carouselArrowHover: '#F3F4F4',
    carouselArrowFocus: '#CECFD3',
    notFound404PinHeadColor: '#1B489B',
    notFOund404PinBodyColor: '#496DAF',
  },
  fonts: {
    heading: 'Roboto, sans-serif',
    text: 'Roboto, sans-serif',
    headingWeight: '300',
    headingBoldWeight: '700',
  },
  decorations: {
    brushstrokes: false,
    boxShadow: '0px 2px 4px 0px #00000014',
    boxShadowLight: '0 0 8px 0 rgba(0, 0, 0, .1)',
    areaPageLineWidth: '51px',
  },
  buttons: {
    borderRadius: '8px',
    borderRadiusSmall: '8px',
    borderRadiusMedium: '8px',
    borderRadiusLarge: '100px',
    borderRadiusModal: '12px',
    borderRadiusBanner: '12px',
  },
  icons: {
    borderRadius: '100px',
  },
  style_overrides: {
    breadcrumbs: {
      mobileMarginTop: '1.5em',
      boxShadow: '0px 2px 4px 0px #00000014',
    },
    areaListingsPage: {
      pageTitleFontWeight: 'bold',
    },
    locationUnavailable: {
      imagePath: '/next/assets/images/listing-page/location-unavailable-exp.svg',
    },
  },
  organization: {
    phoneNumber() {
      return '';
    },
    email() {
      return 'info@exprealty.net';
    },
    address() {
      return '';
    },
    twitterUrl: 'https://x.com/exprealty',
    facebookUrl: 'https://www.facebook.com/profile.php?id=100065096766753',
    instagramUrl: 'https://www.instagram.com/exp.commercial/',
    linkedinUrl: 'https://www.linkedin.com/company/exp-commercial/',
    youtubeUrl: 'https://www.youtube.com/channel/UCZ6dRCbKE_EErGssbZhaW0A',
    joinSectionUrl: 'https://www.expcommercial.com/become-an-advisor',
  },
  menuLinks: [
    {
      label: 'Services',
      link: 'https://www.expcommercial.com/services',
    },
    {
      label: 'Find an Expert',
      link: 'https://www.expcommercial.com/experts',
    },
    {
      label: 'Listings',
      link: 'https://www.expcommercial.com/listings',
    },
    {
      label: 'Join eXp',
      link: 'https://www.expcommercial.com/become-an-advisor',
    },
    {
      label: 'About Us',
      link: 'https://www.expcommercial.com/about',
    },
    {
      label: 'Contact Us',
      link: 'https://www.expcommercial.com/contact-us',
    },
  ],
  footerContent: {
    column1: [
      {
        label: 'Services',
        link: 'https://www.expcommercial.com/services',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Find an Expert',
        link: 'https://www.expcommercial.com/experts',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Listings',
        link: 'https://www.expcommercial.com/listings',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Join eXp',
        link: 'https://www.expcommercial.com/become-an-advisor/',
        isAnchor: false,
        target: '_blank',
      },
      {
        label: 'Calendar',
        link: 'https://expcommercial.com/calendar/',
        isAnchor: false,
        target: '_blank',
      },
    ],
    column2: [
      {
        label: 'AboutUs',
        link: 'https://www.expcommercial.com/about',
        target: '_blank',
      },
      {
        label: 'Contact Us',
        link: 'https://www.expcommercial.com/contact-us',
        target: '_blank',
      },
      {
        label: 'Careers',
        link: 'https://expcommercial.com/contact-us',
        target: '_blank',
      },
      {
        label: 'eXp World Holdings',
        link: 'https://expcommercial.com',
        target: '_blank',
      },
    ],
    column3: [
      {
        label: 'Terms of Use',
        link: 'https://www.expcommercial.com/terms-of-service/',
        target: '_blank',
      },
      {
        label: 'Privacy Policy',
        link: 'https://www.expcommercial.com/privacy-policy/',
        target: '_blank',
      },
      {
        label: 'Cookie Policy',
        link: 'https://www.expcommercial.com/cookie-policy/',
        target: '_blank',
      },
      {
        label: 'DMCA Notice',
        link: 'https://www.expcommercial.com/dmca-notice/',
        target: '_blank',
      },
      {
        label: 'Privacy Rights Request',
        link: 'https://submit-irm.trustarc.com/services/validation/e6d7516d-df9c-4404-bd9f-21a2f0d2a6a4/',
        target: '_blank',
      },
    ],
    bottom: [
      [
        {
          label: 'Texas Real Estate Commission information about brokerage services',
          link: 'https://drive.google.com/file/d/1NMjX7L_BEHJWLQjUXnvf2Ivou4s8sTuA/view',
          isAnchor: true,
          target: '_blank',
        },
      ], [
        {
          label: 'Texas Real Estate Commission Consumer Protection Notice',
          link: 'https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-4-1_1.pdf',
          isAnchor: true,
          target: '_blank',
        },
      ], [
        {
          label: 'eXp Realty® is committed to adhering to the guidelines of The New York State Fair '
          + 'Housing Regulations. To view The Fair Housing Notice',
        },
        {
          label: ' →',
          link: 'https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf',
          target: '_blank',
        },
        {
          label: ' Please click here',
          link: 'https://dos.ny.gov/fair-housing',
          isAnchor: true,
          target: '_blank',
        },

      ], [
        {
          label: '*Standardized Operating Procedure for Purchasers of Real '
          + 'Estate Pursuant to Real Property Law 442-H. To View',
        },
        {
          label: ' →',
          link: 'https://drive.google.com/file/d/1o1iG6CO5hFgawNGJibmd34loBFJeIqqJ/view',
          target: '_blank',
        },
        {
          label: ' Please click here',
          link: 'https://drive.google.com/file/d/1o1iG6CO5hFgawNGJibmd34loBFJeIqqJ/view',
          isAnchor: true,
          target: '_blank',
        },
      ], [
        {
          label: 'eXp Realty of California, Inc. | CA DRE# 01878277 | Deborah Penny – Designated Broker',
        },
      ], [
        {
          label: 'eXp Realty of Southern California, Inc. | CA DRE# 02187306 | Gina Saad – Designated Broker',
        },
      ], [
        {
          label: 'eXp Realty of Greater Los Angeles, Inc. | CA DRE# 01828532 | Justin DeCesare – Designated Broker',
        },
      ], [
        {
          label: 'eXp Realty of Northern California, Inc. | CA DRE# 01951343 | Ryan Rosas – Designated Broker',
        },
      ], [
        {
          label: 'This site is protected by reCAPTCHA and the Google ',
        }, {
          label: ' Privacy Policy ',
          link: 'https://policies.google.com/privacy',
          target: '_blank',
          isAnchor: true,
        }, {
          label: 'and ',
        }, {
          label: ' Terms of Service ',
          link: 'https://policies.google.com/terms',
          target: '_blank',
          isAnchor: true,
        }, {
          label: 'apply.',
        },
      ],
    ],
    joinUsLink: 'https://www.expcommercial.com/become-an-advisor',
    footerImageData: {
      src: '',
      alt: '',
    },
  },
  searchOptions: [
  ],
  contentBlockBuy: {
    title: 'Buying your next home',
    copy: 'eXp Realty transforms home buying into a seamless experience, tailored to your needs with cutting-edge technology and expert support. Discover a better way to buy a home with eXp.',
    image: '/next/assets/images/content-block/exp-buy.jpg',
    link: '/buy',
  },
  contentBlockSell: {
    title: 'Selling your home',
    copy: 'Discover a smarter selling experience with eXp Realty with a simplified process that blends advanced technology and market insight to sell your home effectively and efficiently.',
    image: '/next/assets/images/content-block/exp-sell.jpg',
    link: '/sell',
  },
  privacyPolicy: (language?: SiteLanguage) => {
    switch (language) {
    case SiteLanguage.French:
      return 'https://expworldholdings.com/privacy-policy-french/';
    default:
      return 'https://expworldholdings.com/privacy-policy/';
    }
  },
  termsOfUse: (language?: SiteLanguage) => {
    switch (language) {
    case SiteLanguage.French:
      return 'https://expworldholdings.com/terms-of-service-french/';
    default:
      return 'https://expworldholdings.com/terms-of-service/';
    }
  },
  keywords: keywordsUS,
};

export default ExprealtyUsTheme;
