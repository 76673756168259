import endpoint, { ErrorResponse, isErrorResponse } from 'utils/endpoint';
import Address, { AddressInfo, AddressPayload } from './types';
import { HttpRequestMethodTypes } from 'types';
import { getClientAppHost, isServerSide } from 'utils/host-config';

const PRE_US_ADDRESS_ID = '5940087';
const US_ADDRESS_ID = '6120961';

type AddressesApiResponse = AddressPayload | ErrorResponse;

/**
 * Given a slug, fetch the address hierarchy from the client-app addresses endpoint.
 *
 * @param slug The slug of the address to fetch.
 * @param abortSignal An optional AbortSignal to cancel the request.
 * @param host The host to use for the request. Defaults to the ClientApp host.
 *
 * @returns A promise that resolves to the address hierarchy.
 *
 * @deprecated Use `go-search/getAddressesBySlug` instead.
 */
export async function getAddressesBySlug(slug: string, abortSignal?: AbortSignal, host: string = getClientAppHost(isServerSide())) {
  try {
    const url = `/services/api/v3/addresses/${slug}`;
    const payload = await endpoint<AddressesApiResponse>(url, undefined, undefined, undefined, abortSignal, host);
    if (isErrorResponse(payload)) {
      return [];
    }

    const addresses = [new Address(payload.data as unknown as Record<string, unknown>)];
    if (payload.included && payload.included?.length > 0) {
      for (let index = 0; index < payload.included.length; index++) {
        const addressData = payload.included[index];
        if (addressData?.id !== US_ADDRESS_ID && addressData?.id !== PRE_US_ADDRESS_ID) {
          addresses.push(new Address(addressData as unknown as Record<string, unknown>));
        }
      }
    }
    return addresses;
  } catch (error){
    console.error(error);
    return [];
  }
}

/**
 * Given a set of filter parameters, fetch the addresses from the client-app addresses endpoint.
 *
 * @param params The parameters to use for the request.
 * @param host The host to use for the request. Uses {@link getClientAppHost} as default.
 *
 * @returns A promise that resolves to the addresses.
 */
export async function getAddressesByParams(params: Record<string, unknown>, host: string = getClientAppHost(isServerSide())) {
  try {
    const payload = await endpoint<{ data: AddressInfo[] } | ErrorResponse>(`${host}/services/api/v3/addresses`, HttpRequestMethodTypes.GET, params);
    if (isErrorResponse(payload)) {
      return [];
    }

    return payload.data.map(i => new Address(i as unknown as Record<string, unknown>));
  } catch (error){
    console.error(error);
    return [];
  }
}