import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useChat } from 'contexts/chat';

import type { ConnectionRequestOptions } from 'components/modal-dialog/connection-request';
import type { ConnectionRequestOptions as ConnectionRequestPopupOptions } from 'components/modal-dialog/connection-request-popup';
import type { ListingOptions } from 'components/modal-dialog/listing';
import type { NotificationOptions } from 'components/modal-dialog/notification';
import type { CreateSavedSearchOptions } from 'components/modal-dialog/create-saved-search';
import type { UserConfirmAccountOptions } from 'components/modal-dialog/user/confirm-account';
import type { UserResetPasswordOptions } from 'components/modal-dialog/user/reset-password';
import type { UserRegisterOptions, UserSignInOptions } from 'components/modal-dialog/user/login-registration';
import type { UserConsentOptions } from 'components/modal-dialog/user/phone-number-and-email';
import type { UserForgotPasswordOptions } from 'components/modal-dialog/user/forgot-password';
import type { LightboxOptions } from 'components/modal-dialog/lightbox';
import type { DeleteOptions } from 'components/modal-dialog/delete-prompt';
import type { SavedSearchFiltersOptions } from 'components/modal-dialog/saved-search-filters/saved-search-filters';
import type { PhotoGalleryOptions } from 'components/modal-dialog/photo-gallery';
import type { ConnectWithAgentOptions } from 'components/modal-dialog/connect-with-an-agent';
import type { PreConPhotoGalleryOptions } from 'components/modal-dialog/precon-photo-gallery';
import type { PreconRequestOptions } from 'components/modal-dialog/precon-request';
import type { TermsOfUseOptions } from 'components/modal-dialog/terms-of-use';
import type { UserPrivacyTermsOptions } from 'components/modal-dialog/user/privacy-terms';
import type { ActivateAccountOptions } from 'components/modal-dialog/user/activate-account';
import type { QuebecPopupOptions } from 'components/modal-dialog/quebec-popup';
import type { SSOAuthProperties } from 'components/google-button';
import type { HomeAppraisalConnectionRequestOptions } from 'components/modal-dialog/home-appraisal-connection-request-popup';
import type { MyLinkMyLeadCreateOptions } from 'components/modal-dialog/my-link-my-lead-create';

//#region Modal Names
export const LOGIN_REGISTRATION_MODAL = 'login-registration';
export const LISTING_MODAL = 'listing';
export const TERMS_OF_USE_MODAL = 'terms-of-use';
export const SAVED_SEARCH_FILTERS_MODAL = 'saved-search-filters';
export const CREATE_SAVED_SEARCH_MODAL = 'create-saved-search';
export const MY_LINK_MY_LEAD_CREATE_MODAL = 'my-link-my-lead';
export const NOTIFICATION_MODAL = 'notification';
export const QUEBEC_POPUP_MODAL = 'quebec-popup';
export const CONNECTION_REQUEST_MOBILE_MODAL = 'connection-request';
export const CONNECTION_REQUEST_POPUP_MODAL = 'connection-request-popup';
export const ACTIVATE_ACCOUNT_MODAL = 'activate-account';
export const CONFIRM_ACCOUNT_MODAL = 'confirm-account';
export const FORGOT_PASSWORD_MODAL = 'forgot-password';
export const RESET_PASSWORD_MODAL = 'reset-password';
export const CHANGE_PASSWORD_MODAL = 'change-password';
export const CREATE_PASSWORD_MODAL = 'create-password';
export const CHANGE_EMAIL_MODAL = 'change-email';
export const PHONE_NUMBER_MODAL = 'phone-number';
export const PHONE_NUMBER_AND_EMAIL_MODAL = 'phone-number-and-email';
export const NEWSLETTER_MODAL = 'newsletter';
export const PRIVACY_TERMS_MODAL = 'privacy-terms';
export const LIGHTBOX_MODAL = 'lightbox';
export const DELETE_PROMPT_MODAL = 'delete-prompt';
export const WELCOME_MODAL = 'welcome';
export const SCHOOL_FILTERS_MODAL = 'school-filters';
export const PHOTO_GALLERY_MODAL = 'photo-gallery';
export const CONNECT_WITH_AGENT_MODAL = 'connect-with-agent';
export const PRECON_PHOTO_GALLERY_MODAL = 'precon-photo-gallery';
export const PRECON_SUCCESS_NOTIFICATION_MODAL = 'precon-success-notification';
export const PRECON_REQUEST_MODAL = 'precon-request';
export const HOME_APPRAISAL_CONNECTION_REQUEST_POPUP_MODAL = 'home-appraisal-connection-request-popup';
export const HOME_APPRAISAL_CONNECTION_REQUEST_MODAL = 'home-appraisal-connection-request';
//#endregion

//#region Modal Types
export type ModalName = typeof CONNECTION_REQUEST_MOBILE_MODAL
  | typeof CONNECTION_REQUEST_POPUP_MODAL
  | typeof TERMS_OF_USE_MODAL
  | typeof LISTING_MODAL
  | typeof NOTIFICATION_MODAL
  | typeof CREATE_SAVED_SEARCH_MODAL
  | typeof ACTIVATE_ACCOUNT_MODAL
  | typeof CONFIRM_ACCOUNT_MODAL
  | typeof FORGOT_PASSWORD_MODAL
  | typeof RESET_PASSWORD_MODAL
  | typeof CHANGE_PASSWORD_MODAL
  | typeof CREATE_PASSWORD_MODAL
  | typeof CHANGE_EMAIL_MODAL
  | typeof LOGIN_REGISTRATION_MODAL
  | typeof PHONE_NUMBER_MODAL
  | typeof PHONE_NUMBER_AND_EMAIL_MODAL
  | typeof NEWSLETTER_MODAL
  | typeof PRIVACY_TERMS_MODAL
  | typeof LIGHTBOX_MODAL
  | typeof DELETE_PROMPT_MODAL
  | typeof SAVED_SEARCH_FILTERS_MODAL
  | typeof MY_LINK_MY_LEAD_CREATE_MODAL
  | typeof WELCOME_MODAL
  | typeof SCHOOL_FILTERS_MODAL
  | typeof PHOTO_GALLERY_MODAL
  | typeof CONNECT_WITH_AGENT_MODAL
  | typeof PRECON_PHOTO_GALLERY_MODAL
  | typeof PRECON_SUCCESS_NOTIFICATION_MODAL
  | typeof PRECON_REQUEST_MODAL
  | typeof QUEBEC_POPUP_MODAL
  | typeof HOME_APPRAISAL_CONNECTION_REQUEST_POPUP_MODAL
  | typeof HOME_APPRAISAL_CONNECTION_REQUEST_MODAL;

export type ModalOptions = ConnectionRequestOptions
  | ConnectionRequestPopupOptions
  | MyLinkMyLeadCreateOptions
  | ListingOptions
  | NotificationOptions
  | CreateSavedSearchOptions
  | UserConfirmAccountOptions
  | UserResetPasswordOptions
  | UserSignInOptions
  | UserRegisterOptions
  | UserForgotPasswordOptions
  | UserPrivacyTermsOptions
  | ActivateAccountOptions
  | LightboxOptions
  | DeleteOptions
  | SavedSearchFiltersOptions
  | PhotoGalleryOptions
  | ConnectWithAgentOptions
  | PreConPhotoGalleryOptions
  | PreconRequestOptions
  | SSOAuthProperties
  | UserConsentOptions
  | TermsOfUseOptions
  | QuebecPopupOptions
  | HomeAppraisalConnectionRequestOptions;

export type OpenModalEventHandler = (name: ModalName, options?: ModalOptions) => void;
export type CloseModalEventHandler = () => void;
export type CloseModalByNameEventHandler = (name: ModalName) => void;
export type CloseAllModalsEventHandler = () => void;
export type ChangeUrlOnModalEventHandler = (customUrl: string) => void;

export type IModalContext = {
  modals: ModalContentProps[];
  activeModal: ModalName | null;
  openModal: OpenModalEventHandler;
  closeModal: CloseModalEventHandler;
  closeModalByName: CloseModalByNameEventHandler;
  closeAllModals: CloseAllModalsEventHandler;
  changeUrlOnModal: ChangeUrlOnModalEventHandler;
  isCrawler: boolean;
};
//#endregion

//#region Modal Context
export const ModalContext = createContext<IModalContext | Record<string, unknown>>({});

export function useModalContext <T extends IModalContext | Record<string, unknown> = IModalContext>() {
  return useContext(ModalContext) as T;
}

type ModalContextProviderProps = {
  children: ReactNode;
  isCrawler: boolean;
}

export type ModalProps<T extends ModalOptions = ModalOptions> = ModalContentProps<T> & Partial<ModalActionsProps> & Partial<ModalStateProps>;

export type ModalContentProps<T extends ModalOptions = ModalOptions> = {
  name: ModalName;
  options: T;
}

export type ModalActionsProps = {
  openModal: OpenModalEventHandler;
  closeModal: CloseModalEventHandler;
  closeModalByName: CloseModalByNameEventHandler;
  closeAllModals: CloseAllModalsEventHandler;
  changeUrlOnModal: ChangeUrlOnModalEventHandler;
};

export type ModalStateProps = {
  isActiveModal: boolean;
  isCrawler: boolean;
};

export default function ModalContextProvider({ children, isCrawler }: ModalContextProviderProps) {
  const [modals, setModals] = useState<IModalContext['modals']>([]);
  const { setPageContent } = useChat();
  const activeModal: ModalName | null = modals.length ? modals[modals.length - 1].name : null;

  const openModal: OpenModalEventHandler = useCallback((name, options = {}) => {
    setModals(modals => [...modals, { name, options }]);
  }, []);

  const changeUrlOnModal: ChangeUrlOnModalEventHandler = useCallback(url => {
    window.history.pushState({ ...window.history.state }, '', url);
  }, []);

  const closeModal: CloseModalEventHandler = useCallback(() => {
    activeModal === 'listing' && setPageContent(null);
    setModals(modals => modals.filter(modal => modal !== modals[modals.length - 1]));
  }, [activeModal, setPageContent]);

  const closeModalByName: CloseModalByNameEventHandler = useCallback((name: ModalName) => {
    setModals(modals => modals.filter(modal => modal.name !== name));
  }, []);

  const closeAllModals: CloseAllModalsEventHandler = useCallback(() => {
    setPageContent(null);
    setModals([]);
  }, [setPageContent]);

  const onPopState = useCallback(() => {
    closeAllModals();
  }, [closeAllModals]);

  useEffect(() => {
    window.addEventListener('popstate', onPopState, { passive: true });
    return () => window.removeEventListener('popstate', onPopState);
  }, [onPopState]);

  const contextValue = useMemo(() => ({
    modals,
    activeModal,
    openModal,
    closeModal,
    closeModalByName,
    closeAllModals,
    changeUrlOnModal,
    isCrawler,
  }), [
    modals,
    activeModal,
    openModal,
    closeModal,
    closeModalByName,
    closeAllModals,
    changeUrlOnModal,
    isCrawler,
  ]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
}

function useModal(): IModalContext {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContextProvider');
  }
  return context as Required<IModalContext>;
}

export { useModal };