import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  testId?: string;
}

const CloseIcon = ({ className, onClick, testId }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" className={className} onClick={onClick} data-testid={testId}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M17.057 15.36L30.369 2.049A1.2 1.2 0 1028.67.35L15.36 13.663 2.049.351A1.2 1.2 0 10.35 2.05L13.663 15.36.351 28.671A1.2 1.2 0 102.05 30.37L15.36 17.057l13.311 13.312a1.2 1.2 0 101.698-1.698L17.057 15.36z"></path>
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
