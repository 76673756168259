import { SingleAddress } from '@zoocasa/go-search';
import { getGoSearchHost, isServerSide } from 'utils/host-config';
import { getAddressesBySlug } from './fetcher';

export type AddressesApiType = {
    /**
     * Given a slug, fetch the address hierarchy from the go-search addresses endpoint.
     *
     * The address hierarchy consists of an array of SingleAddress objects representing the address structure sorted
     * by the address type as follows:
     *
     * 1. country
     * 2. province
     * 3. sub-division
     * 4. neighbourhood
     * 5. multi-unit
     * 6. address
     *
     * @param slug The slug of the address to fetch.
     * @param signal An optional AbortSignal to cancel the request.
     * @param host The host to use for the request. Defaults to the GoSearch host.
     * @returns A promise that resolves to the SingleAddress array. Returns an empty array
     *  if the slug is not provided or is empty and in case of an error.
     */
    getAddressesBySlug(slug: string, signal?: AbortSignal): Promise<SingleAddress[]>
}

class AddressesApi implements AddressesApiType {
  private constructor(private readonly host: string) { }

  /** Creates a new AddressesApi instance with the given host. */
  static create(host: string = getGoSearchHost(isServerSide())): AddressesApi {
    return new AddressesApi(host);
  }

  getAddressesBySlug(slug: string, signal?: AbortSignal): Promise<SingleAddress[]> {
    return getAddressesBySlug(slug, signal, this.host);
  }

}

/** Creates a new AddressesApi instance with the given host. */
export const createAddressesApi = AddressesApi.create;