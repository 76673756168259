export enum SiteLanguage {
  English = 'en',
  French = 'fr'
}

export enum SiteNameSpace {
  Common = 'common',
  Modal = 'modal',
  ExpInService = 'expInService',
  FormError = 'formError',
}

export const COMMON_BUTTON_KEY_LABEL = {
  CONTINUE: 'common:button.continue',
  SUBMITTING: 'common:button.submitting',
  DONE: 'common:button.done',
};