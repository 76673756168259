export const Colors = {
  // Orange
  orange: '#DF6541',
  orangeDark: '#CC5E3D',

  // Sand
  sandVeryLight: '#D8C19E',
  sandLight: '#C99E5E',
  sand: '#B38E57',
  sandDark: '#A4814F',

  // Beige
  beige: '#FCF9F1',

  // Blue
  blue: '#4696C4',
  blue10: 'rgba(70, 150, 196, 0.1)',
  blueLight: '#EDF3F6',
  blueDark: '#31587F',
  darkNavyBg: '#F3F4F4',
  blueUtility: '#1369D2',
  darkNavyLight: '#6D6F7C',
  darkNavy20: '#CECFD3',
  darkNavy80: '#3D3F50',
  darkNavy: '#0C0F24',
  darkNavy10: '#E8EDF5',

  // Blue Grey
  blueGrey1: '#EEEFF1',
  blueGrey2: '#858998',
  blueGrey23: '#636670',
  blueGrey3: '#5A5C65',
  blueGrey4: '#2F323B',
  blueGrey5: '#1D1F28',

  // Red
  red: '#C6483A',
  redLight: '#FCF1F1',

  // Yellow
  yellow: '#DF9628',

  // Green
  green: '#84A338',
  greenBright: '#0FC282',

  // White
  white: '#FFF',
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white80: 'rgba(255, 255, 255, 0.8)',

  // Black
  black: '#000',
  black05: 'rgba(0, 0, 0, 0.05)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black35: 'rgba(0, 0, 0, 0.35)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black65: 'rgba(0, 0, 0, 0.65)',
  black75: 'rgba(0, 0, 0, 0.75)',
  black80: 'rgba(0, 0, 0, 0.8)',
  black90: 'rgba(0, 0, 0, 0.9)',
  warmBlack: '#191B1E',
  warmBlack5: '#F3F4F4',
  warmBlack10: '#E8E8E8',
  warmBlack20: '#D1D1D2',
  warmBlack60: '#757678',
  warmBlack80: '#47494B',

  // Grey
  grey1: '#EFEFEF',
  grey2: '#ECECEC',
  grey3: '#DEDEDE',
  grey4: '#DBD7D6',
  grey5: '#B6B5B5',
  grey6: '#191B1E',
  grey7: '#F6F4F4',
  grey8: '#393939',
  grey9: '#46484A',
  grey10: '#181B1E',
  grey11: '#FCFCFC',
  grey12: '#9F9F9F',
  grey13: '#F3F3F3',
  grey14: '#CDCDCD',
  grey15: '#F9F9F9',
  grey16: '#E7E7E9',
  grey17: '#47494B',
  grey18: '#6D6F7C',
  grey19: '#F6F6F5',
  grey20: '#9E9FA7',
  grayishBlue: '#D1D1D1',
  greyLight: 'rgba(255, 255, 255, 0.1)',
  greyLight2: 'rgba(255, 255, 255, 0.2)',
  greySuperLight: 'rgba(243, 243, 244, 0.50)',

  // Silver
  modernSilver: '#5A5A5A',
  modernSilverLight: '#8E9195',

  // Transparent
  transparent: 'rgba(255, 255, 255, 0)',

  // Exp Theme Colors
  expPrimaryBrandRoyal: '#1B489B',
  expPrimaryBrandRoyal80: '#496DAF',
  expSecondaryDarkNavy: '#0C0F24',
  expSecondaryDarkNavy5: '#F3F3F4',
  expSecondaryDarkNavy80: '#3D3F50',
  expMaroon: '#6D071A',

  // Specific Use Cases
  mapBackground: '#F8F5ED',
  visitedMapPin: '#757678',
  boxShadow: '0 1px 10px rgba(0, 0, 0, 0.04), 0 4px 5px rgba(0, 0, 0, 0.06), 0 2px 4px -1px rgba(0, 0, 0, 0.09)',
  snackbarBg: '#E5EFEB',
  snackbarBgError: '#F1DADA',
  snackbarText: '#0D4D36',
  snackbarTextError: '#4D0D0D',
  calloutBackground: 'rgba(247, 247, 247, 0.749)',
  arrowBoxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.18)',
  listingDetailLabel: '#858791',
  expKeywordsBg: '#E9EDF2',
  commercialFooterBg: '#1C1E2A',

  // Gradients
  modernSilverGradient: 'linear-gradient(270deg, #5A5A5A -125.65%, #8E9195 89.64%)',
} as const;

// Type for the Colors object
export type ColorType = typeof Colors;

// Type for color keys
export type ColorKey = keyof typeof Colors;