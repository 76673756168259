import { getObjectFromRequestCookie } from './request-cookies';
import { POPULAR_CITIES_COOKIE } from 'constants/cookies';
import { IncomingMessage } from 'http';
import { ROOT_PATH_SUFFIX } from 'data/addresses/go-search/types';

import type { SearchSuggestions } from 'components/suggested-location-dropdown';

/**
 * Returns the popular cities cookie if the user location and the cities cookie
 * belong to the same location.
 *
 * @param req The request object
 * @param userLocationSlug The slug of the location to check
 * @returns The popular cities cookie if the user location and the cities
 *  cookie belong to the same location, otherwise undefined
 */
export default function getPopularCitiesCookie(req?: IncomingMessage, userLocationSlug?: string) {
  if (userLocationSlug && userLocationSlug.trim().length > 0) {
    const cities = getObjectFromRequestCookie<SearchSuggestions[]>(POPULAR_CITIES_COOKIE, req);

    try {
      const expectedCitySlug = `${userLocationSlug.trim().slice(-2)}-${ROOT_PATH_SUFFIX}`;
      const isInUserLocation = cities?.[0]?.urlPath?.endsWith(expectedCitySlug);
      return isInUserLocation ? cities : undefined;
    } catch (e) {
      console.error(e);
    }
  }

  return undefined;
}
