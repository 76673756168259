import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { useUserContext } from './user';
import { useThemeContext } from 'contexts';
import endpoint, { ErrorResponse } from 'utils/endpoint';
import { trackEvent } from 'utils/google-tag-manager';
import { useRouter } from 'next/router';
import { AREA_LISTINGS_ROUTE, getRouteNameFromPath, LISTING_ROUTE } from 'components/dynamic-page/route-matchers';
import {
  GTM_CLICK_ADDRESS_PAGE_FAVOURITE_ICON,
  GTM_CLICK_FAV_ON_AREA_PAGE,
  GTM_CLICK_FAV_ON_MAP,
  GTM_CLICK_FAV_ON_MODAL,
} from '../constants/events';
import Listing from 'data/listing';

import type { SavedListingsParams, Meta } from 'utils/types';
import { withTryAsync } from 'utils/withTry';

export interface ISavedListingContext {
  savedListingIDs: number[];
  addSavedListing: (listingId: number, isModal?: boolean) => void;
  removeSavedListing: (listingId: number) => void;
  isSavedListing: (listingId: number) => boolean;
  getSavedListings: (params: SavedListingsParams) => Promise<{ listings: Listing[]; meta: Meta }>;
}

export const SavedListingContext = createContext<ISavedListingContext | Record<string, unknown>>({});

export function useSavedListingContext <T extends ISavedListingContext | Record<string, unknown> = ISavedListingContext>() {
  return useContext(SavedListingContext) as T;
}

interface Props {
  children: ReactNode;
}

type SavedListingsResponse = {
  data: {
    id: string;
    type: 'saved-listings';
    relationships: {
      listing: {
        data: {
          id: string;
          type: 'listings';
        };
      };
    };
  }[];
  included: {
    attributes: Record<string, unknown>;
    id: string;
    type: 'listings';
  }[];
  meta: Meta;
};

export default function SavedListingContextProvider({ children }: Props) {
  const { isAuthenticated } = useUserContext();
  const { themeName } = useThemeContext();
  const [savedListingIDs, setSavedListings] = useState<ISavedListingContext['savedListingIDs']>([]);
  const { asPath } = useRouter();

  const savedListingIDMap = useMemo(() => {
    return new Map(savedListingIDs.map(id => [id, true]));
  }, [savedListingIDs]);

  useEffect(() => {
    if (isAuthenticated) {
      syncSavedListing();
    } else {
      !!savedListingIDs.length && setSavedListings([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const syncSavedListing = async () => {
    const [response, error] = await withTryAsync<{ active: number[]; inactive: number[] }, ErrorResponse>(endpoint)('/services/api/v3/saved-listings/listing-ids', 'GET', { source: themeName.toString() });
    if (error) {
      console.error('failed to sync saved listings %s', JSON.stringify(error));
    } else {
      const activeListings = response?.active || [];
      const inactiveListings = response?.inactive || [];
      setSavedListings([...activeListings, ...inactiveListings]);
    }
  };

  const addSavedListing: ISavedListingContext['addSavedListing'] = async (listingId, isModal) => {
    const routeName = getRouteNameFromPath(asPath);
    const isOnAreaPage = routeName === AREA_LISTINGS_ROUTE;
    const isOnAddressPage = routeName === LISTING_ROUTE;
    const isOnMapPage = asPath === '/search';

    if (isModal) {
      trackEvent(GTM_CLICK_FAV_ON_MODAL);
    } else if (isOnAreaPage) {
      trackEvent(GTM_CLICK_FAV_ON_AREA_PAGE);
    } else if (isOnAddressPage) {
      trackEvent(GTM_CLICK_ADDRESS_PAGE_FAVOURITE_ICON);
    } else if (isOnMapPage) {
      trackEvent(GTM_CLICK_FAV_ON_MAP);
    }
    const [, error] = await withTryAsync<{ data: Record<string, unknown> }, ErrorResponse>(endpoint)('/services/api/v3/saved-listings', 'POST', { listingId, source: themeName });
    if (error) {
      console.error('failed to add saved listing %s', JSON.stringify(error));
    } else {
      setSavedListings(prev => [...prev, listingId]);
    }
  };

  const removeSavedListing: ISavedListingContext['removeSavedListing'] = async listingId => {
    const [, error] = await withTryAsync<{ data: Record<string, unknown> }, ErrorResponse>(endpoint)('/services/api/v3/saved-listings', 'DELETE', { listingId });
    if (error) {
      console.error('failed to remove saved listing %s', JSON.stringify(error));
    } else {
      setSavedListings(prev => prev.filter(id => id !== listingId));
    }
  };

  const getSavedListings = async (params: SavedListingsParams) => {
    // Add source so only listings from the current theme are fetched
    const paramsWithSource = {
      ...params,
      filter: {
        ...params.filter,
        source: themeName,
      },
    };

    const [response, error] = await withTryAsync<SavedListingsResponse, ErrorResponse>(endpoint)('/services/api/v3/saved-listings', 'GET', paramsWithSource);
    if (error) {
      console.error('failed to get saved listings %s', JSON.stringify(error));
      return { listings: [], meta: error.meta };
    } else {
      const { data, included, meta } = response;
      const normalizedPayload: Listing[] = [];
      data.forEach(savedListing => {
        const listingPayload = included.find(({ id }: { id: string }) => id === savedListing.relationships.listing?.data.id);
        const data = new Listing({ ...listingPayload, attributes: { ...listingPayload?.attributes, id: savedListing.relationships.listing?.data.id }});
        normalizedPayload.push(data);
      });
      return { listings: normalizedPayload, meta: meta };
    }
  };
  const isSavedListing = savedListingIDMap.has.bind(savedListingIDMap);

  return (
    <SavedListingContext.Provider value={{ savedListingIDs, addSavedListing, removeSavedListing, getSavedListings, isSavedListing }}>
      {children}
    </SavedListingContext.Provider>
  );
}
