import Cookies from 'js-cookie';
import { QUEBEC_POPUP } from 'constants/cookies';
import { ThemeNames } from 'types/themes';
import { CountryCodeList } from 'types/countries';
import { QUEBEC_POPUP_MODAL } from 'contexts/modal';

import type { OpenModalEventHandler } from 'contexts';
import type { ThemeName } from 'themes';
import type { IUserLocation } from 'utils/user-location';
import type { QuebecPopupSource } from 'components/modal-dialog/quebec-popup';

type Props = {
  themeName: ThemeName;
  isQuebec: boolean;
  openModal: OpenModalEventHandler;
  source: QuebecPopupSource;
}

export function handleQuebecPopup({ themeName, isQuebec, openModal, source }: Props) {
  // Prevent Quebec popup on Join eXp pages
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

  // Check if on join subdomain or specific join paths
  const isJoinSubdomain = currentUrl.includes('join.');
  const isJoinPath = pathname.includes('/join-exp-ca') || pathname.includes('/join-exp-us');

  // Skip popup for join subdomain and join paths
  if (isJoinSubdomain || isJoinPath) {
    return;
  }

  // Original condition for other pages
  if (themeName !== ThemeNames.ZOOCASA && !Cookies.get(QUEBEC_POPUP) && isQuebec) {
    setTimeout(() => {
      document.body.click(); // Loads modal-dialog component in background (see _app)
      openModal(QUEBEC_POPUP_MODAL, { source });
    }, 300);
  }
}

export const IsUserInQuebec = (userLocation: IUserLocation | undefined) =>
  userLocation?.countryCode === CountryCodeList.CANADA && userLocation.slug.slice(-2) === 'qc';
