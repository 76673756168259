import { 
  GetAddressRequest,
  GetAddressResponse,
  SingleAddress,
} from '@zoocasa/go-search';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { FetchOptions } from 'types/fetchWithRetry';
import { HttpRequestMethodTypes } from 'types';
import { getGoSearchHost, isServerSide } from 'utils/host-config';

/**
 * Given a slug, fetch the address hierarchy from the go-search addresses endpoint.
 * 
 * The address hierarchy consists of an array of SingleAddress objects representing the address structure sorted
 * by the address type as follows:
 * 
 * 1. country
 * 2. province
 * 3. sub-division
 * 4. neighbourhood
 * 5. multi-unit
 * 6. address
 * 
 * @param slug The slug of the address to fetch.
 * @param signal An optional AbortSignal to cancel the request.
 * @param host The host to use for the request. Defaults to the GoSearch host.
 * @returns A promise that resolves to the SingleAddress array. Returns an empty array
 *  if the slug is not provided or is empty and in case of an error.
 */
export async function getAddressesBySlug(slug: string, signal?: AbortSignal, host: string = getGoSearchHost(isServerSide())) {
  const addressesEndpointUrl = `${host}/api/address`;
  let addresses: SingleAddress[];

  if (!slug || slug.trim().length === 0) {
    return [];
  }

  try {
    const request = GetAddressRequest.fromPartial({ slug });
    const requestBody = GetAddressRequest.encode(request).finish();
    const options: FetchOptions = { method: HttpRequestMethodTypes.POST, body: requestBody, signal };
    const response = await fetchWithRetry(addressesEndpointUrl, options);

    if (response.ok) {
      const content = await response.blob(); 
      const buffer = await content.arrayBuffer();
      const apiResp = GetAddressResponse.decode(new Uint8Array(buffer));
      return apiResp.data;
    } else {
      console.error(`fetch addresses: ${response.status} ${response.statusText} ${response.type}`);
      addresses = [];
    }
  } catch (error: any) {
    if (error?.name === 'AbortError') {
      console.debug('fetch addresses request was aborted');
    } else {
      console.error('failed to fetch addresses: %s', error);
    }
    addresses = [];
  }

  return addresses;
}