import { camelizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { provinceOrStateNameFromCode, type ProvinceOrStateCode } from 'utils/province_or_state';
import { generateBreadcrumbLink } from './utils';

import type { SingleAddress, AddressType } from '@zoocasa/go-search';

/** @deprecated use {@link AddressType} instead */
export const COUNTRY_ADDRESS_TYPE = 'country';
/** @deprecated use {@link AddressType} instead */
export const PROVINCE_ADDRESS_TYPE = 'province';
/** @deprecated use {@link AddressType} instead */
export const SUB_DIVISION_ADDRESS_TYPE = 'sub-division';
/** @deprecated use {@link AddressType} instead */
export const PLACE_NAME_ADDRESS_TYPE = 'place-name';
/** @deprecated use {@link AddressType} instead */
export const NEIGHBOURHOOD_ADDRESS_TYPE = 'neighbourhood';
/** @deprecated use {@link AddressType} instead */
export const ADDRESS_ADDRESS_TYPE = 'address';

/** @deprecated use {@link AddressType} instead */
export type AddressCategory = typeof COUNTRY_ADDRESS_TYPE | typeof PROVINCE_ADDRESS_TYPE | typeof SUB_DIVISION_ADDRESS_TYPE | typeof PLACE_NAME_ADDRESS_TYPE | typeof NEIGHBOURHOOD_ADDRESS_TYPE | typeof ADDRESS_ADDRESS_TYPE;

/** @deprecated use {@link SingleAddress} instead */
export type AddressAttributes = {
  id: number;
  addressType: AddressCategory;
  country: string;
  name: string | null;
  neighbourhood: string | null;
  number: number | null;
  postalCode: string | null;
  province: string;
  slug: string;
  subDivision: string | null;
  unit: string | null;
  provinceCode: ProvinceOrStateCode;
};

export interface AddressInfo {
  id: string;
  type: 'addresses';
  attributes: Record<string, unknown>;
  relationships: {
    id: string;
    type: 'addresses';
  }[];
}

export interface AddressPayload {
  data: AddressInfo;
  included: AddressInfo[];
}

/** use {@link SingleAddress} instead */
export default class Address implements AddressAttributes {
  id: number;
  addressType: AddressCategory;
  country: string;
  name: string | null;
  neighbourhood: string | null;
  number: number | null;
  postalCode: string | null;
  province: string;
  slug: string;
  subDivision: string | null;
  unit: string | null;
  
  constructor(address: Record<string, unknown>) {
    const camelizedAddress = camelizeKeys(address);
    const attributes = camelizedAddress.attributes as Record<string, unknown>;
    const relationships = camelizedAddress.relationships as Record<
        string,
        unknown
      >;
    const formattedAddress = {
      ...attributes,
      ...relationships,
      id: Number(camelizedAddress.id),
    } as Address;
    Object.assign(this, formattedAddress);
  }
  
  get provinceCode() {
    return this.province.toLowerCase() as ProvinceOrStateCode;
  }
  
  get label() {
    const addressType = this.addressType;
    let label = '';
    if (addressType === PROVINCE_ADDRESS_TYPE) {
      label = provinceOrStateNameFromCode(this.provinceCode);
    } else if (addressType === SUB_DIVISION_ADDRESS_TYPE || addressType === PLACE_NAME_ADDRESS_TYPE) {
      label = this.subDivision as string;
    } else if (addressType === NEIGHBOURHOOD_ADDRESS_TYPE) {
      label = this.neighbourhood as string;
    } else if (addressType === ADDRESS_ADDRESS_TYPE) {
      label = `${this.number || ''} ${this.name}`;
    }
    return capitalizeWords(label);
  }
  
  get isNotAreaPage() {
    return this.addressType === ADDRESS_ADDRESS_TYPE;
  }
  
  generateLink = (addressPath?: string) => {
    return generateBreadcrumbLink(this, addressPath);
  };
}