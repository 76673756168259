import { statusOptions } from 'utils/select-options';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import { getQueryParams } from './update-query-params';
import setNestedProperty from 'set-value';
import { DEFAULT_HAS_IMAGE_VALUE } from 'contexts/preferences/listing-params';
import { Locker,
  Status,
  PropertyTypeFilter,
  CondoOrTownhouseAdditionalFilter,
  ListingParams,
  Sort,
  Filter,
  STATUS_KEYS,
  LOCKER_VALUES,
  SORT_KEYS,
  DATE_DESCENDING_SORT,
  HOUSE_PROPERTY_TYPE,
  DeprecatedPropertyTypeFilter,
  HouseAdditionalFilter } from 'contexts/preferences/listing-params/types';
import { cloneDeep } from 'lodash';
import DEFAULT_LISTING_PARAMS from 'contexts/preferences/listing-params/defaults';
import { DEFAULT_PAGE_NUMBER } from './extract-page-from-url';
import { PAGE_URL_SEARCH_PARAM, SLUG_FALLBACK_URL_SEARCH_PARAM, SORT_URL_SEARCH_PARAM } from 'types/SupportedURLSearchParam';

import type { PartialDeep } from 'type-fest';

export const HOMES_TARGETED_URL_PROPERTY_TYPE = 'Homes';
export const HOUSES_TARGETED_URL_PROPERTY_TYPE = 'Houses';
export const CONDO_TARGETED_URL_PROPERTY_TYPE = 'Condos';
export const TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE = 'Townhouses';
export const FARMS_TARGETED_URL_PROPERTY_TYPE = 'Farms';
export const COMMERCIAL_TARGETED_URL_PROPERTY_TYPE = 'Commercial';
export const LAND_TARGETED_URL_PROPERTY_TYPE = 'Land';
export const REAL_ESTATE_TARGETED_URL_PROPERTY_TYPE = 'Real Estate';

export type TargetedUrlPropertyType = typeof HOUSES_TARGETED_URL_PROPERTY_TYPE |
  typeof CONDO_TARGETED_URL_PROPERTY_TYPE |
  typeof TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE |
  typeof FARMS_TARGETED_URL_PROPERTY_TYPE |
  typeof COMMERCIAL_TARGETED_URL_PROPERTY_TYPE |
  typeof LAND_TARGETED_URL_PROPERTY_TYPE |
  typeof HOMES_TARGETED_URL_PROPERTY_TYPE;

export function parameterContainsIsMore(parameter: string) {
  return `${parameter}`.includes('+');
}

export const toggleContainsMore = (value: string) => {
  return parameterContainsIsMore(value) ? value.replace('+', '') : `${value}+`;
};

export function isHouse(homeTypeFilter: PropertyTypeFilter & DeprecatedPropertyTypeFilter) {
  return homeTypeFilter.houseDetached || homeTypeFilter.houseSemidetached || homeTypeFilter.houseAttached;
}

export function getLegacyTargetedUrlPropertyTypeSelection(homeTypeFilter: PropertyTypeFilter): TargetedUrlPropertyType {
  const { townhouse, condo } = homeTypeFilter;
  const house = homeTypeFilter.house || isHouse(homeTypeFilter as PropertyTypeFilter & DeprecatedPropertyTypeFilter);
  if (house && !condo && !townhouse) {
    return HOUSES_TARGETED_URL_PROPERTY_TYPE;
  } else if (condo && !house && !townhouse) {
    return CONDO_TARGETED_URL_PROPERTY_TYPE;
  } else if (townhouse && !house && !condo) {
    return TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE;
  } else {
    return HOMES_TARGETED_URL_PROPERTY_TYPE;
  }
}

/**
 * Determines the targeted property type for URL based on the home type filter selection.
 *
 * @param homeTypeFilter an instance of a {@link PropertyTypeFilter} containing boolean flags for different property types.
 * @returns One of the following values:
 * - 'Houses' - when only house is selected
 * - 'Condos' - when only condo is selected
 * - 'Townhouses' - when only townhouse is selected
 * - 'Farms' - when only farm is selected
 * - 'Commercial' - when only commercial is selected
 * - 'Land' - when only land is selected
 * - 'Homes' - when multiple property types are selected or none are selected
 *
 * @example
 * ```ts
 * getTargetedUrlPropertyTypeSelection({ house: true, condo: false, townhouse: false }) // Returns 'Houses'
 * getTargetedUrlPropertyTypeSelection({ house: true, condo: true }) // Returns 'Homes'
 * getTargetedUrlPropertyTypeSelection({ condo: true }) // Returns 'Condos'
 * ```
 */
export function getTargetedUrlPropertyTypeSelection(homeTypeFilter: PropertyTypeFilter): TargetedUrlPropertyType {
  const { townhouse, condo, farm, commercial, land } = homeTypeFilter;
  const house = homeTypeFilter.house || isHouse(homeTypeFilter as PropertyTypeFilter & DeprecatedPropertyTypeFilter);
  if (house && !condo && !townhouse && !farm && !commercial && !land) {
    return HOUSES_TARGETED_URL_PROPERTY_TYPE;
  } else if (condo && !house && !townhouse && !farm && !commercial && !land) {
    return CONDO_TARGETED_URL_PROPERTY_TYPE;
  } else if (townhouse && !house && !condo && !farm && !commercial && !land) {
    return TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE;
  } else if (farm && !house && !condo && !townhouse && !commercial && !land) {
    return FARMS_TARGETED_URL_PROPERTY_TYPE;
  } else if (commercial && !house && !condo && !townhouse && !farm && !land) {
    return COMMERCIAL_TARGETED_URL_PROPERTY_TYPE;
  } else if (land && !house && !condo && !townhouse && !farm && !commercial ) {
    return LAND_TARGETED_URL_PROPERTY_TYPE;
  } else {
    return HOMES_TARGETED_URL_PROPERTY_TYPE;
  }
}

export function isForSaleOrRent(rentalFilter: boolean) {
  return rentalFilter ? 'for Rent' : 'for Sale';
}

export function getNumberOfLegacyPropertyTypesFiltered(propertyTypeFilter: PropertyTypeFilter) {
  let numberOfHomesFiltered = 0;
  if (propertyTypeFilter.house) {
    numberOfHomesFiltered += 1;
  }
  if (propertyTypeFilter.townhouse) {
    numberOfHomesFiltered += 1;
  }
  if (propertyTypeFilter.condo) {
    numberOfHomesFiltered += 1;
  }
  return numberOfHomesFiltered;
}

export function getNumberOfPropertyTypesFiltered(propertyTypeFilter: PropertyTypeFilter) {
  let numberOfHomesFiltered = 0;
  if (propertyTypeFilter.house) {
    numberOfHomesFiltered += 1;
  }
  if (propertyTypeFilter.townhouse) {
    numberOfHomesFiltered += 1;
  }
  if (propertyTypeFilter.condo) {
    numberOfHomesFiltered += 1;
  }
  if (propertyTypeFilter.farm) {
    numberOfHomesFiltered += 1;
  }
  if (propertyTypeFilter.commercial) {
    numberOfHomesFiltered += 1;
  }
  if (propertyTypeFilter.land) {
    numberOfHomesFiltered += 1;
  }
  return numberOfHomesFiltered;
}

export function getPropertyTypesFilteredLabel(homeTypeFilter: PropertyTypeFilter) {
  const label = [];
  if (homeTypeFilter.house) {
    label.push('House');
  }
  if (homeTypeFilter.townhouse) {
    label.push('Townhouse');
  }
  if (homeTypeFilter.condo) {
    label.push(label.length > 1 ? 'Condo/Apt' : 'Condo/Apartment');
  }
  if (homeTypeFilter.townhouse) {
    label.push('Land');
  }
  if (homeTypeFilter.farm) {
    label.push('Farm');
  }
  if (homeTypeFilter.commercial) {
    label.push('Commercial');
  }
  return label.length ? label.join(', ') : 'Any';
}

export function getLabelFromFilterObject(filterObject: any) {
  const labels = [];

  for (const property in filterObject) {
    if (filterObject[property]) {
      const formattedProperty = property
        .replace(/([a-z])([A-Z])/g, '$1 $2'); // Add space between camelCase words
      labels.push(formattedProperty);
    }
  }

  if (labels.length === 0) {
    return 'Any';
  }

  let result = labels.join(', ');

  if (result.length > 25) {
    result = result.slice(0, 22) + '...';
  }

  return result;
}

export function getListingStatusLabel(status: Status, isRental: boolean) {
  const statusOption = statusOptions(isRental).find(item => item.value === status);
  return statusOption ? statusOption.label : 'Unknown';
}

export function getBedOrBathSelection(secondaryFilter?: string) {
  if (secondaryFilter && (secondaryFilter.includes('-bedroom') || secondaryFilter.includes('-bathroom'))) {
    return `${secondaryFilter.replace(/-/g, ' ')} `;
  }
  return '';
}

export function getOtherSecondaryFilterSelection(secondaryFilter?: string) {
  if (secondaryFilter && !(secondaryFilter.includes('-bedroom') || secondaryFilter.includes('-bathroom') || secondaryFilter.includes('filter'))) {
    return ` ${secondaryFilter.replace(/-/g, ' ')}`;
  }
  return '';
}

// https://stackoverflow.com/questions/6566456/how-to-serialize-an-object-into-a-list-of-url-query-parameters
export function formatQueryIntoURLQueryParams(query: Record<string, unknown> | { latitude: number; longitude: number }) {
  const flattenedObject = flattenObject(query);
  let queryParameterString = '';
  for (const key in flattenedObject) {
    if (flattenedObject[key] !== null) {
      if (queryParameterString != '') {
        queryParameterString += '&';
      }
      const originalKey = key;
      let formattedKey;
      if (originalKey === 'houseAttached' || originalKey === 'houseDetached' || originalKey === 'houseSemidetached') {
        // In the past we made these shortened form in our url (i.e. houseattached = attached), at this
        // point we need to maintain that mapping or we'll break bookmarks for our users :/
        formattedKey = key.replace('house', '').toLowerCase();
      } else {
        formattedKey = dasherize(key).replace('+', '').toLowerCase();
      }
      queryParameterString += formattedKey + '=' + encodeURIComponent(flattenedObject[originalKey] as string | boolean | number);
    }
  }
  return queryParameterString;
}

function extractBooleanParam(value: string | undefined, defaultValue: boolean) {
  if (value === '' || value === undefined) {
    return defaultValue;
  }
  return value.toLowerCase() === 'true';
}

function extractStringParam<T extends string | number>(value: string | undefined, defaultValue: T) {
  if (value === '' || value === undefined) {
    return defaultValue;
  }
  return value as T;
}

function extractNumberParam<T extends number | null>(value: string | undefined, defaultValue: T) {
  if (value === '' || value === undefined) {
    return defaultValue;
  }
  return Number(value);
}

/**
 * Parses the given url search params string and try to create a valid Filter object.
 *
 * **Note**: `Unknown` and `out of range` values will be ignored. Does not set default values to the parsed Filter object.
 *
 * @param urlSearchParams A string, which will be parsed from `application/x-www-form-urlencoded` format. A leading `'?'` character is ignored.
 * @returns A PartialDeep<Filter> object based on the given urlSearchParams.
 */
export function getFilterFromURLSearchParams(urlSearchParams: URLSearchParams): PartialDeep<Filter> {
  const filter: PartialDeep<Filter> = {};

  if (urlSearchParams.has('rental')) {
    filter.rental = urlSearchParams.get('rental')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('garage')) {
    filter.garage = urlSearchParams.get('garage')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('open-house')) {
    filter.openHouse = urlSearchParams.get('open-house')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('pool')) {
    filter.pool = urlSearchParams.get('pool')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('fireplace')) {
    filter.fireplace = urlSearchParams.get('fireplace')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('waterfront')) {
    filter.waterfront = urlSearchParams.get('waterfront')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('detached')) {
    setNestedProperty(filter, 'homeType.houseDetached', urlSearchParams.get('detached')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('semidetached')) {
    setNestedProperty(filter, 'homeType.houseSemidetached', urlSearchParams.get('semidetached')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('attached')) {
    setNestedProperty(filter, 'homeType.houseAttached', urlSearchParams.get('attached')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('townhouse')) {
    setNestedProperty(filter, 'homeType.townhouse', urlSearchParams.get('townhouse')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('condo')) {
    setNestedProperty(filter, 'homeType.condo', urlSearchParams.get('condo')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('land')) {
    setNestedProperty(filter, 'homeType.land', urlSearchParams.get('land')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('farm')) {
    setNestedProperty(filter, 'homeType.farm', urlSearchParams.get('farm')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('commercial')) {
    setNestedProperty(filter, 'homeType.commercial', urlSearchParams.get('commercial')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('house')) {
    setNestedProperty(filter, 'homeType.house', urlSearchParams.get('house')?.toLowerCase() == 'true');
  } else {
    // this will keep backward compatibility with the old way of setting homeType.house as it was split
    // into 3 different params: attached, semidetached and detached
    const hasAttached = urlSearchParams.has('attached');
    const hasSemidetached = urlSearchParams.has('semidetached');
    const hasDetached = urlSearchParams.has('detached');

    if (hasAttached || hasSemidetached || hasDetached) {
      const isAttached = urlSearchParams.get('attached')?.toLowerCase() === 'true';
      const isSemidetached = urlSearchParams.get('semidetached')?.toLowerCase() === 'true';
      const isDetached = urlSearchParams.get('detached')?.toLowerCase() === 'true';

      setNestedProperty(filter, 'homeType.house', isAttached || isSemidetached || isDetached);
    } else {
      setNestedProperty(filter, 'homeType.house', true);
    }
  }

  if (urlSearchParams.has('locker') && LOCKER_VALUES.includes(urlSearchParams.get('locker') || '')) {
    setNestedProperty(filter, 'additional.condoOrTownhouse.locker', urlSearchParams.get('locker') as Locker);
  }

  if (urlSearchParams.has('maintenance-fee')) {
    setNestedProperty(filter, 'additional.condoOrTownhouse.maintenanceFee', Number(urlSearchParams.get('maintenance-fee')));
  }

  if (urlSearchParams.has('status') && STATUS_KEYS.includes(urlSearchParams.get('status') || '')) {
    filter.status = urlSearchParams.get('status') as Status;
  }

  if (urlSearchParams.has('price-min') && urlSearchParams.get('price-min')?.trim()?.length !== 0) {
    filter.priceMin = Number(urlSearchParams.get('price-min'));
  }

  if (urlSearchParams.has('price-max') && urlSearchParams.get('price-max')?.trim()?.length !== 0) {
    filter.priceMax = Number(urlSearchParams.get('price-max'));
  }

  if (urlSearchParams.has('sqft-min') && urlSearchParams.get('sqft-min')?.trim()?.length !== 0) {
    filter.sqftMin = Number(urlSearchParams.get('sqft-min'));
  }

  if (urlSearchParams.has('sqft-max') && urlSearchParams.get('sqft-max')?.trim()?.length !== 0) {
    filter.sqftMax = Number(urlSearchParams.get('sqft-max'));
  }

  if (urlSearchParams.has('listed-since') && urlSearchParams.get('listed-since')?.trim()?.length !== 0) {
    filter.listedSince = urlSearchParams.get('listed-since');
  }

  if (urlSearchParams.has('listed-to') && urlSearchParams.get('listed-to')?.trim()?.length !== 0) {
    filter.listedTo = urlSearchParams.get('listed-to');
  }

  const regex = new RegExp(/^[0-6]\+?$/);

  if (urlSearchParams.has('bathrooms') && urlSearchParams.get('bathrooms')?.trim()?.length !== 0 && regex.test(urlSearchParams.get('bathrooms') as string)) {
    filter.bathrooms = urlSearchParams.get('bathrooms') as string;
  }

  if (urlSearchParams.has('bedrooms') && urlSearchParams.get('bedrooms')?.trim()?.length !== 0 && regex.test(urlSearchParams.get('bedrooms') as string)) {
    filter.bedrooms = urlSearchParams.get('bedrooms') as string;
  }

  if (urlSearchParams.has('parking-spaces') && urlSearchParams.get('parking-spaces')?.trim()?.length !== 0 && regex.test(urlSearchParams.get('parking-spaces') as string)) {
    filter.parkingSpaces = urlSearchParams.get('parking-spaces') as string;
  }

  if (urlSearchParams.has('has-image')) {
    filter.hasImage = urlSearchParams.get('has-image')?.toLowerCase() === 'true';
  }

  return filter;
}

/**
 * Parses the given url search params object for the page value.
 *
 * **Note**: `Unknown` values will be ignored and the default sort value ({@link DEFAULT_PAGE_NUMBER}) is returned instead.
 *
 * @param urlSearchParams the {@link URLSearchParams} object to search for the {@link PAGE_URL_SEARCH_PARAM} value.
 * @returns the {@link PAGE_URL_SEARCH_PARAM} value based on the given {@link URLSearchParams}
 *  object. {@link DEFAULT_PAGE_NUMBER} is returned when no {@link PAGE_URL_SEARCH_PARAM} is set.
 */
export function getPageFromURLSearchParams(urlSearchParams: URLSearchParams): number {
  if (urlSearchParams.has(PAGE_URL_SEARCH_PARAM)) {
    const pageValue = urlSearchParams.get(PAGE_URL_SEARCH_PARAM);
    if (pageValue?.trim().length === 0) {
      return DEFAULT_PAGE_NUMBER;
    }
    const parsedPage = Number(pageValue);
    if (!isNaN(parsedPage) && Number.isInteger(parsedPage)) {
      return parsedPage;
    }
  }
  return DEFAULT_PAGE_NUMBER;
}

/**
 * Parses the given url search params object for the sorting value.
 *
 * **Note**: `Unknown` values will be ignored and the default sort value
 * ({@link DATE_DESCENDING_SORT}) is returned instead.
 *
 * @param urlSearchParams the {@link URLSearchParams} object to search for
 *  the {@link SORT_URL_SEARCH_PARAM} value.
 * @returns the {@link SORT_URL_SEARCH_PARAM} value based on the given
 *  {@link URLSearchParams} object. {@link DATE_DESCENDING_SORT} is
 *  returned when no sorting is set.
 */
export function getSortFromURLSearchParams(urlSearchParams: URLSearchParams): Sort {
  if (urlSearchParams.has(SORT_URL_SEARCH_PARAM) && SORT_KEYS.includes(urlSearchParams.get(SORT_URL_SEARCH_PARAM) || '')) {
    return urlSearchParams.get(SORT_URL_SEARCH_PARAM) as Sort;
  }
  return DATE_DESCENDING_SORT;
}

/**
 * Parses the given url search params object for the slug-fallback value.
 *
 * @param urlSearchParams the {@link URLSearchParams} object to search for
 *  the {@link SLUG_FALLBACK_URL_SEARCH_PARAM} value.
 * @returns the {@link SLUG_FALLBACK_URL_SEARCH_PARAM} value based on the given
 *  {@link URLSearchParams} object. `null` is returned when no
 *  {@link SLUG_FALLBACK_URL_SEARCH_PARAM} is set.
 */
export function getSlugFallbackFromURLSearchParams(urlSearchParams: URLSearchParams): string | null {
  return urlSearchParams.get(SLUG_FALLBACK_URL_SEARCH_PARAM);
}

export function getFilterParamsFromParsedUrlQuery(query: NodeJS.Dict<string> ): ListingParams {
  return {
    sort: extractStringParam<Sort>(query.sort, '-date'),
    filter: {
      rental: extractBooleanParam(query.rental, false),
      status: extractStringParam<Status>(query.status, 'available'),
      slug: query.slug || 'toronto-on',
      latitude: extractNumberParam(query.latitude, 43.653226),
      longitude: extractNumberParam(query.longitude, -79.3831843),
      zoom: extractNumberParam(query.zoom, 14),
      homeType: {
        house: extractBooleanParam(query['house'], (extractBooleanParam(query['detached'], true) || extractBooleanParam(query['semidetached'], true) || extractBooleanParam(query['attached'], true))),
        townhouse: extractBooleanParam(query.townhouse, true),
        condo: extractBooleanParam(query.condo, true),
        land: extractBooleanParam(query.land, true),
        commercial: extractBooleanParam(query.commercial, true),
        farm: extractBooleanParam(query.farm, true),
      },
      priceMin: extractNumberParam(query['price-min'], null),
      priceMax: extractNumberParam(query['price-max'], null),
      listedSince: query['listed-since'] || null,
      listedTo: query['listed-to'] || null,
      bedrooms: query.bedrooms || '0+',
      sqftMin: extractNumberParam(query['sqft-min'], null),
      sqftMax: extractNumberParam(query['sqft-max'], null),
      bathrooms: extractStringParam(query.bathrooms, '1+'),
      parkingSpaces: extractStringParam(query['parking-spaces'], '0+'),
      openHouse: extractBooleanParam(query['open-house'], false),
      garage: extractBooleanParam(query.garage, false),
      pool: extractBooleanParam(query.pool, false),
      fireplace: extractBooleanParam(query.fireplace, false),
      waterfront: extractBooleanParam(query.waterfront, false),
      additional: {
        condoOrTownhouse: {
          locker: extractStringParam<Locker>(query.locker, 'any'),
          maintenanceFee: extractNumberParam(query['maintenance-fee'], null),
        },
      },
      areaName: query['area-name'] || 'Toronto, ON',
      boundary: query.boundary || null,
      providerId: query.providerId || null,
      hasImage: extractBooleanParam(query['has-image'], DEFAULT_HAS_IMAGE_VALUE),
    },
  };
}

export function getFilterParamsFromURLQuery(urlParams: string): ListingParams {
  const query = getQueryParams(urlParams) as any;
  return getFilterParamsFromParsedUrlQuery(query);
}

export type FilterAndPositionUrlParams = {
  sort?: string;
  rental?: boolean;
  status?: string;
  slug?: string;
  latitude: number;
  longitude: number;
  zoom: number;
  house?: boolean;
  farm?: boolean;
  commercial?: boolean;
  land?: boolean;
  townhouse?: boolean;
  condo?: boolean;
  'price-min'?: number;
  'price-max'?: number;
  'listed-since'?: string;
  'listed-to'?: string;
  bedrooms?: number;
  'sqft-min'?: number;
  'sqft-max'?: number;
  bathrooms?: number;
  'parking-spaces'?: number;
  'open-house'?: boolean;
  garage?: boolean;
  pool?: boolean;
  fireplace?: boolean;
  waterfront?: boolean;
  locker?: boolean;
  'maintenance-fee'?: number;
  boundary?: string;
  'area-name'?: string;
};

export function convertFilterAndPositionToUrlParams(listingParams: ListingParams, latitude: number, longitude: number, zoom: number) {
  let isHouse = listingParams.filter.homeType?.house;
  const homeTypeFilter = cloneDeep(listingParams.filter.homeType) as PropertyTypeFilter & DeprecatedPropertyTypeFilter;
  if (!Object.prototype.hasOwnProperty.call(homeTypeFilter, HOUSE_PROPERTY_TYPE)) {
    // If 'house' property doesn't exist, create it based on deprecated properties for backward compatibility
    isHouse = !!(homeTypeFilter.houseDetached || homeTypeFilter.houseSemidetached || homeTypeFilter.houseAttached);
  }

  const param = {
    sort: listingParams.sort,
    rental: listingParams.filter.rental,
    status: listingParams.filter.status,
    slug: listingParams.filter.slug || DEFAULT_LISTING_PARAMS.filter.slug,
    latitude,
    longitude,
    zoom,
    house: isHouse,
    farm: listingParams.filter.homeType.farm,
    commercial: listingParams.filter.homeType.commercial,
    land: listingParams.filter.homeType.land,
    townhouse: listingParams.filter.homeType.townhouse,
    condo: listingParams.filter.homeType.condo,
    'price-min': listingParams.filter.priceMin,
    'price-max': listingParams.filter.priceMax,
    'listed-since': listingParams.filter.listedSince,
    'listed-to': listingParams.filter.listedTo,
    bedrooms: listingParams.filter.bedrooms,
    'sqft-min': listingParams.filter.sqftMin,
    'sqft-max': listingParams.filter.sqftMax,
    bathrooms: listingParams.filter.bathrooms,
    'parking-spaces': listingParams.filter.parkingSpaces,
    'open-house': listingParams.filter.openHouse,
    garage: listingParams.filter.garage,
    pool: listingParams.filter.pool,
    fireplace: listingParams.filter.fireplace,
    waterfront: listingParams.filter.waterfront,
    locker: listingParams.filter.additional.condoOrTownhouse.locker,
    'maintenance-fee': listingParams.filter.additional.condoOrTownhouse.maintenanceFee,
    boundary: listingParams.filter.boundary,
    'area-name': listingParams.filter.areaName,
    hasImage: listingParams.filter.hasImage,
  };

  // delete null values
  Object.keys(param).forEach((k: any) => {
    (param as any)[k] == null && delete (param as any)[k];
  });

  const urlParams = new URLSearchParams(
    Object.entries(param).reduce((acc, [key, value]) => {
      acc[key] = String(value);
      return acc;
    }, {} as Record<string, string>)
  );
  return urlParams;
}

// https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object
function flattenObject(query: Record<string, unknown>) {
  const flattenedObject: Record<string, unknown> = {};
  Object.keys(query).forEach(key => {
    if (typeof query[key] === 'object' && query[key] !== null) {
      Object.assign(flattenedObject, flattenObject(query[key] as Record<string, unknown>));
    } else {
      flattenedObject[key] = query[key];
    }
  });
  return flattenedObject;
}


export function hasDeprecatedHomeType(filter: PartialDeep<Filter>): boolean {
  if (filter.homeType === undefined && filter.additional === undefined) {
    return false;
  }

  if (filter.homeType) {
    const homeType = filter.homeType as DeprecatedPropertyTypeFilter & PropertyTypeFilter;
    const houseTypes = [homeType.houseDetached, homeType.houseSemidetached, homeType.houseAttached];
    const selectedHouseTypes = houseTypes.some(type => type !== undefined && type !== null);

    if (selectedHouseTypes) {
      return true;
    }
  }

  const additional = filter?.additional as { condoOrTownhouse: CondoOrTownhouseAdditionalFilter; house: HouseAdditionalFilter };
  if (additional?.house) {
    const {
      singleFamily = false,
      basementApartment = false,
      duplex = false,
      triplex = false,
      'fourplex+': fourplexPlus = false,
    } = additional.house;
    return singleFamily || basementApartment || duplex || triplex || fourplexPlus;
  }
  return false;
}