import { useState, useEffect } from 'react';
import useWindowWidth from 'hooks/use-window-width';

export const IS_MOBILE_BREAKPOINT = 768;
export const IS_SMALL_TABLET_BREAKPOINT = 1024;
export const IS_TABLET_BREAKPOINT = 1300;
export const IS_SMALL_MOBILE_BREAKPOINT = 480;
export const IS_NOT_MOBILE_BREAKPOINT = 769;
export const IS_LARGE_DESKTOP_BREAKPOINT = 1920;

export default function useSizeClass({ min = 0, max = 1000000 }): boolean {
  const windowWidth = useWindowWidth();
  const determineSize = () => windowWidth >= min && windowWidth <= max;

  const [size, setSize] = useState(determineSize());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSize(determineSize()), [windowWidth]);

  return size;
}

export function useIsLargeDesktop() {
  return useSizeClass({ min: IS_LARGE_DESKTOP_BREAKPOINT });
}

export function useIsSmallMobile() {
  return useSizeClass({ max: IS_SMALL_MOBILE_BREAKPOINT });
}

export function useIsMobile() {
  return useSizeClass({ max: IS_MOBILE_BREAKPOINT });
}

export function useIsNotMobile() {
  return useSizeClass({ min: IS_NOT_MOBILE_BREAKPOINT });
}

export function useIsTablet() {
  return useSizeClass({ max: IS_TABLET_BREAKPOINT });
}

export function useIsSmallTablet() {
  return useSizeClass({ max: IS_SMALL_TABLET_BREAKPOINT });
}

export function useIsDesktop() {
  return useSizeClass({ min: IS_TABLET_BREAKPOINT });
}