/**
 * This is a higher-order function that provides error handling for synchronous operations by
 * wrapping the function in a try-catch block and returning a tuple containing the result or error.
 *
 * @template T The type of the successful result. Defaults to `unknown`.
 * @template E The type of the error. Defaults to `unknown`.
 *
 * @param {(...args: any[]) => T} fn The synchronous function to wrap
 * @returns {(...args: any[]) => [T | undefined, E | undefined]} A new function that returns a tuple [result, error]
 *
 * @example
 * ```typescript
 * const divide = (a: number, b: number) => a / b;
 * const safeDivide = withTry<number>(divide);
 * const [result, error] = safeDivide(10, 2); // [5, undefined]
 * const [result2, error2] = safeDivide(10, 0); // [undefined, Error]
 * ```
 */
export function withTry<T = unknown, E = unknown>(
  fn: (...args: any[]) => T
): (...args: any[]) => [T | undefined, E | undefined] {
  return (...args: any[]): [T | undefined, E | undefined] => {
    try {
      const result = fn(...args);
      return [result, undefined];
    } catch (error) {
      return [undefined, error as E];
    }
  };
}

/**
 * This is a higher-order function that provides error handling for asynchronous operations by
 * wrapping the function in a try-catch block and returning a tuple containing the result or error.
 *
 * @template T The type of the successful result. Defaults to `unknown`.
 * @template E The type of the error. Defaults to `unknown`.
 *
 * @param {(...args: any[]) => Promise<T>} fn The asynchronous function to wrap
 * @returns {(...args: any[]) => Promise<[T | undefined, E | undefined]>} A new async function that returns a Promise of a tuple [result, error]
 *
 * @example
 * ```typescript
 * // Async function example
 * const fetchUser = async (id: string) => {
 *   const response = await fetch(`/api/users/${id}`);
 *   return response.json();
 * };
 * const safeFetchUser = withTryAsync<User>(fetchUser);
 * const [user, error] = await safeFetchUser('123');
 * ```
 */
export function withTryAsync<T = unknown, E = unknown>(
  fn: (...args: any[]) => Promise<T>
): (...args: any[]) => Promise<[T | undefined, E | undefined]> {
  return async (...args: any[]): Promise<[T | undefined, E | undefined]> => {
    try {
      const result = await fn(...args);
      return [result, undefined];
    } catch (error) {
      return [undefined, error as E];
    }
  };
}
