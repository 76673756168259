import { IncomingMessage, ServerResponse } from 'http';

class Cookies {
  cookies: Record<string, string> = {};

  constructor(req: IncomingMessage) {
    req?.headers?.cookie?.split(/\s*;\s*/)?.forEach(cookie => {
      const [key, value] = cookie.split(/\s*=\s*/);
      this.cookies[key] = value;
    });
  }

  get(name: string): string | undefined {
    return this.cookies[name];
  }
}

export function getObjectFromRequestCookie<T = Record<string, string>>(name: string, req?: IncomingMessage): T | undefined {
  if (req?.headers?.cookie) {
    const cookies = new Cookies(req);
    const cookie = cookies.get(name);

    if (cookie) {
      return getJSON<T>(cookie);
    }
  }
  return undefined;
}

export function getStringFromRequestCookie(name: string, req?: IncomingMessage, defaultValue?: string): string | undefined {
  if (req?.headers?.cookie) {
    const cookies = new Cookies(req);
    return cookies.get(name) || defaultValue;
  }
  return defaultValue;
}

export function getJSON<T = string>(cookie?: string): T | undefined {
  if (cookie) {
    try {
      return JSON.parse(decodeURIComponent(cookie)) as T;
    } catch (e){
      console.error(e);
      return undefined;
    }
  }
  return undefined;
}

export function getResponseCookieValue(res: ServerResponse, name: string): any | undefined {
  const setCookieHeader = res.getHeader('Set-Cookie');

  if (!setCookieHeader) {
    return undefined;
  }

  const cookies = Array.isArray(setCookieHeader) ? setCookieHeader : [setCookieHeader];

  for (const cookie of cookies) {
    const cookieParts = cookie.toString().split(';')[0].split('=');

    if (cookieParts[0] !== name) {
      return undefined;
    }

    try {
      const cookieValue = cookieParts[1];

      // Decode the URL-encoded JSON string
      const decodedValue = decodeURIComponent(cookieValue);

      // Parse the JSON string
      const parsedData = JSON.parse(decodedValue);
      return parsedData;
    } catch (error) {
      console.error('Failed to parse JSON string:', error);
    }
  }

  return undefined;
}