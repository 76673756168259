const keywordsCA = [
  {
    label: 'Sold Prices',
    link: '/sold',
    type: 'area',
  },
  {
    label: 'Houses for Sale',
    link: '/houses',
    type: 'area',
  },
  {
    label: 'Condos for Sale',
    link: '/condos',
    type: 'area',
  },
  {
    label: 'Townhouses for Sale',
    link: '/townhouses',
    type: 'area',
  },
  {
    label: 'Home Appraisal',
    link: '/home-appraisal',
    type: 'other',
  },
  {
    label: 'Find an Agent',
    link: '/company/our-agents',
    type: 'other',
  },
  {
    label: 'Houses',
    link: '/search?townhouse=false&condo=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: '3-Bed Houses',
    link: '/search?bedrooms=2&condo=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: '2-Bed Condos',
    link: '/search?bedrooms=2&house=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: '1-Bed Condos',
    link: '/search?bedrooms=1&house=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: 'Condos for Rent',
    link: '/filter?rental=true&townhouse=false&house=false&commercial=false&farm=false',
    type: 'area',
  },
  {
    label: 'Houses for Rent',
    link: '/filter?rental=true&townhouse=false&condo=false&commercial=false&farm=false',
    type: 'area',
  },
  {
    label: 'Condos Under $500K',
    link: '/search?price-max=500000&house=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: 'Houses Under $1 mil',
    link: '/search?price-max=1000000&condo=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: 'Condos > $1,000,000',
    link: '/search?price-min=1000000&house=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: 'Most Expensive Houses',
    link: '/search?sort=-price&condo=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: 'Luxury Condos',
    link: '/search?sort=-price&house=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: 'Cheapest Condos in Toronto',
    link: '/search?latitude=43.66078879833992&longitude=-79.40200837292478&sort=price&zoom=12&house=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: 'Cheapest Houses in Toronto',
    link: '/search?latitude=43.66078879833992&longitude=-79.40200837292478&sort=price&zoom=12&condo=false&townhouse=false&land=false&commercial=false&farm=false',
    type: 'search',
  },
  {
    label: 'Downtown Toronto Condos',
    link: '/toronto-on-real-estate/downtown/condos',
    type: 'other',
  },
];

export default keywordsCA;